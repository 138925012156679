import React, { useState } from "react"
import styled from "styled-components"
import Button from "Elements/Button/Button"
import { Modal } from "antd"
import Input from "Elements/Input/Input"
import { ChangePasswordSchemas } from "Schemas"
import { useFormik } from "formik"
import { BaseService } from "API/base"
import { Error } from "Responses/Response"

const ChangePasswordModal = ({ setchangePassword, changePassword }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    current_password: "",
    new_password1: "",
    new_password2: ""
  })

  const [error, setError] = useState(null)

  const onclose = () => {
    handleReset()
    setchangePassword(false)
  }

  const hanldeChangePassword = async (values, action) => {
    try {
      const response = await BaseService.post(
        "/rest-auth/password/change/",
        values,
        true
      )
      if (response?.status === 200) {
        onclose()
      }
    } catch (error) {
      error?.response?.data?.current_password?.map(err => Error(err))
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangePasswordSchemas,
    onSubmit: hanldeChangePassword
  })
  const handleReset = () => {
    setInitialValues({
      new_password1: "",
      new_password2: "",
      current_password: ""
    })
    resetForm()
  }
  return (
    <>
      <Modal
        footer={false}
        className="New_password_modal"
        open={changePassword}
        onCancel={onclose}
      >
        <Container>
          <ModelContent>
            <Heading>Change Password</Heading>
            <InputWrapper>
              <Input
                Error={errors.current_password && touched.current_password}
                type="password"
                placeholder={"Current Password"}
                name="current_password"
                value={values.current_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.current_password && touched.current_password ? (
                <Span>{errors.current_password}</Span>
              ) : null}
            </InputWrapper>
            <InputWrapper>
              <Input
                Error={errors.new_password1 && touched.new_password1}
                type="password"
                placeholder={"New Password"}
                name="new_password1"
                value={values.new_password1}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.new_password1 && touched.new_password1 ? (
                <Span>{errors.new_password1}</Span>
              ) : null}
            </InputWrapper>
            <InputWrapper>
              <Input
                Error={errors.new_password2 && touched.new_password2}
                type="password"
                placeholder={"Confirm Password"}
                name="new_password2"
                value={values.new_password2}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {console.log(errors.new_password2)}
              {errors.new_password2 && touched.new_password2 ? (
                <Span>{errors.new_password2}</Span>
              ) : null}
            </InputWrapper>
            <Text>
              Must be at least 8 characters with one number and one special
              character
            </Text>
            <ButtonContent>
              <Button onClick={handleSubmit} disabled={isLoading} isBorder>
                {isLoading ? "Saving" : "Save"}
              </Button>
              <Button isBorder isBackgroundLight onClick={onclose}>
                Cancel
              </Button>
            </ButtonContent>
          </ModelContent>
        </Container>
      </Modal>
    </>
  )
}

export default ChangePasswordModal

const Container = styled.div`
  min-height: 445px;
  height: 100%;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 340px;
`
const Heading = styled.h3`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin: 3.5rem 0;
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
`
const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`

const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`
const Text = styled.p`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.24px;
  margin-bottom: 1.6rem;
`
