import SideNav from "Components/SideNav/SideNav"
import React, { Fragment, useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { GlobalStateContext } from "GlobalStateContext"
import { BaseService } from "API/base"

const MainApp = () => {
  const location = useLocation()
  const [getNotificationloading, setGettNotificationLoading] = useState()
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)

  console.log(globalState, "hhhh")

  const getNotification = async (isLoading = true) => {
    try {
      if (isLoading) {
        setGettNotificationLoading(true)
      }
      const response = await BaseService.get(
        `/api/v1/notifications/history/`,
        true
      )
      updateGlobalState("notifications", response?.data)

      setGettNotificationLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getNotification()
  }, [])

  return (
    <Fragment>
      <Container>
        <LayoutWrapper>
          <SideNav />

          {/* <ErrorModal /> */}
          {/* <Loading /> */}

          <Screens
          // style={
          //   location.pathname === "/app/coachee/schedule-session/:id" && {
          //     width: "calc(100% - 24rem"
          //   }
          // }
          >
            <Outlet />
          </Screens>
        </LayoutWrapper>
      </Container>
    </Fragment>
  )
}

export default MainApp
const LayoutWrapper = styled.div`
  transition: width 0.5s;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundDark};
  /* z-index: ${({ layout }) => !layout && 0}; */
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
`

const Container = styled.div`
  width: 100%;
`

const Screens = styled.div`
  width: calc(100% - 24rem);
`
