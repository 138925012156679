import { Rate } from "antd";
import Button from "Elements/Button/Button";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LeftArrow } from "./left_arrow.svg";
import { BaseService } from "API/base";
import { Error, Success } from "Responses/Response";
import { DateTime } from "luxon";
import RequestModal from "Components/RequestModal/RequestModal";

const CoachSessionFeedback = () => {
  const navigate = useNavigate();
  const [isOpen, setisOpen] = useState(false);
  const [getCoachee, setGetCoachee] = useState([]);
  const [getCoachdata, setGetCoachData] = useState();
  const [getSession, setGetSession] = useState();
  const { id, sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const reviewCoachSession = async (data, i) => {
    try {
      setIsLoading(true);
      const response = await BaseService.post(
        `api/v1/coachees/review/${sessionId}/`,
        data
      );
      if (response?.status === 200) {
        // handlePopup()
        Success(response?.data?.detail);
        getReviewCochee();
        setisOpen(true);
        // navigate(-1);
      }
    } catch (error) {
      Error(error?.response?.data?.detail);
    } finally {
      setIsLoading(false);
      console.log(isOpen);
    }
  };

  const getReviewCochee = async () => {
    try {
      // setCoacheesDetailLoading(true);
      const response = await BaseService.get(
        `api/v1/coachees/review/${sessionId}/`,
        true
      );
      console.log(response?.data);
      setGetCoachee(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReviewCochee();
  }, []);

  // console.log(getCoachee, "getttt data ");

  //   const getCoachees = async () => {
  //     try {
  //       const response = await BaseService.get(
  //         `api/v1/coaches/session/students/`,
  //         true
  //       );
  //       Object.keys(response?.data).forEach((key) => {
  //         const filteredArray = response?.data[key].filter((item) => {
  //           if (item?.id == profileId) {
  //             setProfileData(item);
  //           }
  //         });
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const convertBackendDataToUserTimeZone = (backendData) => {
    const backendDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    );

    const userTime = backendDateTime.setZone("Etc/GMT+5");

    const endDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    );

    const userEndTime = endDateTime.setZone("Etc/GMT+5");

    const formattedUtcOffset = "-05:00";

    return {
      ...backendData,
      session_date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"), // Use userEndTime
      utc_offset: formattedUtcOffset,
    };
  };

  const getCoach = async () => {
    try {
      // setCoacheesDetailLoading(true);
      const response = await BaseService.get(`api/v1/coaches/${id}/`, true);

      setGetCoachData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSessions = async () => {
    try {
      const response = await BaseService.get(`/api/v1/coachees/session/${id}/`);
      const slotsAvailable = response?.data.map(
        convertBackendDataToUserTimeZone
      );

      const filterSession = slotsAvailable?.find((item) => {
        if (item?.id == sessionId) {
          setGetSession(item);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    // if (ratingValue?.comment === "") return toast?.error("field is required")
    reviewCoachSession(ratingValue);
  };

  const [ratingValue, setRatingValue] = useState({
    rate_1: 0,
    comment: "",
  });

  const handleRatingChange = (value) => {
    setRatingValue({
      ...ratingValue,
      rate_1: value,
    });
  };
  const handleCommentChange = (event) => {
    const newComment = event.target.value;

    setRatingValue((prevRatingValue) => ({
      ...prevRatingValue,
      comment: newComment,
    }));
  };

  useEffect(() => {
    if (getCoachee.rate_1 && getCoachee.comment) {
      setRatingValue({
        ...ratingValue,
        rate_1: getCoachee.rate_1,
        comment: getCoachee.comment,
      });
    }
  }, [getCoachee]);

  // console.log(profileData, "profile");
  // console.log(id, "id");

  useEffect(() => {
    getCoach();
  }, []);

  useEffect(() => {
    getSessions();
  }, []);

  return (
    // <Container></Container>
    <Container>
      <Header>
        <SubHeading>
          {/* <img
            src="/Assets/left_arrow.svg"
            alt=""
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />{" "} */}
          <LeftArrow
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
          Review Coach Session
        </SubHeading>
      </Header>
      <ReviewContent>
        <Content>
          <ProfileSection>
            <Profile>
              <div>
                <ImageProfile
                  src={
                    getCoachdata?.profile_picture_url
                      ? getCoachdata?.profile_picture_url
                      : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                  }
                  alt="profile"
                />
              </div>
              <div>
                <Name>{`${getCoachdata?.first_name} ${getCoachdata?.last_name}`}</Name>

                <SubTitle>
                  {`${getSession?.call_type} ${getSession?.start_time?.slice(
                    0,
                    5
                  )} ${getSession?.start_time?.slice(5, 7)}`}
                  &nbsp; -{" "}
                  {`${getSession?.end_time.slice(
                    0,
                    5
                  )} ${getSession?.end_time.slice(5, 7)}  EST`}
                </SubTitle>
              </div>
            </Profile>
          </ProfileSection>
          <Para>On a scale of 1 to 5, please rate how the session went.</Para>
          <InputWrapper>
            <Rate
              defaultValue={getCoachee.rate_1 || ratingValue.rate_1}
              onChange={(value) =>
                !getCoachee.rate_1 && handleRatingChange(value)
              }
              value={ratingValue.rate_1}
              // disabled={getCoachee.rate} // Updated line
              character={({ index }) => (
                <Box
                  isSelected={
                    index + 1 === (getCoachee.rate_1 || ratingValue.rate_1)
                  }
                  onClick={() =>
                    !getCoachee.rate_1 && handleRatingChange(index + 1)
                  }
                  disabled={!!getCoachee.rate_1}
                >
                  {index + 1}
                </Box>
              )}
            />
          </InputWrapper>

          <Para>Notes for the Session</Para>
          <TextArea
            placeholder="Your comment (optional)"
            value={getCoachee.comment || ratingValue.comment}
            onChange={handleCommentChange}
            disabled={!!getCoachee.comment}
          />
          <SubmitButtonWrapper>
            <Button
              isBorder
              onClick={handleSubmit}
              disabled={!getCoachee?.rate_1 < 1 || isLoading}
            >
              {isLoading
                ? "Loading..."
                : !getCoachee?.rate_1 < 1
                ? "Submitted"
                : "Submit"}
            </Button>
          </SubmitButtonWrapper>

          <RequestModal
            isOpen={isOpen}
            setisOpen={setisOpen}
            // onClick={HandleOpen}
            description={"Review was successfully sent!"}
            // isButtonDisabled={
            //   getCoachee.rate !== 0 || getCoachee.comment.trim() !== ""
            // }
          />
        </Content>
      </ReviewContent>
    </Container>
  );
};

export default CoachSessionFeedback;

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
`;
const SubHeading = styled.div`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-left: 1.5rem;
`;
const ReviewContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.5rem;
`;
const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  /* border: 1px solid green; */
  border-radius: 24px;
  padding: 2.3rem 0rem;
  width: 603px;
  /* height: 519px; */
`;
const ProfileSection = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  padding: 0.65rem 0rem;
  margin: 0 auto;
  width: 531px;
  border-radius: 24px;
`;
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 0.7rem;
`;
const Profile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;
const Name = styled.h2`
  margin-bottom: 0;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 10.23%;
  color: #2b2b2b;
`;
const SubTitle = styled.p`
  margin-top: 0.5rem;
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 158.333% */
  letter-spacing: -0.24px;
`;
const Para = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  /* letter-spacing: -0.32px; */
  color: ${({ theme }) => theme.textDark};
  margin-top: 2.2rem;
  margin-left: 2.6rem;
`;

const InputWrapper = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  /* padding: 0 0.5rem; */
  padding-left: 2.45rem;
  margin-bottom: 2rem;
  /* border: 1px solid green; */
`;

const TextArea = styled.textarea`
  width: 505px;
  height: 105px;
  margin-left: 2.5rem;
  padding-top: 1rem;
  padding-left: 1rem;
  /* padding: 1rem; */
  border: 1px solid ${({ theme }) => theme.border2};
  outline: none;
  border-radius: 8px;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textMedium};
`;
const SubmitButtonWrapper = styled.div`
  width: 88%;
  margin: 2rem 2rem 1rem 2rem;
  /* border: 1px solid green; */
`;
const Box = styled.button`
  width: 89.96px;
  height: 48px;
  background-color: ${({ isSelected }) =>
    isSelected ? "#E5BE44" : "transparent"};
  border: none;
  outline: none;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  font-size: 16px;
  font-family: "Myriad-Regular";
  font-weight: 600;
  margin-right: 0.7rem;
  cursor: pointer;
  &:focus {
    background-color: ${({ theme }) => theme.primary};
  }
`;
const Span = styled.p`
  color: red;
`;
