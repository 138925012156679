import { Navigate } from "react-router-dom";
import { getCookie, removeAllCookie } from "Utils/StorageVariables";
import { userTypes } from "Utils/Common";

export const useProtectedRoutes = () => {
  let userData = JSON.parse(getCookie("userData"));
  // console.log({ userData });

  const ProtectedRoute = ({ redirectPath = "/", children }) => {
    if (!getCookie("accessToken")) {
      removeAllCookie();
      return <Navigate to={redirectPath} />;
    }
    switch (userData?.user_type) {
      case userTypes?.client:
        <Navigate to={"/app/client/profile"} />;
        break;
      case userTypes?.coach:
        <Navigate to={"/app/coach/profile"} />;
        break;
      case userTypes?.coachee:
        <Navigate to={"/app/coachee/profile"} />;

        break;

      default:
        break;
    }

    return children;
  };
  return { ProtectedRoute };
};
