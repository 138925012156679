import React, { useState } from "react";
import styled from "styled-components";
import Button from "Elements/Button/Button";
import { Modal } from "antd";
import { FeedbackSchemas } from "Schemas";
import { Formik, Form } from "formik";
import { Error } from "Responses/Response";
import { BaseService } from "API/base";
import { setDefaultOptions } from "date-fns/esm";

const FeedbackModal = ({ setisOpen, isOpen }) => {
  const [isLoading, setIsloading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const initialValues = {
    feedback_text: "",
  };

  const onSubmit = async (values, { resetForm }) => {
    setIsloading(true);
    try {
      const response = await BaseService.post(
        "api/v1/feedbacks/",
        values,
        true
      );
      if (response?.status === 201) {
        setisOpen(false);
        resetForm(); // Reset the form, which includes clearing the input field
        console.log(values);
        closeModal();
      }
    } catch (error) {
      console.error({ error });
      closeModal();
      resetForm();
      Error(error);
    } finally {
      setIsloading(false);
    }
  };

  // const closeModal = (setFieldValue, resetForm) => {
  //   setisOpen(false);
  //   setInputValue("");
  //   setFieldValue({
  //     feedback_text: "",
  //   });
  //   resetForm();
  // };
  const closeModal = () => {
    setisOpen(false);
    setInputValue("");
  };

  return (
    <>
      <Modal
        footer={false}
        className="feedback_modal"
        open={isOpen}
        onCancel={closeModal}
      >
        <Container>
          <ModelContent>
            <Heading>Feedback</Heading>
            <Formik
              initialValues={initialValues}
              validationSchema={FeedbackSchemas}
              onSubmit={onSubmit}
            >
              {({ errors, touched, setFieldValue, resetForm }) => (
                <Form>
                  <>
                    <CommentBox>
                      <Message
                        name="feedback_text"
                        id="feedback_text"
                        value={inputValue} // Use inputValue as the input value
                        onChange={(event) => {
                          setInputValue(event.target.value); // Update inputValue
                          setFieldValue("feedback_text", event.target.value);
                        }}
                        placeholder="Your Comment"
                      ></Message>
                      {errors.feedback_text && touched.feedback_text ? (
                        <Span>{errors.feedback_text}</Span>
                      ) : null}
                    </CommentBox>
                    <ButtonContent>
                      <Button type={"submit"} isBorder disabled={isLoading}>
                        Submit
                      </Button>
                      <CustomButton
                        isBorder
                        isBackgroundLight
                        onClick={() => {
                          closeModal(); // Pass both setFieldValue and resetForm
                          resetForm();
                        }}
                        disabled={isLoading}
                      >
                        Cancel
                      </CustomButton>
                    </ButtonContent>
                  </>
                </Form>
              )}
            </Formik>
          </ModelContent>
        </Container>
      </Modal>
    </>
  );
};

export default FeedbackModal;

const Container = styled.div`
  height: 378px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`;

const CommentBox = styled.div`
  /* border: 1px solid red; */
  width: 327px;
  height: 110px;
  margin-bottom: 4rem;
`;

const Message = styled.textarea`
  height: 100%;
  width: 100%;
  padding: 12px;
  font-size: 17px;
  border: 1px solid ${({ theme }) => theme.textLight};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundDark};

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    color: ${({ theme }) => theme.textMedium};
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  gap: 1rem;
`;

const Span = styled.span`
  color: ${({ theme }) => theme.danger};
  font-size: 13px;
  font-weight: 700;

  margin: 0;
`;
const CustomButton = styled.div`
  cursor: pointer;
  color: #1c1e21;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.32px;
`;
