import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Button from "Elements/Button/Button"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./Calender.scss"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { BaseService } from "API/base"
import { Error } from "Responses/Response"
import Loading from "Components/Loader/Loading"
import { DateTime } from "luxon"
import VideocCallModal from "Components/VideocCallModal/VideocCallModal"
import { ReactComponent as CalendlyLogo } from "./Calendar.svg"
import InfoModal from "Components/InfoModal/InfoModal"

const CoachCalendar = () => {
  const navigate = useNavigate()
  const localizer = momentLocalizer(moment)
  const [getCoachesLoading, setGetCoachesLoading] = useState(false)
  const [Viewdot, setViewdot] = useState("month")
  // const [slotsData, setSlotsData] = useState([])
  const [videoRoomData, setVideoRoomData] = useState()
  const [coacheeData, setcoacheeData] = useState()
  const [roomId, setRoomId] = useState()
  const [sessionDetail, setsessionDetail] = useState()
  const [isConnectionStatus, setIsConnectionStatus] = useState()
  const [getUser, setGetUser] = useState(null)
  const [currentMonth, setCurrentMonth] = useState(null)
  const [currentYear, setCurrentYear] = useState(null)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [sessionArray, setSessionArrays] = useState([
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 8, 27, 12, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 27, 1, 0),
    // },
    // {
    //   title: "Meeting with Harim",
    //   start: new Date(2023, 8, 11, 2, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 11, 3, 0),
    // },
    // {
    //   title: "Meeting with Azeem",
    //   start: new Date(2023, 8, 11, 3, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 11, 4, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 8, 11, 4, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 11, 5, 0),
    // },
  ])
  const [isSlotLoading, setIsSlotLoading] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const [isStatusLoading, setIsStatusLoading] = useState(false)

  const getSlots = async (coachId, month) => {
    setIsSlotLoading(true)
    try {
      const response = await BaseService.get(
        `/api/v1/coaching_slots/${coachId}/calendar/?year=${currentYear}&month=${month}`
      )

      setIsSlotLoading(false)
      const mappedSlotsData = response?.data
        ?.filter(item => !item?.is_reserved)
        ?.map(item => {
          // const start = convertToDateObject(item.date, item.start_time)
          // const end = convertToDateObject(item.date, item.end_time)
          const start = moment(
            `${item.date} ${item.start_time}`,
            "YYYY-MM-DD h:mmA"
          ).toDate()

          const end = moment(
            `${item.date} ${item.end_time}`,
            "YYYY-MM-DD h:mmA"
          ).toDate()

          return {
            title: "Available Slots",
            date: item?.date,
            start,
            end,
            id: item?.id,
            allDay: false
          }
        })

      const filteredSlotsData = mappedSlotsData.filter(newSlot => {
        return !sessionArray.some(existingSlot => {
          return (
            existingSlot.start.getTime() === newSlot.start.getTime() &&
            existingSlot.end.getTime() === newSlot.end.getTime()
          )
        })
      })
      // setSlotsData(prevSlotsData => [...prevSlotsData, ...filteredSlotsData])

      setSessionArrays(prevSessionArray => [
        ...prevSessionArray,
        ...filteredSlotsData
      ])
    } catch (error) {
      if (error?.response?.status == 503) {
        Error("Calendly Timeout, please try later.")
      }
      setIsSlotLoading(false)
    } finally {
    }
  }

  const convertBackendDataToUserTimeZone = backendData => {
    const backendDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    )
    const userTime = backendDateTime.setZone("America/New_York")
    const endDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    )
    const userEndTime = endDateTime.setZone("America/New_York")
    const formattedUtcOffset = "-05:00"

    return {
      ...backendData,
      date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"), // Use userEndTime
      utc_offset: formattedUtcOffset
    }
  }

  const getCoachCalender = async () => {
    setGetCoachesLoading(true)
    try {
      const response = await BaseService.get("/api/v1/coaches/calendar/")

      setGetCoachesLoading(false)
      const slotsAvailable = response?.data?.map(item => {
        if (item?.sessions) {
          const sessions = item?.sessions?.map(session =>
            convertBackendDataToUserTimeZone(session)
          )

          return {
            ...item,
            sessions
          }
        }

        return item
      })
      setsessionDetail(slotsAvailable)

      const res = slotsAvailable
        ?.map(item =>
          item?.sessions?.map(session => ({
            title: `${session?.call_type} with ${item?.first_name} ${item?.last_name}`,
            start: convertToDateObject(
              session?.session_date,
              session?.start_time
            ),
            end: convertToDateObject(session?.session_date, session?.end_time),
            id: session?.id,
            call_type: session?.call_type
          }))
        )
        ?.filter(item => item?.length > 0)

      const flattenedSessions = res.flat()

      setSessionArrays(prevSession => [...prevSession, ...flattenedSessions])
    } catch (error) {
      Error(error?.detail)
      setGetCoachesLoading(false)
    }
  }

  const convertToDateObject = (dateString, timeString) => {
    const dateParts = dateString.split("-")
    const timeParts = timeString.match(/(\d+):(\d+)(\w+)/)

    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1]) - 1 // Months are zero-based in JavaScript
    const day = parseInt(dateParts[2])

    let hour = parseInt(timeParts[1])
    const minute = parseInt(timeParts[2])
    const meridiem = timeParts[3].toLowerCase()

    if (meridiem === "pm" && hour < 12) {
      hour += 12 // Convert 12-hour format to 24-hour format for PM times
    } else if (meridiem === "am" && hour === 12) {
      hour = 0 // Convert 12 AM to 0 (midnight) in 24-hour format
    }

    return new Date(year, month, day, hour, minute)
  }

  const handleConnectVideo = (start, end, event) => {
    if (!isConnectionStatus) return setIsInfoModalOpen(true)

    const newStart = new Date(start)
    const newEnd = new Date(end)

    const matchingSlot = sessionArray.find(
      slot =>
        new Date(slot?.start).getTime() === newStart.getTime() ||
        new Date(slot?.end).getTime() === newEnd.getTime()
    )

    sessionDetail?.map(item =>
      item?.sessions?.map(
        itemOne =>
          itemOne?.id === event?.id && setcoacheeData(itemOne, event?.id)
      )
    )

    if (matchingSlot && matchingSlot?.call_type === "Coaching Session") {
      setRoomId(matchingSlot?.id)
      setOpenVideoModal(true)
    }
  }
  const CustomEvent = ({ event }) => {
    const { start, end, title } = event
    return (
      <>
        {" "}
        <AvailableSlotdiv onClick={() => handleConnectVideo(start, end, event)}>
          <EventTitle>{title}</EventTitle>
          <EventDate>
            {moment(start).format("h:mm A")} - {moment(end).format("h:mm A")}
          </EventDate>
        </AvailableSlotdiv>
      </>
    )
  }

  const CustomHeader = ({ date, sessionArray }) => {
    const day = moment(date).format("ddd")
    const dateNumber = moment(date).format("DD")

    return (
      <HeaderContent className="rbc-header-content">
        <HeaderDay className="rbc-header-day">{day}</HeaderDay>
        <HeaderDate className="rbc-header-date">
          {dateNumber}
          <span
            style={{
              position: "absolute",
              top: "8px",
              left: "13px"
            }}
          >
            {sessionArray?.find(
              session => moment(session?.end)?.format("DD") == dateNumber
            )
              ? "."
              : null}
          </span>
        </HeaderDate>
      </HeaderContent>
    )
  }

  const Toolbar = ({
    label,
    onView,
    view,
    onNavigate,
    localizer,
    setCurrentMonth,
    setCurrentYear
  }) => {
    const [monthName, year] = label.split(" ")

    const checkMonthAfterHyphen = dateRange => {
      // Split the string on '–' to separate the two dates
      const parts = dateRange.split("–").map(s => s.trim())

      // Check if we have two parts
      if (parts.length !== 2) return false

      // Extract the second part
      const secondPart = parts[1]

      // Create a regex to find the month name
      const monthRegex =
        /^(January|February|March|April|May|June|July|August|September|October|November|December)/

      // Test second part for month name
      const match = monthRegex.exec(secondPart)

      // If a match is found, return the month name; otherwise, return false
      return match ? match[0] : false
    }

    useEffect(() => {
      if (view === "month") {
        setCurrentMonth(monthName)
        setCurrentYear(year)
      } else if (view === "week") {
        setCurrentMonth(checkMonthAfterHyphen(label))
        setCurrentYear(new Date().getFullYear())
      }
    }, [label, view])

    return (
      <MainToolbarContainer className="my-toolbar" localizer={localizer}>
        <Header>
          <ButtonContent>
            <Button1 onClick={() => onNavigate("PREV")}>
              <LeftOutlined />
            </Button1>
            <Button1 onClick={() => onNavigate("NEXT")}>
              <RightOutlined />
            </Button1>
            <Span>{label}</Span>
          </ButtonContent>

          <Buttoncontent2>
            <Button2
              active={view === "week"}
              onClick={() => {
                onView("week")
                setViewdot("week")
              }}
            >
              Week
            </Button2>
            <Button2
              active={view === "month"}
              onClick={() => {
                onView("month")
                setViewdot("month")
              }}
            >
              Month
            </Button2>
          </Buttoncontent2>
        </Header>
      </MainToolbarContainer>
    )
  }

  const [dotsVisible, setDotsVisible] = useState(false)

  useEffect(() => {
    const updateDotsVisibility = () => {
      if (Viewdot === "month") {
        setDotsVisible(true)
      } else {
        setDotsVisible(false)
      }
    }

    updateDotsVisibility()
  }, [Viewdot])

  useEffect(() => {
    getCoachCalender()
  }, [])

  const handleMonthNumber = monthName => {
    return new Date(Date.parse(monthName + " 1, 2000")).getMonth() + 1
  }

  useEffect(() => {
    if (dotsVisible) {
      const dateCells = document.querySelectorAll(".rbc-date-cell")

      dateCells.forEach(dateCell => {
        const button = dateCell.querySelector(".rbc-button-link")
        const dateText = button.textContent.trim()

        const SessionArray = sessionArray?.find(
          session => moment(session?.end)?.format("DD") === dateText
        )

        if (SessionArray) {
          console.log("Adding dot for date:", dateText)

          const dotElement = document.createElement("span")
          dotElement.innerHTML = "."
          dotElement.classList.add("dot")

          dotElement.style.position = "absolute"
          dotElement.style.top = "11px"
          dotElement.style.left = "13px"
          dotElement.style.zIndex = "10000"

          dotElement.style.color = "black" // Adjust color
          dotElement.style.fontSize = "15px" // Adjust font size
          button.appendChild(dotElement)
        }
      })
    } else {
      const dateCellDots = document.querySelectorAll(".rbc-button-link .dot")
      dateCellDots.forEach(dot => dot.remove())
    }
  }, [dotsVisible, sessionArray])

  const getCalendlyStatus = async () => {
    setIsStatusLoading(true)
    try {
      const response = await BaseService.get("/rest-auth/user/")
      setIsConnectionStatus(response?.data?.calendly_connection_status)
      setGetUser(response?.data)
    } catch (error) {
      Error(error?.detail)
      setIsStatusLoading(false)
    } finally {
      setIsStatusLoading(false)
    }
  }

  const getCalendlyLink = async () => {
    try {
      const response = await BaseService.get(
        "/modules/calendly/auth/?redirectUrl=https://calm-darkness-38642.botics.co/app/coach/calendar/coach-calender"
      )

      if (response?.status === 200) {
        const redirectUrl = response?.data?.redirect_url
        window.open(redirectUrl, "_blank")
      }
    } catch (error) {
      Error(error?.detail)
    } finally {
    }
  }

  useEffect(() => {
    getCalendlyStatus()
  }, [])

  const getMonthNumber = dateString => {
    const date = new Date(dateString)
    return date.getMonth() + 1
  }

  useEffect(() => {
    if (!currentMonth) return

    const changeMonthNumber = handleMonthNumber(currentMonth)
    if (!getUser || currentMonth == null) return

    const isDataAvailableForCurrentMonth = sessionArray?.some(
      slot => getMonthNumber(slot?.date) === handleMonthNumber(currentMonth)
    )
    if (isDataAvailableForCurrentMonth) return

    getSlots(getUser.user_type_id, changeMonthNumber)
  }, [currentMonth])

  return (
    <Container>
      {(getCoachesLoading && <Loading />) || (isSlotLoading && <Loading />)}
      <Content>
        <Heading>Calendar</Heading>
      </Content>
      {isConnectionStatus && !isStatusLoading && (
        <Calendar
          localizer={localizer}
          startAccessor="start"
          events={sessionArray}
          endAccessor="end"
          style={{}}
          className="my-calendar-class"
          components={{
            toolbar: props => (
              <Toolbar
                setCurrentMonth={setCurrentMonth}
                setCurrentYear={setCurrentYear}
                {...props}
              />
            ),
            event: CustomEvent,
            week: {
              header: props => (
                <CustomHeader sessionArray={sessionArray} {...props} />
              )
            }
          }}
          min={new Date(0, 0, 0, 0, 0, 0)}
          max={new Date(0, 0, 0, 23, 1, 0)}
        />
      )}
      {!isConnectionStatus && !isStatusLoading && (
        <Calendlycontainer>
          <Calendlycontent>
            <CalendlyLogo />
            <Calendlyheading>Link your Calendly account</Calendlyheading>
            <CalendlyDesc>
              In order to set your availability, please link your Calendly
              account.
            </CalendlyDesc>
            <Calendlybtn>
              <Button onClick={() => getCalendlyLink()} isBorder>
                Link
              </Button>
            </Calendlybtn>
          </Calendlycontent>
        </Calendlycontainer>
      )}
      {isInfoModalOpen && (
        <InfoModal
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
          description={
            "Your next coaching session is just around the corner. Link your Calendly, and let's resume your path to success!"
          }
        />
      )}
      <VideocCallModal
        roomId={roomId}
        coacheeData={coacheeData}
        isVideoOpen={openVideoModal}
        setIsVideoOpen={setOpenVideoModal}
        videoRoomData={videoRoomData}
      />
      {/* <CalendlyUnlinkModal
      Info={`Are you sure you want to unlink your Calendly account. All future sessions will be cancelled`}
      Secondarybtntext={"Unlink"}
      Primarybtntext={"Cancel"}
      setIsUnlinkModalOpen={setIsUnlinkModalOpen}
      isUnlinkModalOpen={isUnlinkModalOpen}
      /> */}
    </Container>
  )
}

export default CoachCalendar

const RedirectedLink = styled.a`
  color: black;
`

const Container = styled.div`
  height: 100%;
  /* border: 1px solid red; */
  background-color: #fff;
`
const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  padding-left: 1.4rem;
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`

const BtnContent = styled.div`
  width: 123px;
  margin-left: 1.2rem;
`

const MainToolbarContainer = styled.div`
  background-color: #fff;
  height: 60px;
`
const Header = styled.div`
  /* border: 1px solid red; */
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`
const Span = styled.span`
  margin: 0;
  font-family: "Myriad-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`
const Button1 = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 9px;
`

const Buttoncontent2 = styled.div`
  padding: 2px;
  width: 343px;
  height: 32px;
  display: flex;
  background: ${({ theme }) => theme.border2};
  border-radius: 8px;
`

const Button2 = styled.button`
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.primary : theme.border2};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.textDark};
`

const HeaderContent = styled.div`
  min-height: 65px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`
const HeaderDay = styled.div`
  font-family: "Myriad-Light";
  font-weight: 600;
  font-size: 14px;
  line-height: 124.5%;
  text-align: center;
  color: #45485b;
`
const HeaderDate = styled.div`
  position: relative;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 124.5%;
  color: #45485b;
  /* color: red; */
  margin-top: 3px;
  padding: 2px 4px;
  border-radius: 50%;
`

const EventTitle = styled.div`
  color: #2b2b2b;
  margin: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
`

const AvailableSlotdiv = styled.div`
  background-color: transparent;
  font-size: 10px;
  font-weight: 600;
  display: flex;
`

const EventDate = styled.p`
  margin: 0;
  color: #2b2b2b;
`
const FootarButtoncontainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 11px 27px rgba(0, 0, 0, 0.15);
  padding: 0.7rem 1.5rem 0.7rem 0rem;
`
const FootarButton = styled.div`
  width: 10%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

const Calendlycontainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
`

const Calendlycontent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled.img``

const Calendlyheading = styled.h3`
  margin: 0;
  font-family: "Myriad-Regular";
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  margin-top: 1.2rem;
`

const CalendlyDesc = styled.p`
  width: 320px;
  text-align: center;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 17px;
  line-height: 24.5px;
  letter-spacing: -0.32px;
`

const Calendlybtn = styled.div`
  width: 100%;
  margin: 0.5rem;
`

const Mainbutton = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
`

const UnlinkContainer = styled.div`
  border: 1px solid #e2e9ed;
  width: 345px;
  height: 67px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CalendlyTitle = styled.h3`
  margin: 0;
  font-family: "Myriad-Regular";
  font-weight: 600;
  font-size: 16px;
  line-height: 23.24px;
  color: #2b2b2b;
`
const Unlinkbtn = styled.div`
  width: 75px;
  margin-right: 0.7rem;
`
