// import React, { useEffect, useState } from "react";
import styled from "styled-components"
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons"
import Button from "Elements/Button/Button"
import { Modal, TimePicker } from "antd"
import "./Overide.scss"
import moment from "moment"
// import { BaseService } from "API/base";
import { ReactComponent as DeleteIcon } from "./DeletRed.svg"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"

const AddSlotModal = ({
  slotData,
  openslot,
  setisOpenslot,
  getSlots,
  deleteObjectById,
  handleAddTime,
  handleChangeTime,
  errorMessages,
  handleSave
}) => {
  const onClose = () => {
    setisOpenslot(false)
    getSlots()
  }

  const addSpaceBeforeTimeIndicator = timeString => {
    const timeIndicators = ["AM", "PM"]
    for (const indicator of timeIndicators) {
      const index = timeString.indexOf(indicator)
      if (index !== -1) {
        const timeWithoutIndicator = timeString.substring(0, index)
        return `${timeWithoutIndicator} ${indicator}`
      }
    }
    return timeString
  }
  return (
    <Modal
      footer={false}
      className="Add_slot_modal"
      open={true}
      onCancel={onClose}
    >
      <ModalItem>
        <Heading>Availability hours</Heading>
        <Description>You can add 6 session slots</Description>
        <MainItem>
          {slotData?.map(item => (
            <>
              <ItemContainer>
                <InputWrapper>
                  <TimePicker
                    className="time_picker"
                    size="large"
                    placeholder={
                      item?.start_time &&
                      addSpaceBeforeTimeIndicator(item?.start_time)
                    }
                    errorMessages={errorMessages}
                    showNow={false}
                    use12Hours
                    format="hh:mm a"
                    // disabled={errorMessages[item?.id] !== undefined}
                    onChange={(time, timeString) =>
                      handleChangeTime(
                        item.id,
                        "start_time",
                        timeString?.toLocaleUpperCase()
                      )
                    }
                    style={{
                      borderColor: item?.error && "red"
                    }}
                    onSelect={value => {
                      if (value.$d instanceof Date) {
                        const timeString = moment(value.$d).format("hh:mm a")
                        handleChangeTime(
                          item.id,
                          "start_time",
                          timeString?.toLocaleUpperCase()
                        )
                        return timeString
                      } else {
                        return ""
                      }
                    }}
                  />

                  <Line />
                  <TimePicker
                    className="time_picker"
                    showNow={false}
                    size="large"
                    placeholder={
                      item?.end_time &&
                      addSpaceBeforeTimeIndicator(item?.end_time)
                    }
                    use12Hours
                    format="hh:mm a"
                    style={{
                      borderColor: item?.error && "red"
                    }}
                    onChange={(time, timeString) =>
                      handleChangeTime(
                        item.id,
                        "end_time",
                        timeString?.toLocaleUpperCase()
                      )
                    }
                    errorMessages={errorMessages}
                    onSelect={value => {
                      if (value.$d instanceof Date) {
                        const timeString = moment(value.$d).format("hh:mm a")
                        handleChangeTime(
                          item.id,
                          "end_time",
                          timeString?.toLocaleUpperCase()
                        )
                        return timeString
                      } else {
                        return ""
                      }
                    }}
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <DeleteButton onClick={() => deleteObjectById(item.id)}>
                    <DeleteIcon />
                  </DeleteButton>
                </ButtonWrapper>
              </ItemContainer>
            </>
          ))}
        </MainItem>
        <AdditemButton onClick={handleAddTime}>
          <PlusOutlined /> Add &nbsp;Time
        </AdditemButton>
        <ApplyButtonContainer>
          <Button isBorder onClick={handleSave}>
            Apply
          </Button>
        </ApplyButtonContainer>
      </ModalItem>
    </Modal>
  )
}

export default AddSlotModal

const ModalItem = styled.div`
  /* border: 1px solid red; */

  padding: 2px 14px;
`
const Heading = styled.h3`
  margin-bottom: 8px;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`

const Description = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`
const MainItem = styled.div`
  /* border: 1px solid purple; */
  min-height: 410px;
  max-height: 410px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
`
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 85%;

  .ant-picker-large {
    .ant-picker-input {
      input {
        text-transform: uppercase !important;
      }
    }
  }
`
const Input = styled.input`
  width: 128px;
  height: 48px;
  border: 1px solid #97adb647;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: #45485b;
  text-align: center;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  :focus {
    outline: none;
  }
`
const Line = styled.hr`
  height: 2px;
  outline: none;
  border: none;
  background-color: #45485b;
  width: 9px;
  margin: 0 0.5rem;
`
const ButtonWrapper = styled.div``
const DeleteButton = styled.button`
  outline: none;
  border: none;
  background-color: #ffcdcf;
  border-radius: 100px;
  padding: 9px 10px;
  cursor: pointer;
`
const Image = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
`
const AdditemButton = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #221f20;
  /* margin-top: 10px; */
  cursor: pointer;
`
const ApplyButtonContainer = styled.div`
  width: 100%;
  margin: 1.2rem 0rem;
`
