export const replaceUpdatedObject = (array, object, key) => {
  let items = array ? array : [];
  let index;
  items?.map((item, i) => {
    if (item[key] == object[key]) {
      index = i;
    }
  });
  items[index] = object;
  return [...items];
};
export const isSame = (array1, array2, key) => {
  const array1Ids = array1?.map((obj) => obj[key]);
  const array2Ids = array2?.map((obj) => obj[key]);
  return array1Ids.join() === array2Ids.join();
};
export const cleanUp = (obj, keyToRemove) => {
  if (!obj) return;
  let cleanedObject = { ...obj };
  Object.keys(cleanedObject)?.map(function (key) {
    if (keyToRemove.includes(key)) {
      delete cleanedObject[key];
    }
  });

  return cleanedObject;
};

export const validateURL = (string, split, index) => {
  // console.log(string?.split(split)[index], "validatedata");
  return string?.split(split)[index];
};
export const activeTabHandler = (
  validatedURL,
  route,
  activeStyle,
  inActiveStyle
) => {
  // console.log("validatedURL:", validatedURL);
  // console.log("route:", route);
  if (validatedURL === route) {
    // console.log("URL matches the route");
    return activeStyle;
  } else {
    // console.log("URL does not match the route");
    return inActiveStyle;
  }
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const formatRawDateToClean = (date, format) => {
  if (!date) return;
  let remakeing = date?.split("/");

  if (format === "space") {
    remakeing = `${remakeing[2]} ${remakeing[1]} ${remakeing[0]}`;
    console.log(remakeing);
    return remakeing?.toString();
  } else {
    remakeing = `${remakeing[2]}-${remakeing[1]}-${remakeing[0]}`;
    return remakeing?.toString();
  }
};
