import Button from "Elements/Button/Button";
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { SearchOutlined, TagOutlined } from "@ant-design/icons";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BaseService } from "API/base";
import { ReactComponent as LeftArrow } from "./left_arrow.svg";
import { ReactComponent as TermAndCondition } from "./terms-and-conditions1.svg";
import Loading from "Components/Loader/Loading";
import AddEmployesModal from "Components/AddEmployesModal/AddEmployesModal";
import moment from "moment";
import { DateTime } from "luxon";

const EmployeesDetails = () => {
  const [getDetailsLoading, setGetDetailsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [ishowModal, setIsShowModal] = useState(false);
  const [coacheesSession, setCoacheesSession] = useState();
  const [coacheesSessionLoading, setCoacheesSessionLoading] = useState(false);
  const [completedReport, setCompletedReport] = useState({});

  // const [isOpenList, setIsOpenList] = useState([]);
  // const ref = useRef(null);

  // const handleClickOutside = (event) => {
  //   if (ref.current && !ref.current.contains(event.target)) {
  //     console.log("jhell");
  //     setIsOpenList([]);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // });

  // useEffect(() => {
  //   // setIsOpenList(new Array(coacheesData?.length).fill(false));
  //   setIsOpenList([]);
  // }, [coacheesData]);

  // const toggleBoxWrapper = (index) => {
  //   const updatedIsOpenList = [...isOpenList];
  //   updatedIsOpenList[index] = !updatedIsOpenList[index];
  //   setIsOpenList(updatedIsOpenList);
  // };

  // const reviewCoachee = async () => {
  //   try {
  //     const response = await BaseService.get(
  //       `/api/v1/clients/employees/session/reviews/${coacheesData[1]?.id}/`
  //     );
  //     if (response?.status === 200) {
  //       console.log(response?.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   reviewCoachee();
  // }, []);

  const convertBackendDataToUserTimeZone = (backendData) => {
    // Convert backend data to the UK/London time zone (UTC)
    const backendDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    );

    // Convert to the UK/London time zone with the desired UTC offset (+02:00)
    const userTime = backendDateTime.setZone("America/New_York");

    // Calculate end_time based on backend.end_time
    const endDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    );

    // Convert end_time to the UK/London time zone with the desired UTC offset (+02:00)
    const userEndTime = endDateTime.setZone("America/New_York");

    // Format the UTC offset
    const formattedUtcOffset = "-04:00";

    // Return the converted data
    return {
      ...backendData,
      date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"), // Use userEndTime
      utc_offset: formattedUtcOffset,
    };
  };

  const getDetails = async () => {
    try {
      setGetDetailsLoading(true);
      const response = await BaseService.get(`api/v1/coachees/`);
      const filter = response?.data?.find((item) => item?.id == id);
      setDetails(filter);
      setGetDetailsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const finalReport = async () => {
    try {
      const response = await BaseService.get(
        `/api/v1/clients/employees/final_report/reviews/${employeeDetail?.id}/`
      );
      if (response?.status === 200) {
        setCompletedReport(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const employeeEngagmentInfo = async () => {
    try {
      const response = await BaseService.get(
        `api/v1/clients/employees/engagement_info/${id}/`
      );

      if (response?.status === 200) {
        setEmployeeDetail(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const employeeSession = async () => {
    try {
      setCoacheesSessionLoading(true);
      const response = await BaseService.get(
        `api/v1/clients/employees/sessions/${employeeDetail?.id}/`
      );
      if (response?.status === 200) {
        let ChangedData = response?.data?.map(convertBackendDataToUserTimeZone);

        setCoacheesSession(ChangedData);
        setCoacheesSessionLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    employeeEngagmentInfo();
  }, [id]);

  useEffect(() => {
    if (!employeeDetail) return;
    employeeSession();
    finalReport();
  }, [employeeDetail]);

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Container>
      {(getDetailsLoading && <Loading />) ||
        (coacheesSessionLoading && <Loading />)}
      <Content>
        <HeadingContent>
          <LeftArrow onClick={() => navigate(-1)} />
          <Heading>Employees Profile</Heading>
        </HeadingContent>
        {/* <Searchcontainer>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
            className="search_bar"
            style={{ borderRadius: "32px" }}
          />
          <br />
          <BtnContent>
            <Button isBorder onClick={() => setIsShowModal(true)}>
              Add Employee
            </Button>
          </BtnContent>
        </Searchcontainer> */}
      </Content>
      <Mainitem>
        <MyProfile>
          <Profileimg>
            <Image
              src={
                details?.profile_picture_url
                  ? details?.profile_picture_url
                  : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
              }
              alt=""
            />
          </Profileimg>
          <Profileinfo>
            <ProfileHeading>
              {details && `${details.first_name} ${details.last_name}`}
            </ProfileHeading>
            <ProfilePosition>{details?.title}</ProfilePosition>
            <Profilelocation>
              {" "}
              <TagOutlined />
              {details?.city}
            </Profilelocation>
          </Profileinfo>
        </MyProfile>

        {/* <ScheduleContainer>
          <Scheduleheading>Scheduled Sessions</Scheduleheading>

          <ScheduleContent>
            <HeadingItem>
              <Imgcontainer>
                <TermAndCondition />
              </Imgcontainer>
              <ImgDescription>
                <Title>Coaching Session</Title>
                <Descriptions>March 5, 2023 10:00 - 10:30 AM</Descriptions>
              </ImgDescription>
            </HeadingItem>
          </ScheduleContent>
        </ScheduleContainer> */}

        <PastContainer>
          <Pastheading>Scheduled Sessions</Pastheading>

          {coacheesSession?.map((item, index) => (
            <>
              <PastContent
                $is_notify
                key={item?.id}
                onClick={() =>
                  navigate(
                    `/app/client/coaching-session/${item?.id}/${details?.id}`
                  )
                }
              >
                <HeadingItem>
                  <Imgcontainer>
                    <TermAndCondition style={{ marginRight: 0 }} />
                  </Imgcontainer>
                  <ImgDescription>
                    <Title>{item?.call_type}</Title>
                    <Descriptions>
                      {` ${moment(item?.session_date).format(
                        "MMMM D, YYYY"
                      )} ${item?.start_time.slice(
                        0,
                        -2
                      )} ${item?.start_time.slice(
                        5,
                        8
                      )} - ${item?.end_time.slice(
                        0,
                        -2
                      )} ${item?.end_time.slice(5, 8)} EST `}
                    </Descriptions>
                  </ImgDescription>
                </HeadingItem>

                <Wrapper>
                  <ArrowWrapper>
                    <MdKeyboardArrowRight />
                  </ArrowWrapper>
                  {/* 
                  {item?.is_notify && !item?.is_reviewed_by_coach && (
                    <Notification>!</Notification>
                  )} */}
                </Wrapper>

                {/* {isOpenList[index] && (
                  <BoxWrapper ref={ref}>
                    <CustomRow
                    // onClick={() =>
                    //   navigate(
                    //     `/app/coach/review-session/${item?.id}/${profileData?.id}`
                    //   )
                    // }
                    >
                      <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                      <Text>Review Session</Text>
                    </CustomRow>
                    {index === coacheesData?.length - 1 && (
                      <CustomRow
                      // onClick={() =>
                      //   navigate(
                      //     `/app/coach/coachee-reports/${item?.engagement_info_id}/${profileData?.id}`
                      //   )
                      // }
                      >
                        <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                        <Text>Review Coachee</Text>
                      </CustomRow>
                    )}
                  </BoxWrapper>
                )} */}
              </PastContent>
            </>
          ))}
        </PastContainer>

        <FinalReportContainer>
          <Reportheading>Final Report</Reportheading>
          {completedReport ? (
            <PastContent

            // $is_notify
            // key={item?.id}
            // onClick={() =>
            //   navigate(
            //     `/app/client/coaching-session/${item?.id}/${details?.id}`
            //   )
            // }
            >
              <HeadingItem>
                <Imgcontainer>
                  <TermAndCondition style={{ marginRight: 0 }} />
                </Imgcontainer>
                <ImgDescription>
                  <Title>Final Report</Title>
                  {/* <Descriptions></Descriptions> */}
                </ImgDescription>
              </HeadingItem>

              <Wrapper>
                <ArrowWrapper>
                  <MdKeyboardArrowRight />
                </ArrowWrapper>
                {/* 
                  {item?.is_notify && !item?.is_reviewed_by_coach && (
                    <Notification>!</Notification>
                  )} */}
              </Wrapper>

              {/* {isOpenList[index] && (
                  <BoxWrapper ref={ref}>
                    <CustomRow
                    // onClick={() =>
                    //   navigate(
                    //     `/app/coach/review-session/${item?.id}/${profileData?.id}`
                    //   )
                    // }
                    >
                      <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                      <Text>Review Session</Text>
                    </CustomRow>
                    {index === coacheesData?.length - 1 && (
                      <CustomRow
                      // onClick={() =>
                      //   navigate(
                      //     `/app/coach/coachee-reports/${item?.engagement_info_id}/${profileData?.id}`
                      //   )
                      // }
                      >
                        <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                        <Text>Review Coachee</Text>
                      </CustomRow>
                    )}
                  </BoxWrapper>
                )} */}
            </PastContent>
          ) : (
            <ReportInfo>
              Final report will be available when all the coaching sessions are
              completed.
            </ReportInfo>
          )}
        </FinalReportContainer>
      </Mainitem>
      {ishowModal && (
        <AddEmployesModal onCancel={() => setIsShowModal(false)} />
      )}
    </Container>
  );
};

export default EmployeesDetails;

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`;

const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 2rem;

  svg {
    margin-bottom: 0.3rem;
  }
`;

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1.7rem;
`;
const Searchcontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BtnContent = styled.div`
  width: 210px;
  margin-left: 1.2rem;
`;

const Mainitem = styled.div`
  padding: 1.7rem 3rem;
`;

const MyProfile = styled.div`
  display: flex;
  align-items: center;
`;
const Profileimg = styled.div`
  height: 78px;
  width: 78px;
  margin-right: 0.9rem;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Profileinfo = styled.div``;

const ProfileHeading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

const ProfilePosition = styled.h5`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textMedium};
  margin: 0;
`;

const Profilelocation = styled.p`
  font-family: "Myriad-Regular";
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textMedium};
  margin: 0;

  .anticon svg {
    margin-right: 5px;
  }
`;

const ScheduleContainer = styled.div``;

const Scheduleheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`;

const ScheduleContent = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
`;

const HeadingItem = styled.div`
  padding: 0.6rem 0rem;
  display: flex;
  align-items: center;
`;
const Imgcontainer = styled.div`
  margin: 0 0.9rem;
  border-radius: 50%;
  padding: 9px 9px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundDark};
`;

const Img = styled.img``;
const ImgDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-family: "Myriad-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

const Descriptions = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.border};
  margin: 0;
`;

const PastContainer = styled.div``;

const Pastheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-top: 2rem;
`;

const PastContent = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  background-color: ${({ theme, is_notify }) =>
    is_notify && theme.backgroundDark};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 0.9rem;
  position: relative;
  svg {
    margin-right: 0.7rem;
    font-size: 1.2rem;
  }
  cursor: pointer;
`;

const FinalReportContainer = styled.div``;

const Reportheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-top: 2.7rem;
`;

const ReportInfo = styled.p`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.227%; /* 23.236px */
  margin: 0;
  margin-top: 0.7rem;
`;

const Wrapper = styled.div`
  position: relative;
  height: 70px;
`;
const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Notification = styled.div`
  background-color: ${({ theme }) => theme.danger};
  color: ${({ theme }) => theme.white};
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: -8px;
`;

const BoxWrapper = styled.div`
  width: 20%;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 31px rgba(43, 70, 88, 0.1);
  border-radius: 8px;
  z-index: 1;
  padding: 1rem;
  position: absolute;
  right: 2rem;
  top: 4.5rem;
`;
const CustomRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;
const Text = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  text-align: center;
  letter-spacing: -0.32px;

  color: ${({ theme }) => theme.textDark};
`;
