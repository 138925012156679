import React, { useEffect, useState } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Tabs } from "antd"
import CoachAverageRating from "./CoachAverageRating/CoachAverageRating"
import CoacheeAverageRating from "./CoacheeAverageRating/CoacheeAverageRating"
import { Data } from "../Analytics/mocData"
import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import { ReactComponent as Arrow } from "./LeftArrow.svg"
import { ReactComponent as LocationSvg } from "./location.svg"

const CoachDetails = () => {
  const [coach, setcoach] = useState()
  const [data, setData] = useState()
  const [getCoachLoading, setgetCoachLoading] = useState(false)
  const [averageRatingLoading, setaverageRatingLoading] = useState(false)
  const navigate = useNavigate()

  const onChange = key => {
    // console.log(key);
  }

  const { id } = useParams()

  const AverageRating = async () => {
    try {
      setaverageRatingLoading(true)
      const response = await BaseService.get(
        `api/v1/clinets/analytics/average_rating/${id}/`
      )
      if (response?.status === 200) {
        setData(response?.data)
      }

      setaverageRatingLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  console.log(data)

  const items = [
    {
      key: "1",
      label: `Coachee Average Rating`,
      children: <CoacheeAverageRating data={data} />
    },
    {
      key: "2",
      label: `Coach Average Rating`,
      children: <CoachAverageRating data={data} />
    }
  ]

  const getCoach = async () => {
    try {
      setgetCoachLoading(true)
      const response = await BaseService.get(`api/v1/coaches/${id}/`)
      if (response?.status === 200) {
        setcoach(response?.data)
      }

      setgetCoachLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCoach()
    AverageRating()
  }, [])

  return (
    <Container>
      {(getCoachLoading && <Loading />) ||
        (averageRatingLoading && <Loading />)}
      <Heading>
        <Arrow
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginBottom: "0.2rem" }}
        />{" "}
        Analytics
      </Heading>
      <Outlet />
      <TabWrapper>
        <ProfileSection>
          <ProfileImage
            src={
              coach?.profile_picture_url
                ? coach?.profile_picture_url
                : '"https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"'
            }
            alt=""
          />
          <ProfileDetails>
            <Name> {coach && `${coach?.first_name} ${coach?.last_name}`}</Name>
            <SubTitle>Coach</SubTitle>
            <Location>
              <LocationSvg />
              {coach?.city}
            </Location>
          </ProfileDetails>
        </ProfileSection>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </TabWrapper>
    </Container>
  )
}

export default CoachDetails
const Container = styled.div`
  height: 100vh;
  background-color: #ffffff;
`

const Heading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  padding-left: 2.5rem;
  border-bottom: 1px solid rgba(194, 193, 189, 0.57);
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
`

const TabWrapper = styled.div``
const ProfileSection = styled.div`
  padding: 1.5rem 2.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`
const ProfileImage = styled.img`
  width: 84px;
  height: 84px;
  border-radius: 50%;
`
const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 4px;
`
const Name = styled.p`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
  margin: 0;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  color: #5b5b5b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.24px;
  margin: 0;
`
const Location = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.24px;
  color: #5b5b5b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
  margin: 0;
`
