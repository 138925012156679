import Button from "Elements/Button/Button";
import Modal from "Elements/CustomModal/Modal";
import Input from "Elements/Input/Input";
import SearchInput from "Elements/SearchInput/SearchInput";
import React, { useState } from "react";
import styled from "styled-components";
import ThemeSwitch from "../Elements/ThemeSwitch/ThemeSwitch";
import { FaUserAlt } from "react-icons/fa";
import TabGroupButton from "Elements/TabsButton/TabsButton";
import OtpModal from "Elements/OtpInput/OtpInput";
import AvatarDropdown from "Elements/AvatarDropdown/AvatarDropdown";
import Signup from "Components/Signup/Signup";
import Phoneotp from "Components/Signup/Phoneotp";
import Signin from "Components/Signup/Sign in/Signin";
import Navbar from "Components/Signup/Navbar/Navbar";
import Footar from "Components/Signup/Footar/Footar";
import { activeTabHandler, validateURL } from "Utils/Reusable-Functions";
import { useHistory } from "Hooks/history";
import { useLocation } from "react-router-dom";

import LogoutModal from "Components/LogoutModal/LogoutModal";
import FeedbackModal from "Components/FeedbackModal/FeedbackModal";
// import RequestModal from "Components/RequestModal/RequestModal";
import ChangePasswordModal from "Components/ChangePasswordModal/ChangePasswordModal";
import AddEmployesModal from "Components/AddEmployesModal/AddEmployesModal";
import AddSlotModal from "Components/AddSlotModal/AddSlotModal";

// import DragDropFile from "Elements/Drag&DropFiles/DragDropFile";

const DisplayElements = () => {
  const { Navigate } = useHistory();
  const location = useLocation().pathname;

  // const signInData = [
  //   {
  //     title: "Email",
  //     status: "Email",
  //   },
  //   {
  //     title: "Phone",
  //     status: "Phone",
  //   },

  // ]

  // const tabData = [
  //   {
  //     title: "All",
  //     status: "ALL",
  //   },
  //   {
  //     title: "Email",
  //     status: "ACTIVE",
  //   },
  //   {
  //     title: "Phone Number",
  //     status: "INACTIVE",
  //   },
  // ];

  const ModalData = [
    {
      logo: "/Assets/message logo.png",
      title: "Email confirmation",
      Description: " A code was sent to mikegray.23@gmail.com",
      tag: "Send again",
    },
  ];

  const PhoneData = [
    {
      logo: "/Assets/Phone Logo.png",
      title: "Phone confirmation",
      Description: " A confirmation code was sent to +xxxxxxx12.",
      tag: "Send again",
    },
  ];

  //  const listItems = [
  //   { id: 1, avatar: "/Assets/half moon.png" },
  //   { id: 2, avatar: "/Assets/sun.png" },
  //   { id: 3, avatar: "/Assets/Vector.png" },
  // ];

  return (
    <Wrapper>
      <Heading>Welome to the UI - KIT</Heading>
      {/* {
Render your elements here 
} */}

      <BtnWrap>
        {/* <AddSlotModal/> */}
        {/* <AddEmployesModal/> */}
        {/* <DeleteModal/> */}
        {/* <LogoutModal/> */}
        {/* <FeedbackModal/> */}
        {/* <RequestModal/> */}
        {/* <ChangePasswordModal/> */}
        {/* <Input
                    label={"Enter Phone Number"}
                    type="password"
                    placeholder={"Enter your Name"}
                    isRight
                /> */}
        {/* <SearchInput /> */}
        {/* <Button isBorder>
          Submit <FaUserAlt />
        </Button> */}

        {/* <Input
                    label={"Enter Phone Number"}
                    type="email"
                    placeholder={"Enter your Name"}
                    isRight
                /> */}

        {/* <AvatarDropdown listItems={listItems}/> */}
        {/* <Signup /> */}

        {/* <OtpModal ModelItems={ModalData}/> */}

        {/* <Phoneotp ModelItems={PhoneData} />  */}

        {/* <Signin /> */}

        {/* <Textarea placeholder={'Please write your message'}/> */}

        {/* <Navbar  /> */}

        {/* <Footar /> */}
      </BtnWrap>
      {/* <Modal
                Heading={"Error"}
                // onClick={}
                src={"/Assets/error_icon.svg"}
                text={"Please try again later or contact support"}
            /> */}
      {/* <DargWrapper>
        <DragDropFile />
      </DargWrapper> */}
    </Wrapper>
  );
};

export default DisplayElements;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  min-height: 100vh;
  /* padding: 1rem; */
  width: 100%;
  height: auto;
`;
const Heading = styled.h2`
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;
const BtnWrap = styled.div`
  width: 100%;
`;
