import PicUploadModal from "Components/ProfilePicUploadModal/PicUploadModal"
import Button from "Elements/Button/Button"
import Input from "Elements/Input/Input"
import React, { Fragment, useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import { ReactSVG } from "react-svg"
import { coachProfileSchema } from "Schemas"
import { Formik, Form } from "formik"
import { BaseService } from "API/base"
import { Error } from "Responses/Response"
import { getCookie, removeAllCookie, setCookie } from "Utils/StorageVariables"
import { ReactComponent as ProfileAvatar } from "./profile_avatar.svg"
import { ReactComponent as UploadVideo } from "./upload_video.svg"
import { ReactComponent as DeleteIcon } from "./delete_icon.svg"
import { ReactComponent as DeleteIconWhite } from "./delete_white_icon.svg"
import { ReactComponent as CertificateIconSvg } from "./certificate_Icon.svg"
import { GlobalStateContext } from "GlobalStateContext"
import Loading from "Components/Loader/Loading"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
const CoachProfile = () => {
  const playerRef = useRef(null)

  const handleProgress = progress => {
    if (progress.playedSeconds > 50) {
      playerRef.current.seekTo(50, "seconds")
    }
  }
  // const handleDuration = (videoDuration) => {
  //   if (videoDuration > 50) {
  //     // Display an error message or take appropriate action
  //     toast.error("Error: Video duration exceeds 50 seconds");
  //   }
  // };
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const [addCertificate, setAddCertificate] = useState()
  const userData = JSON.parse(getCookie("userData"))
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)
  const [deletCertificateLoading, setdeletCertificateLoading] = useState()
  const [getCoachLoading, setGetCoachLoading] = useState(false)
  const [isShowImage, setisShowImage] = useState(null)
  const formik = useRef()
  const navigate = useNavigate()
  const handleAddCertificate = (setFieldValue, prevCertificates, e) => {
    e.preventDefault()
    setFieldValue("certificates", [
      ...prevCertificates,
      { name: inputValue, id: prevCertificates?.length + 1, isNew: true }
    ])
    setAddCertificate(!addCertificate)
    setInputValue("")
  }

  const setValues = data => {
    formik?.current?.setFieldValue("first_name", data?.first_name)
    formik?.current?.setFieldValue("profile_picture", data?.profile_picture_url)
    formik?.current?.setFieldValue("last_name", data?.last_name)
    formik?.current?.setFieldValue(
      "years_of_experience",
      data?.years_of_experience
    )
    formik?.current?.setFieldValue("bio", data?.bio)
    formik?.current?.setFieldValue("zip_code", data?.zip_code)
    formik?.current?.setFieldValue("city", data?.city)
    formik?.current?.setFieldValue("intro_video", data?.intro_video_url)
    formik?.current?.setFieldValue("certificates", data?.certificates)
  }

  const deleteCertificate = async id => {
    setdeletCertificateLoading(true)
    try {
      const { status } = await BaseService.remove(
        `api/v1/coaches/${userData?.user_type_id}/certificates/${id}/`,
        true
      )
      if (status === 200) {
        getCoach()
        setdeletCertificateLoading(false)
      }
    } catch (error) {
      Error(error)
    }
  }

  const handleDeleteInput = (setFieldValue, prevCerts, certificate, e) => {
    e.preventDefault()
    if (certificate?.isNew) {
      setFieldValue("certificates", [
        ...prevCerts?.filter(cert => cert?.id !== certificate?.id)
      ])
    } else {
      deleteCertificate(certificate?.id)
    }
  }
  const getCoach = async () => {
    try {
      setGetCoachLoading(true)
      const response = await BaseService.get(
        `api/v1/coaches/${userData?.user_type_id}/`,
        true
      )
      setValues(response?.data)
      updateGlobalState("profile_details", response?.data)
      // setCookie("globalState", JSON.stringify(globalState));
      setGetCoachLoading(false)
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        removeAllCookie()
      }
    } finally {
      setTimeout(() => {
        setGetCoachLoading(false)
      }, 5000) // 50
    }
  }
  useEffect(() => {
    getCoach()
  }, [])

  const handleDelete = async (e, isLocalVideo, setFieldValue) => {
    e.preventDefault()
    if (isLocalVideo) {
      setFieldValue("intro_video", "")
      setVideoUrl(null)
      return
    }

    try {
      setdeletCertificateLoading(true)
      const { status } = await BaseService.remove(
        `api/v1/coaches/${userData?.user_type_id}/delete_intro_video/`,
        true
      )
      if (status === 204) {
        getCoach()
        setdeletCertificateLoading(false)
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        removeAllCookie()
      }
    }
  }

  const onSubmit = async values => {
    setIsLoading(true)
    try {
      // console.log({ check: values?.certificates });
      const certificates = values?.certificates
        ?.filter(cert => cert?.isNew)
        ?.map(({ name }) => {
          return { name }
        })
      // console.log({ certificates });
      const form = new FormData()
      if (typeof values?.profile_picture === "object") {
        form.append("profile_picture", values?.profile_picture)
      }
      form.append("first_name", values?.first_name)
      form.append("last_name", values?.last_name)
      form.append("years_of_experience", values?.years_of_experience)
      form.append("bio", values?.bio)
      form.append("zip_code", values?.zip_code || "")
      form.append("city", values?.city || "")
      if (typeof values?.intro_video === "object" && values?.intro_video) {
        form.append("intro_video", values?.intro_video)
      }
      form.append("certificates", JSON.stringify(certificates))

      const response = await BaseService.put(
        `api/v1/coaches/${userData?.user_type_id}/`,
        form,
        true,
        true
      )
      if (response?.status === 200) {
        setValues(response?.data)
        updateGlobalState("profile_details", response?.data)
        setisShowImage(null)
        setAvatarUrl(null)
      }
    } catch (error) {
      console.error({ error })
      Error(error?.response?.data?.detail)
    } finally {
      setIsLoading(false)
    }
  }

  const [showInput, setShowInput] = useState(true)

  const [validVideo, setValidVideo] = useState(true)

  // ...

  const handleVideoUpload = event => {
    const file = event.target.files[0]
    const video = document.createElement("video")

    video.preload = "metadata"
    video.onloadedmetadata = () => {
      const duration = Math.floor(video.duration)
      if (duration <= 50) {
        const reader = new FileReader()
        reader.onload = event => {
          setVideoUrl(event.target.result)
          setValidVideo(true) // Set validVideo to true when a valid video is selected
          setShowInput(false) // Hide the input when videoUrl is available
        }
        reader.readAsDataURL(file)
      } else {
        toast.error(
          "Error: Video duration exceeds 50 seconds. Please select a shorter video."
        )
        event.target.value = null
        event.target.focus()
        setVideoUrl(null)
        setValidVideo(false) // Set validVideo to false when an invalid video is selected
        setShowInput(true) // Show the input again when there is an error
      }
    }

    video.src = URL.createObjectURL(file)
  }
  const initialValues = {
    first_name: "",
    last_name: "",
    years_of_experience: "",
    bio: "",
    city: "",
    zip_code: "",
    profile_picture: "",
    intro_video: "",
    certificates: []
  }
  const addCertificates = e => {
    e.preventDefault()
    setAddCertificate(!addCertificate)
  }
  const renderImg = values => {
    if (isShowImage) return isShowImage
    if (avatarUrl) return avatarUrl
    if (values?.profile_picture) return values?.profile_picture
  }
  return (
    <Fragment>
      <MainContainer>
        {(deletCertificateLoading && <Loading />) ||
          (getCoachLoading && <Loading />)}
        <Header>
          <Heading>My Profile</Heading>
        </Header>
        <Screen>
          <Forms>
            <Formik
              initialValues={initialValues}
              validationSchema={coachProfileSchema}
              onSubmit={onSubmit}
              innerRef={formik}
            >
              {({ errors, touched, setFieldValue, values }) => {
                // console.log(errors)

                return (
                  <Form>
                    <PicUploadModal
                      setAvatarUrl={setAvatarUrl}
                      avatarUrl={avatarUrl}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      setFieldValue={setFieldValue}
                      isShowImage={isShowImage}
                      values={values}
                      setisShowImage={setisShowImage}
                      title={"Upload Photo"}
                    />
                    <CompanyLogo onClick={() => setIsOpen(true)}>
                      {values?.profile_picture ? (
                        <UploadImageContainer>
                          <UploadedImage src={renderImg(values)} alt="avatar" />
                          <UploadLogoText>Upload Photo*</UploadLogoText>
                        </UploadImageContainer>
                      ) : (
                        <>
                          {/* <Image
                            src="/Assets/profile_avatar.svg"
                            alt="avatar"
                          /> */}
                          <ProfileAvatar />
                          <LogoText>Upload Photo*</LogoText>
                        </>
                      )}
                    </CompanyLogo>
                    {errors.profile_picture && touched.profile_picture ? (
                      <Span style={{ textAlign: "center" }}>
                        {errors.profile_picture}
                      </Span>
                    ) : null}
                    <InputWrapper>
                      <Input
                        id="first_name"
                        label={"First Name*"}
                        name="first_name"
                        type="text"
                        value={values?.first_name}
                        placeholder={"First Name*"}
                        onChange={event => {
                          setFieldValue("first_name", event.target.value)
                        }}
                      />
                      {errors.first_name && touched.first_name ? (
                        <Span>{errors.first_name}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        id="last_name"
                        name="last_name"
                        label={"Last Name*"}
                        value={values?.last_name}
                        type="text"
                        placeholder={"Last Name*"}
                        onChange={event => {
                          setFieldValue("last_name", event.target.value)
                        }}
                      />
                      {errors.last_name && touched.last_name ? (
                        <Span>{errors.last_name}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        id="years_of_experience"
                        name="years_of_experience"
                        label={"Years of Coaching Experience*"}
                        type="number"
                        value={values?.years_of_experience}
                        placeholder={"Years of Experience*"}
                        onChange={event => {
                          setFieldValue(
                            "years_of_experience",
                            event.target.value
                          )
                        }}
                      />
                      {errors.years_of_experience &&
                      touched.years_of_experience ? (
                        <Span>{errors.years_of_experience}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        id="bio"
                        name="bio"
                        label={
                          "Short bio about your executive coaching career*"
                        }
                        type="text"
                        value={values?.bio}
                        placeholder={
                          "Short Bio text about great motivation coach"
                        }
                        onChange={event => {
                          setFieldValue("bio", event.target.value)
                        }}
                      />
                      {errors.bio && touched.bio ? (
                        <Span>{errors.bio}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        id="city"
                        label={"City*"}
                        name="city"
                        type="text"
                        value={values?.city}
                        placeholder={"City*"}
                        onChange={event => {
                          setFieldValue("city", event.target.value)
                        }}
                      />
                      {errors.city && touched.city ? (
                        <Span>{errors.city}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        id="zip_code"
                        label={"ZIP Code"}
                        value={values?.zip_code}
                        name="zip_code"
                        type="text"
                        placeholder={"Zip Code"}
                        onChange={event => {
                          setFieldValue("zip_code", event.target.value)
                        }}
                      />
                      {errors.zip_code && touched.zip_code ? (
                        <Span>{errors.zip_code}</Span>
                      ) : null}
                    </InputWrapper>
                    <IntroVideoSection>
                      <IntroHeading>Introduction Video</IntroHeading>
                      <IntroSubHeading>
                        Please upload a video introducing yourself. Video limit
                        is 50 seconds
                      </IntroSubHeading>
                      <UploadVideoContainer>
                        {(videoUrl || values?.intro_video) && validVideo ? (
                          <ReactPlayerContainer>
                            <ReactPlayer
                              ref={playerRef}
                              width={"100%"}
                              url={videoUrl ? videoUrl : values?.intro_video}
                              controls={true}
                              height={"auto"}
                              onProgress={handleProgress}
                            />
                            <DeleteButton
                              onClick={e =>
                                handleDelete(e, videoUrl, setFieldValue)
                              }
                            >
                              <DeleteIconWhite />
                            </DeleteButton>
                          </ReactPlayerContainer>
                        ) : (
                          <>
                            <input
                              style={{ visibility: "hidden" }}
                              id="intro_video"
                              name="intro_video"
                              type="file"
                              onChange={event => {
                                setFieldValue(
                                  "intro_video",
                                  event.currentTarget.files[0]
                                )
                                handleVideoUpload(event)
                              }}
                            />

                            <UploadVideo />
                            <UploadVideoText for="intro_video">
                              Upload Video
                            </UploadVideoText>
                            {/* Show the input section again only when the video is invalid */}
                            {!validVideo && (
                              <div>
                                {/* Add any additional elements or error messages here if needed */}
                              </div>
                            )}
                          </>
                        )}
                      </UploadVideoContainer>
                    </IntroVideoSection>

                    <CertificateContainer>
                      <CertificateHeading>
                        Certificates and documentation
                      </CertificateHeading>
                      <CertificateSubHeading>
                        Please list any certificates you may have.
                      </CertificateSubHeading>
                      <AddCertificateContainer>
                        {/* {!showAddCertificateButton ? ( */}
                        {addCertificate && (
                          <AddCertificate>
                            <InputWrapper width="80%">
                              <Input
                                type="text"
                                placeholder={"Certificate Name"}
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                Error={
                                  errors.certificate && touched.certificate
                                }
                              />
                            </InputWrapper>
                            <AddButton
                              onClick={e => {
                                handleAddCertificate(
                                  setFieldValue,
                                  values?.certificates,
                                  e
                                )
                              }}
                            >
                              Add
                            </AddButton>
                          </AddCertificate>
                        )}
                        {/* ) : ( */}
                        {!addCertificate && (
                          <AddCertificateBtn onClick={e => addCertificates(e)}>
                            + Add Certificate
                          </AddCertificateBtn>
                        )}
                        {/* )}  */}
                        {values?.certificates.map((cert, index) => (
                          <>
                            {cert?.name === "" ? null : (
                              <CertificateList key={index}>
                                <CertificateWrapper>
                                  <CertificateIconSvg />
                                  <CertificateName>
                                    &nbsp;
                                    {cert?.name}
                                  </CertificateName>
                                </CertificateWrapper>
                                <ButtonDelete
                                  disabled={
                                    deletCertificateLoading && "disabled"
                                  }
                                  onClick={e =>
                                    handleDeleteInput(
                                      setFieldValue,
                                      values?.certificates,
                                      cert,
                                      e
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ marginTop: "0.2rem" }} />
                                </ButtonDelete>
                              </CertificateList>
                            )}
                          </>
                        ))}
                      </AddCertificateContainer>
                    </CertificateContainer>
                    <SaveBtnWrapper>
                      <Button isBorder disabled={isLoading}>
                        {isLoading ? "Saving..." : "Save"}
                      </Button>
                    </SaveBtnWrapper>
                  </Form>
                )
              }}
            </Formik>
          </Forms>
        </Screen>
      </MainContainer>
    </Fragment>
  )
}

export default CoachProfile

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  height: 100vh;
`
const Header = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  height: 11vh;
  border-bottom: 1px solid #dbd9d5;
  display: flex;
  align-items: center;
  padding: 0 3rem;
`
const Heading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  color: ${({ theme }) => theme.textDark};
  line-height: 41px;
  font-family: "Myriad-Regular";
`

const Screen = styled.div`
  height: calc(100vh - 8vh);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Forms = styled.div`
  background-color: #ffffff;
  width: 50%;
  padding: 2rem 4rem 4rem 4rem;
  height: auto;
  border: 1px solid #dbd9d5;
  border-radius: 20px;
  height: calc(100vh - 26vh);
  overflow-y: auto;
`

const InputWrapper = styled.div`
  margin: 10px 0;
  width: ${({ width }) => width && width};
`

const CompanyLogo = styled.div`
  cursor: pointer;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundDark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
`
const Image = styled.img`
  width: 50px;
  height: auto;
`
const LogoText = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.primary_dark};
  font-family: "Myriad-Regular";
`

const CertificateContainer = styled.div`
  margin: 1rem 0;
`
const CertificateHeading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  margin: 10px;
`
const CertificateSubHeading = styled.h4`
  font-family: "Myriad-Regular";
  margin: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
`

const SaveBtnWrapper = styled.div`
  margin-top: 30px;
`
const AddButton = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 4rem;
  cursor: pointer;
  padding: 5px;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-family: "Myriad-SemiBold";
  margin: 0;
`
const IntroVideoSection = styled.div`
  margin: 2rem 0 1rem 0;
`
const IntroHeading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  margin: 10px;
`
const IntroSubHeading = styled.h4`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin: 10px;
`
const UploadVideoContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundDark};
  width: inherit;
  height: 185px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`
const UploadVideoIcon = styled.img`
  width: 86px;
  height: auto;
`
const UploadVideoText = styled.label`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: ${({ theme }) => theme.primary_dark};
  cursor: pointer;
`

const AddCertificateContainer = styled.div``
const AddCertificate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AddCertificateBtn = styled.p`
  margin: 0;
  margin-top: 0.6rem;
  background-color: transparent;
  cursor: pointer;
  border: none;
  font-family: "Myriad-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 1rem;
`
const UploadedImage = styled.img`
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
`
const UploadImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const UploadLogoText = styled.p`
  position: absolute;
  bottom: 8px;
  left: 35px;
  font-size: 14px;
  color: ${({ theme }) => theme.textWhite};
  font-weight: 600;
`

const ReactPlayerContainer = styled.div`
  width: 100%;
  height: 185px;
  position: relative;
`
const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.danger};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
// const DeleteIcon = styled.img``;

const CertificateList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
`
const ButtonDelete = styled.p`
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
`
// const DltImage = styled(ReactSVG)`
//   svg {
//     width: 34px;
//     height: 34px;
//     path {
//       fill: red;
//     }
//   }
// `;
const CertificateName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.textDark};
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 0.7rem;
`
const CertificateIcon = styled.img`
  width: 32px;
  height: auto;
`
const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`
const CertificateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
