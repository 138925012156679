import ProgressBar from "Elements/ProgressBar/ProgressBar"

import React from "react"
import styled from "styled-components"

const CoacheeReview = ({ data }) => {
  const width = `${(data?.rate_1 / 5) * 100}%`
  return (
    <Container>
      {data?.rate_1 ? (
        <>
          <SubHeading>Participant Engagement Score</SubHeading>
          <Progress>
            <ProgressBar width={width} color={"#88B3E7"} />
            <Persentage>{data?.rate_1?.toFixed(2)}</Persentage>
          </Progress>
          <Discription>
            <Points>
              <Box style={{ backgroundColor: "#88B3E7" }}></Box>
              <Para>
                <Span>Coach Evaluation: </Span>On a scale of 1 to 5, please rate
                how the session went.
              </Para>
            </Points>
          </Discription>
          <FinalReports>
            <ReportHeading>
              Coachee impressions about coaching session
            </ReportHeading>
            <Para>{data?.comment}</Para>
          </FinalReports>
        </>
      ) : (
        ""
      )}
      {/* <SubHeading>Feedback from Coachee</SubHeading>
      <Progress>
        <ProgressBar width={width} color={"#88B3E7"} />
        <Persentage>{data?.rate?.toFixed(2)}</Persentage>
      </Progress>
      <Progress>
        <ProgressBar width={"70%"} color={"#434348"} />
        <Persentage>3.00</Persentage>
      </Progress>
      <Progress>
        <ProgressBar width={"100%"} color={"#A6EB8A"} />
        <Persentage>5.00</Persentage>
      </Progress>
      <Progress>
        <ProgressBar width={"100%"} color={"#E4BF44"} />
        <Persentage>5.00</Persentage>
      </Progress>
      <Discription>
        <Points>
          <Box style={{ backgroundColor: "#88B3E7" }}></Box>
          <Para>
            <Span>Coachee Evaluatio :</Span>On a scale of 1 to 5, please rate
            how the session went.
          </Para>
        </Points>
         <Points>
          <Box style={{ backgroundColor: "#434348" }}></Box>
          <Para>
            <Span>Coach Evaluation :</Span> How engaged was the coachee’s
            manager/supervisor?
          </Para>
        </Points> 
         <Points>
          <Box style={{ backgroundColor: "#A6EB8A" }}></Box>
          <Para>
            <Span>Coach Evaluation :</Span>How easy was it to work with the
            coachee on this engagement?
          </Para>
        </Points>
        <Points>
          <Box style={{ backgroundColor: "#E4BF44" }}></Box>
          <Para>
            <Span>Coach Evaluation :</Span>To what extent are you satisfied with
            the support SGI has provided in contracting, providing structure,
            and providing any logistical assistance on this engagement?
          </Para>
        </Points> 
      </Discription>
      <FinalReports>
        <ReportHeading>
          Coachee impressions about coaching session
        </ReportHeading>
        <Para>
          The Apex values are exactly what I am looking for in a company,
          especially when it comes to teamwork. I was impressed by the passion
          that everyone had for the company.There is a really strong sense of
          teamwork not just within the different teams, but throughout the
          company. Everyone is willing to support and encourage others, and this
          results in high morale and overall success. As I have thought, all the
          staff at Apex are amazing and Apex has really great teaching system.
        </Para>
      </FinalReports> */}
    </Container>
  )
}

export default CoacheeReview
const Container = styled.div`
  padding: 0 2.2rem;
  height: fit-content;
`
const SubHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  margin: 0.8rem 0;
`
const Progress = styled.div`
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`
const Persentage = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
const Discription = styled.div``
const Points = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 95%;
`
const Box = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 4px;
`
const Para = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  gap: 0.5rem;
`
const Span = styled.span`
  font-weight: 800;
  white-space: nowrap;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const Name = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50.23%;
  color: #2b2b2b;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
`
const FinalReports = styled.div``
const ReportHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
