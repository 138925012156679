import React from "react"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import Button from "Elements/Button/Button"
import styled from "styled-components"

const InfoModal = ({ isInfoModalOpen, setIsInfoModalOpen, description }) => {
  const onClose = () => {
    setIsInfoModalOpen(false)
  }

  return (
    <Modal
      footer={false}
      className="delete_modal"
      open={isInfoModalOpen}
      onCancel={onClose}
      centered
    >
      <Container>
        <ModelContent>
          <ExclamationCircleOutlined />
          <Description>{description}</Description>
          <ButtonContent>
            <Button isBorder onClick={() => onClose()}>
              Close
            </Button>
          </ButtonContent>
        </ModelContent>
      </Container>
    </Modal>
  )
}

export default InfoModal

const Container = styled.div`
  height: 440px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .anticon svg {
    color: #e4bf44;
    font-size: 100px;
  }
`

const Description = styled.p`
  font-style: normal;
  font-family: "Myriad-Regular";
  width: 350px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 2rem;
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 295px;
  gap: 1rem;
`
