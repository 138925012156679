import { createGlobalStyle } from "styled-components";

import MyriadBold from "./Font/static/MYRIADPRO-BOLD.OTF";
import MyriadLight from "./Font/static/MyriadPro-Light.otf";
import MyriadRegular from "./Font/static/MYRIADPRO-REGULAR.OTF";
import MyriadSemiBold from "./Font/static/MYRIADPRO-SEMIBOLD.OTF";

export const GlobalStyles = createGlobalStyle`
*{

    @font-face {
	font-family: 'Myriad-Bold';
	src: url(${MyriadBold}) format("opentype");
}
    @font-face {
	font-family: 'Myriad-Light';
	src: url(${MyriadLight}) format("opentype");
}
    @font-face {
	font-family: 'Myriad-Regular';
	src: url(${MyriadRegular}) format("opentype");
}
    @font-face {
	font-family: 'Myriad-SemiBold';
	src: url(${MyriadSemiBold}) format("opentype");
}



}`;
