import React from "react";
import styled from "styled-components";
import { BsCheck2Circle } from "react-icons/bs";
import Button from "Elements/Button/Button";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const RequestModal = ({ description, isOpen, setisOpen }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setisOpen(false);
    navigate(-1);
  };

  return (
    <>
      <Modal
        open={isOpen}
        footer={false}
        className="request_modal"
        onCancel={handleClose}
        centered
      >
        <Container>
          <ModelContent>
            <BsCheck2Circle />
            <Description>{description}</Description>
            <ButtonContent>
              <Button isBorder onClick={handleClose}>
                Got it
              </Button>
            </ButtonContent>
          </ModelContent>
        </Container>
      </Modal>
    </>
  );
};

export default RequestModal;

const Container = styled.div`
  height: 387px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.primary};
    font-size: 120px;
    margin-left: 1rem;
  }
`;

const Description = styled.p`
  font-style: normal;
  width: 270px;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 2rem;
`;

const ButtonContent = styled.div`
  width: 295px;
`;
