import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import ProgressBar from "Elements/ProgressBar/ProgressBar"
import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import { ReactComponent as Arrow } from "./Arrow.svg"

const AggregateDataAnalytics = () => {
  const navigate = useNavigate()

  const [aggregateData, setAggregateData] = useState()
  const [clientCoaches, setClientCoaches] = useState()
  const [aggregateDataLoading, setAggregateDataLoading] = useState(false)
  const [clientCoacheesLoading, setclientCoacheesLoading] = useState(false)

  const AggregatePercentage = data => {
    if (data?.total_sessions_allotted_for_all_employees === 0) {
      return 0
    }
    let percentage =
      (data?.total_sessions_completed /
        data?.total_sessions_allotted_for_all_employees) *
      100

    percentage = Math.max(0, Math.min(100, percentage))

    return percentage
  }

  const width = AggregatePercentage(aggregateData)

  const getAggregateData = async () => {
    try {
      setAggregateDataLoading(true)
      const response = await BaseService.get(
        `api/v1/clients/analytics/aggregate_data/`
      )
      setAggregateData(response?.data)

      setAggregateDataLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getclientsCoaches = async () => {
    try {
      setclientCoacheesLoading(true)
      const response = await BaseService.get(`api/v1/clients/coaches`)
      if (response?.status === 200) {
        setClientCoaches(response?.data)
        setclientCoacheesLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAggregateData()
    getclientsCoaches()
  }, [])

  return (
    <Container>
      {(aggregateDataLoading && <Loading />) ||
        (clientCoacheesLoading && <Loading />)}
      <SubHeading>Total Sessions Allotted For All Employees</SubHeading>
      <ProgressContainer>
        <Progress>
          <ProgressBar width={`${width}%`} color={"#88b3e7"} />
        </Progress>
        <Title>
          {aggregateData?.total_sessions_remaining} sessions remaining
        </Title>
      </ProgressContainer>
      <UL>
        <LI>
          Total Sessions Allotted For All Employees:{" "}
          <Span>
            {aggregateData?.total_sessions_allotted_for_all_employees}
          </Span>
        </LI>
        <LI>
          Total Sessions Completed:{" "}
          <Span>{aggregateData?.total_sessions_completed}</Span>
        </LI>
        <LI>
          Total Sessions Remaining:{" "}
          <Span>{aggregateData?.total_sessions_remaining}</Span>
        </LI>
      </UL>
      <SubHeading>My Coaches</SubHeading>

      <Container ispadding>
        {clientCoaches?.map(item => (
          <EmployeDetail
            onClick={() =>
              navigate(`/app/client/analytics/coach-details/${item?.id}`)
            }
          >
            <ProfileSection>
              <Profile>
                <div>
                  <ImageProfile
                    src={
                      item?.profile_picture_url
                        ? item?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                  />
                </div>
                <div>
                  <Name>
                    {item?.first_name} {item?.last_name}
                  </Name>
                  <SubTitle>Coach</SubTitle>
                </div>
              </Profile>
            </ProfileSection>
            <Icon>
              <Arrow />
            </Icon>
          </EmployeDetail>
        ))}
      </Container>
    </Container>
  )
}

export default AggregateDataAnalytics

const Container = styled.div`
  padding: ${({ ispadding }) => (ispadding ? "0" : "0 2.2rem")};
`
const SubHeading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
`
const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`
const Progress = styled.div`
  background-color: #f4f4f4;
  height: 26px;
  width: 65%;
  border-radius: 4px;
`
// const ProgressBar = styled.div`
//   width: 50%;
//   height: 100%;
//   background: #88b3e7;
//   border-radius: 0px 4px 4px 0px;
// `;
const Title = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;

  letter-spacing: -0.32px;

  color: #2b2b2b;
`
const UL = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.8rem;
`
const LI = styled.li`
  display: flex;
  gap: 6px;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
const EmployeDetail = styled.div`
  border: 1px solid rgba(151, 173, 182, 0.2);
  width: 975px;
  height: 70px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0rem 1.6rem;
  cursor: pointer;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ImageProfile = styled.img`
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  /* border: 1px solid green; */
`
const Name = styled.h2`
  margin: 0;
  margin-bottom: 4px;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50.23%;
  color: #2b2b2b;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
  margin: 0;
`
const Icon = styled.div``
const IconImg = styled.img``

const Span = styled.h3`
  margin: 0;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.32px;
`
