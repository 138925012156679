import React from "react";
import styled from "styled-components";

const Button = ({
  children,
  isRound,
  isBorder,
  onClick,
  disabled,
  isDeleted,
  isBackgroundLight,
  type,
}) => {
  return (
    <ButtonWrapper
      isRound={isRound}
      isBorder={isBorder}
      onClick={onClick}
      disabled={disabled}
      type={type}
      isDeleted={isDeleted}
      isBackgroundLight={isBackgroundLight}
    >
      {children}
    </ButtonWrapper>
  );
};

export default Button;

const ButtonWrapper = styled.button`
  width: ${({ isRound, isBorder }) =>
    (isRound && "100%") || (isBorder && "100%")};
  height: ${({ isRound }) => isRound && "100%"};
  color: ${({ theme }) => theme.dark};
  background-color: ${({ disabled, theme, isDeleted, isBackgroundLight }) =>
    isDeleted ? "#F47665" : isBackgroundLight ? "white" : theme.primary};
  border-radius: ${({ isRound, isBorder }) =>
    (isRound && "50%") || (isBorder && "100px")};
  border: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "none" : "pointer")};
  padding: ${({ isBorder }) => isBorder && "0.8rem"};
  opacity: ${({ disabled }) => disabled && "0.5"};
  font-size: 16px;
  font-weight: 600;
  font-family: "Myriad-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
`;
