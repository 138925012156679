import ProgressBar from "Elements/ProgressBar/ProgressBar"
import { Col, Row } from "antd"
import React, { useState } from "react"
import styled from "styled-components"
import { ReactComponent as ArrowDown } from "./arrow_down.svg"

export const ListData = ({ data }) => {
  const [showButton, setShowButton] = useState(true)

  const listCoaches = data => {
    if (showButton) {
      let a = data?.slice(0, 2)
      return a
    } else {
      return data
    }
  }
  return (
    <>
      <ProfileWrapper>
        {listCoaches(data)?.map((item, index) => {
          return (
            <ProfileSection key={item?.id}>
              <Profile>
                <div>
                  <ImageProfile
                    src={
                      item?.profile_picture_url
                        ? item?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                  />
                </div>
                <div>
                  <Name>
                    {item?.first_name} {item?.last_name}
                  </Name>
                  <SubTitle>{item?.title}</SubTitle>
                </div>
              </Profile>
            </ProfileSection>
          )
        })}
        {showButton && (
          <Button onClick={() => setShowButton(false)}>
            View all <ArrowDown />
          </Button>
        )}
      </ProfileWrapper>
    </>
  )
}
const CoacheeAverageRating = ({ data = {} }) => {
  // const { id } = useParams();

  // const [data, setData] = useState();

  // const AverageRating = async () => {
  //   try {
  //     // setGetDataloading(true);
  //     const response = await BaseService.get(
  //       `api/v1/clinets/analytics/average_rating/${id}/`
  //     );
  //     if (response?.status === 200) {
  //       setData(response?.data);
  //     }

  //     // setGetDataloading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (!id) return;
  //   AverageRating();
  // }, [id]);

  const widths = {}

  for (const key in data?.coachee_average_rating) {
    if (
      Object.hasOwnProperty.call(data?.coachee_average_rating, key) &&
      key.startsWith("rate")
    ) {
      widths[key] = `${(data?.coachee_average_rating[key] / 5) * 100}%`
    }
  }
  return (
    <Container>
      <Row gutter={48}>
        <Col span={16}>
          <SubHeading>
            Participant Engagement Score after all sessions
          </SubHeading>
          <Progress>
            <ProgressBar width={widths?.rate_1} color={"#88B3E7"} />
            <Persentage>
              {data?.coachee_average_rating?.rate_1?.toFixed(2)}
            </Persentage>
          </Progress>
          <Progress>
            <ProgressBar width={widths?.rate_2} color={"#434348"} />
            <Persentage>
              {" "}
              {data?.coachee_average_rating?.rate_2?.toFixed(2)}
            </Persentage>
          </Progress>
          <Progress>
            <ProgressBar width={widths?.rate_3} color={"#A6EB8A"} />
            <Persentage>
              {" "}
              {data?.coachee_average_rating?.rate_3?.toFixed(2)}
            </Persentage>
          </Progress>
          <Progress>
            <ProgressBar width={widths?.rate_4} color={"#E4BF44"} />
            <Persentage>
              {" "}
              {data?.coachee_average_rating?.rate_4?.toFixed(2)}
            </Persentage>
          </Progress>
          <Discription>
            <Points>
              <Box style={{ backgroundColor: "#88B3E7" }}></Box>
              <Para>
                <Span>Coach Evaluation:</Span> How engaged was the Coachee?
              </Para>
            </Points>
            <Points>
              <Box style={{ backgroundColor: "#434348" }}></Box>
              <Para>
                <Span>Coach Evaluation:</Span> How engaged was the coachee’s
                manager/supervisor?
              </Para>
            </Points>
            <Points>
              <Box style={{ backgroundColor: "#A6EB8A" }}></Box>
              <Para>
                <Span>Coach Evaluation:</Span> How easy was it to work with the
                coachee on this engagement?
              </Para>
            </Points>
            <Points>
              <Box style={{ backgroundColor: "#E4BF44" }}></Box>
              <Para>
                <Span>Coach Evaluation:</Span> To what extent are you satisfied
                with the support SGI has provided in contracting, providing
                structure, and providing any logistical assistance on this
                engagement?
              </Para>
            </Points>
          </Discription>
        </Col>

        <Col span={8}>
          <SubHeading>Coachees</SubHeading>
          <ListData data={data?.coachees} />
        </Col>
      </Row>
    </Container>
  )
}

export default CoacheeAverageRating
const Container = styled.div`
  padding: 1rem 2.2rem;
`
const SubHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
const Progress = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`
const Persentage = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
const Discription = styled.div`
  margin-top: 1rem;
`
const Points = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 95%;
`
const Box = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 4px;
`
const Para = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  gap: 0.5rem;
  width: 95%;
`
const Span = styled.span`
  font-weight: 800;
  white-space: nowrap;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`
const ProfileWrapper = styled.div`
  height: 28rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d7d7d7;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #c5c3c3;
  }
`
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const Name = styled.h2`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 23.236px */
  margin: 0;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
  margin: 0;
`
const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
  margin: 0;
`
const ArrowIcon = styled.img``
