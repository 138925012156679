import Button from "Elements/Button/Button"
import { Modal } from "antd"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { ReactComponent as VideoCall } from "./vc.svg"
import { BaseService } from "API/base"
import { getCookie, setCookie } from "Utils/StorageVariables"
import { useNavigate } from "react-router-dom"
import { GlobalStateContext } from "GlobalStateContext"
import { DateTime } from "luxon"

const VideocCallModal = ({
  isVideoOpen,
  setIsVideoOpen,
  videoRoomData,
  coacheeData,
  roomId
}) => {
  const { globalState } = useContext(GlobalStateContext)
  const [getCoachLoading, setGetCoachLoading] = useState(false)
  const [values, setValues] = useState()
  const userData = JSON.parse(getCookie("userData"))
  const navigate = useNavigate()
  const handleClose = () => {
    setIsVideoOpen(false)
  }

  const GetRoomToken = () => {
    navigate(`/video_room/connecting/${roomId}`)
  }

  const formatDate = sessionDate => {
    const formattedDate = DateTime.fromISO(sessionDate).toFormat("cccc MMMM d") // Output: "Friday January 12"

    return formattedDate
  }

  const getCoach = async () => {
    try {
      setGetCoachLoading(true)
      const response = await BaseService.get(
        `api/v1/coaches/${userData?.user_type_id}/`,
        true
      )
      setValues(response?.data)
      setCookie("localPartispant", JSON.stringify(response?.data))
      setGetCoachLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setGetCoachLoading(false)
      }, 5000) //
    }
  }
  const getClient = async () => {
    try {
      setGetCoachLoading(true)
      const response = await BaseService.get(
        `api/v1/coachees/${userData?.user_type_id}/`,
        true
      )
      setValues(response?.data)
      setCookie("localPartispant", JSON.stringify(response?.data))
      setGetCoachLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setGetCoachLoading(false)
      }, 5000) //
    }
  }
  useEffect(() => {
    if (userData?.user_type === "Coach") return
    getClient()
  }, [])
  useEffect(() => {
    if (userData?.user_type === "Coachee") return
    getCoach()
  }, [])

  setCookie("remotePartispant", JSON.stringify(coacheeData))

  return (
    <Modal
      open={isVideoOpen}
      footer={false}
      className="videocalll_modal"
      onCancel={handleClose}
      centered
    >
      <Container>
        <ModelContent>
          <VideoCall />

          {coacheeData?.call_type === "Coaching Session" && (
            <>
              <Title>{coacheeData?.call_type}</Title>
              <Description>
                {formatDate(coacheeData?.session_date)}

                {` ${coacheeData?.start_time.slice(
                  0,
                  5
                )} ${coacheeData?.start_time.slice(
                  5,
                  7
                )} – ${coacheeData?.end_time.slice(
                  0,
                  5
                )} ${coacheeData?.end_time.slice(5, 7)} EST`}
              </Description>
            </>
          )}

          <Guestsection>
            <GuestHeading>Guests</GuestHeading>

            <ProfileSection>
              <Profile>
                <div>
                  <ImageProfile
                    src={
                      values?.profile_picture_url
                        ? values?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                  />
                </div>
                <div>
                  <Name>
                    {values?.first_name} {values?.last_name}
                  </Name>
                  <SubTitle>{userData?.user_type}</SubTitle>
                </div>
              </Profile>
            </ProfileSection>

            {/* {coacheeData?.sessions?.map(item => ( */}
            <ProfileSection>
              <Profile>
                <div>
                  <ImageProfile
                    src={
                      coacheeData?.profile_picture_url
                        ? coacheeData?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                  />
                </div>
                <div>
                  <Name>
                    {coacheeData?.first_name} {coacheeData?.last_name}
                  </Name>
                  <SubTitle>
                    {userData?.user_type === "Coach" ? "Coachee" : "Coach"}
                  </SubTitle>
                </div>
              </Profile>
            </ProfileSection>
            {/* ))} */}
          </Guestsection>

          <ButtonContent>
            <Button isBorder onClick={GetRoomToken}>
              Join Call
            </Button>
            <Button isBackgroundLight isBorder onClick={handleClose}>
              Cancel
            </Button>
          </ButtonContent>
        </ModelContent>
      </Container>
    </Modal>
  )
}

export default VideocCallModal

const Container = styled.div`
  height: 631px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;

  svg {
    color: ${({ theme }) => theme.primary};
    font-size: 120px;
    margin-left: 1rem;
  }
`

const Title = styled.p`
  color: #2b2b2b;
  text-align: center;
  font-family: "Myriad-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 29.045px */
  margin: 0;
  margin-top: 0.7rem;
`

const ButtonContent = styled.div`
  width: 326px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
  gap: 1rem;
`

const Description = styled.p`
  font-family: "Myriad-Regular";
  color: #838181;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 20.332px */
  margin: 0;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
`

const Guestsection = styled.div`
  width: 296px;
`

const GuestHeading = styled.h3`
  color: #838181;
  text-align: center;
  font-family: "Myriad-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 20.332px */
  margin-bottom: 0.6rem;
`

const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.9rem;
`
const ImageProfile = styled.img`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* border: 1px solid green; */
`
const Name = styled.h3`
  margin: 0;
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 23.236px */
`
const SubTitle = styled.p`
  margin: 0;
  font-family: "Myriad-Regular";
  font-style: normal;
  color: #838181;
  font-size: 12px;
  font-weight: 400;
  line-height: 145.227%; /* 17.427px */
`
