// import SessionModal from "Components/SessionModal/SessionModal";
import Button from "Elements/Button/Button"
import React, { useEffect, useState } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { ReactComponent as LeftArrow } from "./left_arrow.svg"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import "./ScheduleSession.scss"
import SessionModal from "Components/SessionModal/SessionModal"
import { BaseService } from "API/base"
import { Error, Success } from "Responses/Response"
import Loading from "Components/Loader/Loading"
import RequestModal from "Components/RequestModal/RequestModal"
import { v4 as uuidv4 } from "uuid"
const ScheduleSession = () => {
  const localizer = momentLocalizer(moment)
  const navigate = useNavigate()
  const [isSessionmodal, setIsSessionmodal] = useState(false)
  const [slotDate, isSlotDate] = useState()
  const [getTimeslots, setTimeSlots] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [currDate, setCurrdate] = useState()
  const [getCoachData, SetCoachData] = useState()
  const [selectedSlotId, setSelectedSlotId] = useState(null)
  const [isSessionLoading, setIsSessionLoading] = useState(false)
  const [isScheduleloading, setIsScheduleloading] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [currentYear, setCurrentYear] = useState(null)
  const [currentMonth, setCurrentMonth] = useState(null)

  const sessionArray = [
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 12, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 1, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 2, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 3, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 3, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 4, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 4, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 5, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 6, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 7, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 8, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 9, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 7, 11, 10, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 7, 11, 11, 0),
    // },
  ]

  const { id } = useParams()

  const getCoach = async () => {
    try {
      const response = await BaseService.get(`api/v1/coachees/coaches/`)
      if (response?.status === 200) {
        SetCoachData(response?.data)
      }
    } catch (error) {
      Error(error?.detail)
    } finally {
    }
  }

  const getAvailableSessions = async month => {
    setIsSessionLoading(true)
    try {
      const response = await BaseService.get(
        `/api/v1/coaching_slots/${id}/calendar/?year=${currentYear}&month=${month}`
      )
      if (response?.status === 200) {
        const filterSession = response?.data
          ?.filter(item => !item?.is_reserved)
          .map(item => ({
            ...item,
            title: "Available Slots",
            date: item?.date,
            start: moment(
              `${item.date} ${item.start_time}`,
              "YYYY-MM-DD h:mmA"
            ).toDate(),
            end: moment(
              `${item.date} ${item.end_time}`,
              "YYYY-MM-DD h:mmA"
            ).toDate(),
            id: uuidv4()
          }))

        const filteredSlotsData = filterSession.filter(newSlot => {
          return !getTimeslots.some(existingSlot => {
            return (
              existingSlot.start.getTime() === newSlot.start.getTime() &&
              existingSlot.end.getTime() === newSlot.end.getTime()
            )
          })
        })
        setTimeSlots(prevSlotsData => [...prevSlotsData, ...filteredSlotsData])

        // setTimeSlots(filterSession)
      }
    } catch (error) {
      if (error?.response?.status == 503) {
        Error("Calendly Timeout, please try later.")
      }
      // Error(error?.response?.data?.detail)
    } finally {
      setIsSessionLoading(false)
    }
  }

  const scheduleChemistryCall = async data => {
    try {
      setIsScheduleloading(true)
      const response = await BaseService.post(
        `api/v1/coachees/session_or_chemistry_call/${
          getCoachData?.find(item => item?.coach_id == id)?.engagement_info_id
        }/`,
        data
      )

      if (response?.status === 201) {
        const modalOpen = getCoachData?.find(
          item => item?.coach_id == id
        )?.is_assigned
        if (modalOpen) {
          setisOpen(true)
        } else {
          setIsSessionmodal(true)
        }
        Success(response?.data?.detail)
      }
    } catch (err) {
      Error(err?.response?.data?.detail)
    } finally {
      setIsScheduleloading(false)
    }
  }

  useEffect(() => {
    getCoach()
  }, [])

  useEffect(() => {
    isSlotDate(currDate)
  }, [currDate])

  useEffect(() => {
    const dateObj = new Date()
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setCurrdate(formattedDate)
  }, [slotDate])

  const getMonthNumber = dateString => {
    const date = new Date(dateString)
    return date.getMonth() + 1 // getMonth() returns a zero-based month index, so we add 1 to get the actual month number
  }

  const handleMonthNumber = monthName => {
    return new Date(Date.parse(monthName + " 1, 2000")).getMonth() + 1
  }

  useEffect(() => {
    if (!currentMonth) return
    // console.log({ currentMonth })
    const changeMonthNumber = handleMonthNumber(currentMonth)
    if (!id || !currentMonth) return
    const isDataAvailableForCurrentMonth = getTimeslots?.some(
      slot => getMonthNumber(slot?.date) === handleMonthNumber(currentMonth)
    )
    if (isDataAvailableForCurrentMonth) return
    getAvailableSessions(changeMonthNumber)
  }, [id, currentMonth])

  const Toolbar = ({
    label,
    onView,
    view,
    onNavigate,
    localizer,
    setCurrentMonth,
    setCurrentYear
  }) => {
    const [monthName, year] = label.split(" ")

    function checkMonthAfterHyphen(dateRange) {
      // Split the string on '–' to separate the two dates
      const parts = dateRange.split("–").map(s => s.trim())

      // Check if we have two parts
      if (parts.length !== 2) return false

      // Extract the second part
      const secondPart = parts[1]

      // Create a regex to find the month name
      const monthRegex =
        /^(January|February|March|April|May|June|July|August|September|October|November|December)/

      // Test second part for month name
      const match = monthRegex.exec(secondPart)

      // If a match is found, return the month name; otherwise, return false
      return match ? match[0] : false
    }

    useEffect(() => {
      if (view === "month") {
        setCurrentMonth(monthName)
        setCurrentYear(year)
      } else if (view === "week") {
        setCurrentMonth(checkMonthAfterHyphen(label))
        setCurrentYear(new Date().getFullYear())
      }
    }, [label, view])

    return (
      <MainToolbarContainer className="my-toolbar">
        <Header>
          <ButtonContent>
            <Button1 onClick={() => onNavigate("PREV")}>
              <LeftOutlined />
            </Button1>
            <Button1 onClick={() => onNavigate("NEXT")}>
              <RightOutlined />
            </Button1>
            <Span>{label}</Span>
          </ButtonContent>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "58vw"
            }}
          >
            <Buttoncontent2>
              <Button2
                active={view === "week"}
                onClick={() => {
                  onView("week")
                  // setViewdot("week");
                }}
              >
                Week
              </Button2>
              <Button2
                active={view === "month"}
                onClick={() => {
                  onView("month")
                  // setViewdot("month");
                }}
              >
                Month
              </Button2>
            </Buttoncontent2>
          </div>
        </Header>
      </MainToolbarContainer>
    )
  }

  const CustomTimeColumn = props => {
    const handleChemistryCall = timeSlots => {
      const item = timeSlots?.find(item => item?.id === selectedSlotId)
      if (item) {
        scheduleChemistryCall({
          session_date: item?.date,
          start_time: item?.start_time,
          end_time: item?.end_time,
          call_type: getCoachData?.find(item => item?.coach_id == id)
            ?.is_assigned
            ? "Coaching Session"
            : "Chemistry Call",
          utc_offset: item?.utc_offset
        })
      }
    }

    const handleRowClick = data => {
      setSelectedSlotId(prevSelectedSlotId =>
        prevSelectedSlotId === data.id ? null : data.id
      )
      console.log(selectedSlotId)
    }

    return (
      <>
        {/* currDate removed */}
        {selectedDate ? (
          <CustomTimeColumnContainer className="custom-time-column">
            {getTimeslots?.filter(slot => slot?.date === slotDate).length ? (
              getTimeslots
                ?.filter(slot => slot?.date === slotDate)
                .map(item => (
                  <CustomRow
                    key={item?.id}
                    isSelected={item?.id === selectedSlotId}
                    onClick={() => handleRowClick(item)}
                  >
                    <ScheduleTime isSelected={item?.id === selectedSlotId}>
                      {item?.start_time?.slice(0, 5)}{" "}
                      {item?.start_time?.slice(5, 7)} -{" "}
                      {item?.end_time?.slice(0, 5)}{" "}
                      {item?.end_time?.slice(5, 7)}
                    </ScheduleTime>
                  </CustomRow>
                ))
            ) : (
              <Nodata>No Slots Available</Nodata>
            )}

            {selectedSlotId &&
              getTimeslots?.filter(slot => slot?.date === slotDate).length >
                0 && (
                <SelectedTime>
                  {getTimeslots
                    ?.find(item => item?.id === selectedSlotId)
                    .start_time?.slice(0, 5)}{" "}
                  {getTimeslots
                    ?.find(item => item?.id === selectedSlotId)
                    .start_time?.slice(5, 7)}{" "}
                  -{" "}
                  {getTimeslots
                    ?.find(item => item?.id === selectedSlotId)
                    .end_time.slice(0, 5)}{" "}
                  {getTimeslots
                    ?.find(item => item?.id === selectedSlotId)
                    .end_time.slice(5, 7)}{" "}
                  EST Time Zone
                </SelectedTime>
              )}

            <ScheduleTimeBtnWrapper>
              <Button
                onClick={() => handleChemistryCall(getTimeslots)}
                isBorder
                disabled={isScheduleloading}
              >
                {getCoachData?.find(item => item?.coach_id == id)?.is_assigned
                  ? "Book me"
                  : "Schedule Chemistry Call"}
              </Button>
            </ScheduleTimeBtnWrapper>
          </CustomTimeColumnContainer>
        ) : null}
      </>
    )
  }

  const CustomHeader = ({ date }) => {
    const handleHeaderClick = () => {
      const dateString = date
      const dateObj = new Date(dateString)
      const year = dateObj.getFullYear()
      const month = String(dateObj.getMonth() + 1).padStart(2, "0")
      const day = String(dateObj.getDate()).padStart(2, "0")
      const formattedDate = `${year}-${month}-${day}`
      isSlotDate(formattedDate)
      if (selectedDate && selectedDate.getTime() === date.getTime()) {
        setSelectedDate(currDate)
      } else {
        setSelectedDate(date)
        setSelectedSlotId(null)
      }

      // setShowTimeColumn(true)
    }

    const headerStyle = {
      backgroundColor:
        selectedDate && selectedDate.getTime() === date.getTime()
          ? "rgb(255 230 149)"
          : "white"
    }

    const day = moment(date).format("ddd")
    const dateNumber = moment(date).format("DD")
    const month = moment(date).format("MM") //

    return (
      <>
        <HeaderContainer onClick={handleHeaderClick}>
          <HeaderContent className="rbc-header-content">
            <HeaderDay className="rbc-header-day">{day}</HeaderDay>
            <HeaderDate className="rbc-header-date" style={headerStyle}>
              {dateNumber}
              <span
                style={{
                  position: "absolute",
                  top: "8px",
                  left: "11px",
                  fontSize: "2.3rem"
                }}
              >
                {getTimeslots?.find(
                  slot =>
                    moment(slot?.date).format("DD-MM") ==
                    `${dateNumber}-${month}`
                )
                  ? "."
                  : null}
              </span>
            </HeaderDate>
          </HeaderContent>
        </HeaderContainer>
      </>
    )
  }

  const CustomEvent = ({ event }) => {
    const { start, end, title } = event

    return (
      <>
        {" "}
        <AvailableSlotdiv>
          <EventTitle>{title}</EventTitle>
          <EventDate>
            {moment(start).format("h:mm A")} - {moment(end).format("h:mm A")}
          </EventDate>
        </AvailableSlotdiv>
      </>
    )
  }

  return (
    <Container>
      {isSessionLoading && <Loading />}
      <Content>
        <HeadingContent>
          <LeftArrow onClick={() => navigate(-1)} />
          <Heading>Schedule Session</Heading>
        </HeadingContent>
      </Content>

      <Calendar
        views={["month", "week"]}
        localizer={localizer}
        startAccessor="start"
        events={getTimeslots}
        endAccessor="end"
        style={{}}
        className="my-calendar-setting"
        components={{
          toolbar: props => (
            <Toolbar
              setCurrentMonth={setCurrentMonth}
              setCurrentYear={setCurrentYear}
              {...props}
            />
          ),
          event: CustomEvent,
          timeGutterWrapper: CustomTimeColumn,
          week: {
            header: props => <CustomHeader {...props} />
          }
        }}
        min={new Date(0, 0, 0, 0, 0, 0)}
        max={new Date(0, 0, 0, 23, 1, 0)}
        defaultView="week"
      />

      <SessionModal
        isSessionmodal={isSessionmodal}
        setisSessionmodal={setIsSessionmodal}
        getCoachData={getCoachData}
        filterSession={getTimeslots?.find(item => item?.id === selectedSlotId)}
      />
      <RequestModal
        isOpen={isOpen}
        setisOpen={setisOpen}
        description={"Coaching Session was successfully scheduled!"}
      />
    </Container>
  )
}

export default ScheduleSession

const Container = styled.div`
  height: 100%;
  /* border: 1px solid red; */
  background-color: #fff;
`
const Heading = styled.h3`
  margin: 0;
  margin-left: 1.7rem;
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 10vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`
const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.2rem;

  svg {
    margin-bottom: 0.3rem;
  }
`
const BtnContent = styled.div`
  width: 123px;
  margin-left: 1.2rem;
`

const MainToolbarContainer = styled.div`
  background-color: #fff;
  height: 60px;
`
const Header = styled.div`
  /* border: 1px solid yellow; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ButtonContent = styled.div`
  /* border: 1px solid red; */
  /* width: 31.7%; */
  width: 42vw;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 2.2rem;
`
const Span = styled.span`
  margin: 0;
  font-family: "Myriad-regular";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`
const Button1 = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 9px;
`

const Buttoncontent2 = styled.div`
  padding: 2px;
  width: 343px;
  height: 36px;
  display: flex;
  background: ${({ theme }) => theme.border2};
  border-radius: 8px;
`

const Button2 = styled.button`
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.primary : theme.border2};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.textDark};
`

const HeaderContent = styled.div`
  min-height: 65px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`
const HeaderDay = styled.div`
  font-family: "Myriad-Light";
  font-weight: 600;
  font-size: 14px;
  line-height: 124.5%;
  text-align: center;
  color: #45485b;
`
const HeaderDate = styled.div`
  position: relative;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 124.5%;
  color: #45485b;
  /* color: red; */
  margin-top: 3px;
  padding: 2px 4px;
  border-radius: 50%;
`

const EventTitle = styled.div`
  color: #2b2b2b;
  margin: 0 4px;

  /* background-color: ${({ res }) => (res ? "red" : "yellow")}; */
`

const AvailableSlotdiv = styled.div`
  background-color: transparent;
  font-size: 10px;
  font-weight: 600;
  display: flex;
`

const EventDate = styled.p`
  margin: 0;
  color: #2b2b2b;
`
const FootarButtoncontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.15);
  height: 133px;
  padding: 1rem 51px;
  flex-shrink: 0;
`
const FootarButton = styled.div`
  width: 25%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`
const FooterContent = styled.p`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px; /* 122.5% */
  letter-spacing: -0.32px;
`
const CustomTimeColumnContainer = styled.div`
  width: 90%;
  margin: 3.2rem auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
`
const CustomRow = styled.div`
  height: 48px;
  /* width: 962px; */
  width: 100%;
  background-color: ${({ isSelected }) => (isSelected ? "#E4BF44" : "#fff")};
  border-radius: 8px;
  border: 1px solid rgba(151, 173, 182, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.7rem;
  cursor: pointer;
`
const ScheduleTime = styled.p`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 23.236px */
  font-weight: ${({ isSelected }) => (isSelected ? "600" : "400")};
`
const SelectedTime = styled.h3`
  margin-bottom: 0;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 23.236px */
`

const ScheduleTimeBtnWrapper = styled.div`
  width: 418px;
  margin-top: 0.8rem;
`

const HeaderContainer = styled.div``

const Nodata = styled.h1`
  color: #2b2b2b;
  text-align: center;
  font-family: "'Myriad-SemiBold";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`
