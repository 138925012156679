import React, { useContext, useState } from "react"
import styled from "styled-components"
import { TbLogout } from "react-icons/tb"
import Button from "Elements/Button/Button"
import { Modal } from "antd"
import { removeAllCookie } from "Utils/StorageVariables"
import { BaseService } from "API/base"
import { useNavigate } from "react-router-dom"
import { GlobalStateContext } from "GlobalStateContext"

const LogoutModal = ({ isLogout, setisLogout }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)

  const handlelogout = async () => {
    setIsLoading(true)
    try {
      const response = await BaseService.post(
        "rest-auth/logout/",

        false
      )
      if (response?.status === 200) {
        removeAllCookie()
        navigate("/")
        setisLogout(false)
        updateGlobalState("profile_details", null)
      }
    } catch (error) {
      console.error({ error })
      Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onClose = () => {
    setisLogout(false)
  }
  return (
    <>
      <Modal
        footer={false}
        className="logout_modal"
        open={isLogout}
        onCancel={onClose}
      >
        <Container>
          <ModelContent>
            <TbLogout />
            <Description>Are you sure you want to Logout?</Description>
            <ButtonContent>
              <Button isBorder onClick={handlelogout} disabled={isLoading}>
                Yes
              </Button>
              <Button isBorder isBackgroundLight onClick={() => onClose()}>
                Cancel
              </Button>
            </ButtonContent>
          </ModelContent>
        </Container>
      </Modal>
    </>
  )
}

export default LogoutModal

const Container = styled.div`
  height: 440px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.primary};
    font-size: 120px;
    margin-left: 1rem;
  }
`

const Description = styled.p`
  font-style: normal;
  width: 250px;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 2rem;
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 295px;
  gap: 1rem;
`
