import React from "react";
import styled from "styled-components";
import { ReactComponent as NoDataFoundIcon } from "./NoDataIcon.svg";

const NoDataFound = ({ height }) => {
  return (
    <Container height={height}>
      <Circle>
        <NoDataFoundIcon />
      </Circle>
      <Heading>No Data Found</Heading>
    </Container>
  );
};

export default NoDataFound;
const Container = styled.div`
  height: ${({ height }) => (height ? height : "85vh")};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;
const Circle = styled.div`
  width: 250px;
  height: 250px;
  background-color: #eff0f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 50%;
    transform: translateX(10px);
  }
`;
const Heading = styled.h1`
  color: #bfbcbc;
`;
