import Button from "Elements/Button/Button";
import { Rate, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { BaseService } from "API/base";
import { Error, Success } from "Responses/Response";
import { ReactComponent as LeftArrow } from "./left_arrow.svg";
import RequestModal from "Components/RequestModal/RequestModal";

const CoachSessionFeedback = () => {
  const [getCoachdata, setGetCoachData] = useState();
  const [isOpen, setisOpen] = useState(false);
  const [isCoacheeReport, setIsCoacheeReport] = useState(false);
  const [getCoachReport, setGetCoachReport] = useState([]);
  const { id, engagmentinfoId } = useParams();

  const getCoach = async () => {
    try {
      // setCoacheesDetailLoading(true);
      const response = await BaseService.get(`api/v1/coaches/${id}/`, true);

      setGetCoachData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const CoachFinalReport = async (data, i) => {
    try {
      setIsCoacheeReport(true);
      const response = await BaseService.post(
        `api/v1/coachee/final_report_review/${engagmentinfoId}/`,
        data
      );
      if (response?.status === 200) {
        Success(response?.data?.detail);
        setIsCoacheeReport(false);
        setisOpen(true);
        // navigate(-1);
      }
    } catch (error) {
      Error(error?.response?.data?.detail);
    } finally {
      setIsCoacheeReport(false);
    }
  };

  const getCoachFinalReport = async () => {
    try {
      // setCoacheesDetailLoading(true);
      const response = await BaseService.get(
        `api/v1/coachee/final_report_review/${engagmentinfoId}/`
      );

      setGetCoachReport(response?.data);
    } catch (error) {
      Error(error?.response?.data?.detail);
    }
  };

  useEffect(() => {
    getCoachFinalReport();
  }, []);

  const [ratingValue, setRatingValue] = useState({
    rate_1: 0,
    rate_2: 0,
    rate_3: 0,
    rate_4: 0,
  });

  const handleRatingChange = (ratingKey, value) => {
    setRatingValue((prevValues) => ({
      ...prevValues,
      [ratingKey]: value,
    }));
  };

  const handleCommentChange = (event) => {
    setRatingValue({
      ...ratingValue,
      comment: event.target.value,
    });
  };

  const handleSubmit = () => {
    console.log(ratingValue);
    CoachFinalReport(ratingValue);
  };

  useEffect(() => {
    if (
      getCoachReport.rate_1 ||
      getCoachReport.rate_2 ||
      getCoachReport.rate_3 ||
      getCoachReport.rate_4 ||
      getCoachReport?.comment
    ) {
      setRatingValue((prevValues) => ({
        ...prevValues,
        rate_1: getCoachReport.rate_1,
        rate_2: getCoachReport.rate_2,
        rate_3: getCoachReport.rate_3,
        rate_4: getCoachReport.rate_4,
        comment: getCoachReport.comment,
      }));
    }
  }, [getCoachReport]);

  useEffect(() => {
    getCoach();
  }, []);

  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <SubHeading>
          <LeftArrow
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
          Review Coach
        </SubHeading>
      </Header>
      <ReviewContent>
        <Content>
          <ProfileSection>
            <Profile>
              <div>
                <ImageProfile
                  src={
                    getCoachdata?.profile_picture_url
                      ? getCoachdata?.profile_picture_url
                      : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                  }
                  alt="profile"
                />
              </div>
              <div>
                <Name>{`${getCoachdata?.first_name} ${getCoachdata?.last_name}`}</Name>
                <SubTitle>Coach</SubTitle>
              </div>
            </Profile>
          </ProfileSection>

          <Para>
            What was your overall level of satisfaction with your executive
            coaching experience? *
          </Para>
          <ListWrapper>
            <InputWrapper>
              <Rate
                defaultValue={getCoachReport.rate_1 || ratingValue.rate_1}
                onChange={(value) =>
                  !getCoachReport.rate_1 && handleRatingChange("rate_1", value)
                }
                value={ratingValue.rate_1}
                // disabled={getCoachReport.rate_1}
                character={({ index }) => (
                  <Box
                    isSelected={
                      index + 1 ===
                      (getCoachReport.rate_1 || ratingValue.rate_1)
                    }
                    onClick={() =>
                      !getCoachReport.rate_1 && handleRatingChange(index + 1)
                    }
                    disabled={!!getCoachReport.rate_1}
                  >
                    {index + 1}
                  </Box>
                )}
              />
            </InputWrapper>
          </ListWrapper>

          <Para>
            To what degree do you believe you have gained new perspective,
            knowledge, or skills as a result of the coaching you received? *?
          </Para>
          <ListWrapper>
            <InputWrapper>
              <Rate
                defaultValue={getCoachReport.rate_2 || ratingValue.rate_2}
                onChange={(value) =>
                  !getCoachReport.rate_2 && handleRatingChange("rate_2", value)
                }
                value={ratingValue.rate_2}
                // disabled={!!getCoachReport.rate_2}
                character={({ index }) => (
                  <Box
                    isSelected={
                      index + 1 ===
                      (getCoachReport.rate_2 || ratingValue.rate_2)
                    }
                    onClick={() =>
                      !getCoachReport.rate_2 && handleRatingChange(index + 1)
                    }
                    disabled={!!getCoachReport.rate_2}
                  >
                    {index + 1}
                  </Box>
                )}
              />
            </InputWrapper>
          </ListWrapper>

          <Para>
            To what degree do you believe you have experienced a shift in your
            professional behavior or performance as a result of the coaching you
            received? *
          </Para>
          <ListWrapper>
            <InputWrapper>
              <Rate
                defaultValue={getCoachReport.rate_3 || ratingValue.rate_3}
                onChange={(value) =>
                  !getCoachReport.rate_3 && handleRatingChange("rate_3", value)
                }
                value={ratingValue.rate_3}
                // disabled={!!getCoachReport.rate_3}
                character={({ index }) => (
                  <Box
                    isSelected={
                      index + 1 ===
                      (getCoachReport.rate_3 || ratingValue.rate_3)
                    }
                    onClick={() =>
                      !getCoachReport.rate_3 && handleRatingChange(index + 1)
                    }
                    disabled={!!getCoachReport.rate_3}
                  >
                    {index + 1}
                  </Box>
                )}
              />
            </InputWrapper>
          </ListWrapper>

          <Para>
            How would you rate your coach's competency and skills as an
            executive coach? *
          </Para>
          <ListWrapper>
            <InputWrapper>
              <Rate
                defaultValue={getCoachReport.rate_4 || ratingValue.rate_4}
                onChange={(value) =>
                  !getCoachReport.rate_4 && handleRatingChange("rate_4", value)
                }
                value={ratingValue.rate_4}
                // disabled={!!getCoachReport.rate_4}
                character={({ index }) => (
                  <Box
                    isSelected={
                      index + 1 ===
                      (getCoachReport.rate_4 || ratingValue.rate_4)
                    }
                    onClick={() =>
                      !getCoachReport.rate_4 && handleRatingChange(index + 1)
                    }
                    disabled={!!getCoachReport.rate_4}
                  >
                    {index + 1}
                  </Box>
                )}
              />
            </InputWrapper>
          </ListWrapper>
          <Para>Your impressions about coaching session?</Para>
          <TextArea
            placeholder="Your comment"
            value={getCoachReport.comment || ratingValue.comment}
            onChange={handleCommentChange}
            disabled={!!getCoachReport?.comment}
          ></TextArea>
          <SubmitButtonWrapper>
            <Button
              isBorder
              onClick={handleSubmit}
              disabled={!getCoachReport?.rate_1 < 1 || isCoacheeReport}
            >
              {isCoacheeReport
                ? "Loading..."
                : !getCoachReport?.rate_1 < 1
                ? "Submitted"
                : "Submit"}
            </Button>
          </SubmitButtonWrapper>

          <RequestModal
            isOpen={isOpen}
            setisOpen={setisOpen}
            // onClick={HandleOpen}
            description={"Review was successfully sent!"}
            // isButtonDisabled={
            //   getCoachReport.rate !== 0 || getCoachReport.comment.trim() !== ""
            // }
          />
        </Content>
      </ReviewContent>
    </Container>
  );
};

export default CoachSessionFeedback;

const Container = styled.div`
  height: calc(100vh - 2rem);
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  overflow-y: scroll;
  padding-bottom: 2rem;
`;

const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
`;
const SubHeading = styled.div`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-left: 1.5rem;
`;
const ReviewContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
`;
const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 24px;
  padding: 2.3rem 0rem;
  width: 603px;
`;
const ProfileSection = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  padding: 0.65rem 0rem;
  margin: 0 auto;
  width: 531px;
  border-radius: 24px;
`;
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 0.7rem;
`;
const Profile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;
const Name = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 10.23%;
  color: #2b2b2b;
`;
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 40.23%;
  color: #838181;
`;
const Para = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-top: 2.2rem;
  margin-left: 2.6rem;
  width: 80%;
`;
const ListWrapper = styled.div``;
const InputWrapper = styled.div`
  padding-left: 2.45rem;
  margin-bottom: 2rem;
`;

const TextArea = styled.textarea`
  width: 505px;
  height: 105px;
  margin-left: 2.5rem;
  padding-top: 1rem;
  padding-left: 1rem;
  /* padding: 1rem; */
  border: 1px solid ${({ theme }) => theme.border2};
  outline: none;
  border-radius: 8px;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textMedium};
`;
const SubmitButtonWrapper = styled.div`
  width: 89%;
  margin: 1.3rem 2rem 0.5rem 2rem;
`;
const Box = styled.button`
  width: 89.96px;
  height: 48px;
  background-color: ${({ isSelected }) =>
    isSelected ? "#E5BE44" : "transparent"};
  border: none;
  outline: none;
  border: 1px solid ${({ theme }) => theme.border2};
  border-radius: 8px;
  font-size: 16px;
  font-family: "Myriad-Regular";
  font-weight: 600;
  margin-right: 0.7rem;
  cursor: pointer;
  &:focus {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const Span = styled.p`
  color: red;
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;
