import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ReactComponent as Arrow } from "./Arrow.svg"

const MyEmployees = () => {
  const navigate = useNavigate()
  const [listItems, setListItems] = useState()
  const [detailLoading, setdetailLoading] = useState(false)

  const getDetails = async () => {
    try {
      setdetailLoading(true)
      const response = await BaseService.get(`api/v1/coachees/`)
      if (response?.status === 200) {
        setListItems(response?.data)
      }

      setdetailLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <>
      {detailLoading && <Loading />}
      <Container
      // onClick={() => navigate(`/app/client/myemploy-details/${item?.id}`)}
      >
        {listItems?.map(item => (
          <EmployeDetail
            onClick={() =>
              navigate(`/app/client/analytics/myemploy-details/${item?.id}`)
            }
          >
            <ProfileSection>
              <Profile>
                <div>
                  <ImageProfile
                    src={
                      item?.profile_picture_url
                        ? item?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                  />
                </div>
                <div>
                  <Name>
                    {item?.first_name} {item?.last_name}
                  </Name>
                  <Title>{item?.title}</Title>
                </div>
              </Profile>
            </ProfileSection>
            <Icon>
              <Arrow />
            </Icon>
          </EmployeDetail>
        ))}
      </Container>
    </>
  )
}

export default MyEmployees

const Container = styled.div`
  padding: 1rem 2.2rem;
`
const EmployeDetail = styled.div`
  border: 1px solid rgba(151, 173, 182, 0.2);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0rem 0.7rem;
  cursor: pointer;
  margin-bottom: 1rem;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ImageProfile = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const Name = styled.h3`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%;
  margin: 0;
`
const Title = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
  margin: 0;
`
const Icon = styled.div``
const IconImg = styled.img``
