import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Home = () => {
  const navigate = useNavigate();

  const [activeButton, setActiveButton] = useState("Coaches");

  const handleButtonClick = (buttonName) => {
    if (buttonName === "Calendar") {
      setActiveButton("Calendar");
      navigate("Calender");
    } else {
      setActiveButton("Coaches");
      navigate("");
    }
  };

  return (
    <Container>
      <Content>
        <Heading>Home</Heading>

        <TabButtonContainer>
          <Buttoncontent>
            <TabButton
              active={activeButton === "Coaches"}
              onClick={() => handleButtonClick("Coaches")}
            >
              Coaches
            </TabButton>
            <TabButton
              active={activeButton === "Calendar"}
              onClick={() => handleButtonClick("Calendar")}
            >
              Calendar
            </TabButton>
          </Buttoncontent>
        </TabButtonContainer>
      </Content>

      <Section>
        {/* {activeButton === "Coaches" && (
         
        )} */}
        <Outlet />
      </Section>
    </Container>
  );
};

export default Home;

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
`;

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 2rem;
  width: 32%;
  /* border: 1px solid green; */
`;

const TabButtonContainer = styled.div`
  /* border: 1px solid green; */
  width: 68%;
`;

const Buttoncontent = styled.div`
  padding: 2px;
  width: 343px;
  height: 32px;
  display: flex;
  background: ${({ theme }) => theme.border2};
  border-radius: 7px;
`;

const TabButton = styled.button`
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.primary : theme.border2};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.textDark};
`;

const Section = styled.div`
  /* border: 1px solid red; */
  height: 85vh;
  width: 100%;
`;
