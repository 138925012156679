import React from "react";
import styled, { keyframes } from "styled-components";

const ProgressBar = ({ width, color }) => {
  const progressAnimation = keyframes`
    from {
      width: 0;
    }
    to {
      width: ${width};
    }
  `;

  const ContainerOne = styled.div`
    width: 100%;
    height: 26px;
    background-color: #f4f4f4;
  `;

  const Bar = styled.div`
    width: ${width};
    height: 100%;
    background-color: ${color};
    animation: ${progressAnimation} 1s linear forwards;
  `;

  return (
    <ContainerOne>
      <Bar></Bar>
    </ContainerOne>
  );
};

export default ProgressBar;
