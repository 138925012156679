import ProgressBar from "Elements/ProgressBar/ProgressBar"
import { Col, Row } from "antd"
import React from "react"
import styled from "styled-components"

const CoachReview = ({ data }) => {
  const width = `${(data?.rate / 5) * 100}%`

  return (
    <Container>
      {data?.rate ? (
        <>
          <SubHeading>Participant Engagement Score</SubHeading>
          <Progress>
            <ProgressBar width={width} color={"#88B3E7"} />
            <Persentage>{data?.rate?.toFixed(2)}</Persentage>
          </Progress>
          <Discription>
            <Points>
              <Box style={{ backgroundColor: "#88B3E7" }}></Box>
              <Para>
                <Span>Coach Evaluation: </Span>On a scale of 1 to 5, please rate
                how the session went.
              </Para>
            </Points>
          </Discription>
          <FinalReports>
            <ReportHeading>
              Coach impressions about coaching session
            </ReportHeading>
            <Para>{data?.comment}</Para>
          </FinalReports>
        </>
      ) : (
        ""
      )}
    </Container>
  )
}

export default CoachReview
const Container = styled.div`
  padding: 0 2.2rem;
  height: fit-content;
`
const SubHeading = styled.p`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 105% */
  letter-spacing: -0.32px;
  margin: 0.8rem 0;
`
const Progress = styled.div`
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`
const Persentage = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
const Discription = styled.div``
const Points = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.9rem;
  width: 95%;
  padding: 0.5rem 0rem;
`
const Box = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 4px;
`
const Para = styled.p`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
  gap: 0.4rem;
`
const Span = styled.span`
  font-weight: 800;
  white-space: nowrap;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const Name = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50.23%;
  color: #2b2b2b;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
`
const FinalReports = styled.div``
const ReportHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
