import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Button from "Elements/Button/Button"
import { Modal } from "antd"

const SelectCoachModal = ({
  onclick,
  isSelectCoach,
  setisSelectCoach,
  getCoach,
  //   isloading,
  Username,
  img
}) => {
  //   const navigate = useNavigate()

  const onClose = () => {
    setisSelectCoach(false)
    // getCoach();
  }

  return (
    <>
      <Modal
        footer={false}
        className="select_coach_modal"
        open={isSelectCoach}
        onCancel={onClose}
        centered
      >
        <Container>
          <ModelContent>
            <ProfileImage
              src={
                img
                  ? img
                  : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
              }
              alt=""
            />
            <Description>
              Are you sure you want to select {Username} as your Coach?
            </Description>
            <ButtonContent>
              <Button isBorder onClick={onclick}>
                Yes
              </Button>
              <Button
                isBorder
                isBackgroundLight
                onClick={() => onClose()}
                // disabled={isloading}
              >
                Cancel
              </Button>
            </ButtonContent>
          </ModelContent>
        </Container>
      </Modal>
    </>
  )
}

export default SelectCoachModal

const Container = styled.div`
  height: 440px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Description = styled.p`
  color: ${({ theme }) => theme.textDark};
  width: 250px;
  text-align: center;
  font-family: "Myriad-regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px; /* 106.25% */
  letter-spacing: -0.32px;

  margin-bottom: 2rem;
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 295px;
  gap: 1rem;
`

const ProfileImage = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 50%;
`
