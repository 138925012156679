import ProgressBar from "Elements/ProgressBar/ProgressBar"
import { Col, Row } from "antd"
import React from "react"
import styled from "styled-components"

const CoacheeReview = ({ data }) => {
  const widths = {}

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key) && key.startsWith("rate")) {
      widths[key] = `${(data[key] / 5) * 100}%`
    }
  }
  return (
    <Container>
      {data?.comment ? (
        <>
          <SubHeading>
            Participant Engagement Score after all sessions
          </SubHeading>
          <Progress>
            <ProgressBar width={widths?.rate_1} color={"#88B3E7"} />
            <Persentage>{data?.rate_1?.toFixed(2)}</Persentage>
          </Progress>
          <Progress>
            <ProgressBar width={widths?.rate_2} color={"#434348"} />
            <Persentage>{data?.rate_2?.toFixed(2)}</Persentage>
          </Progress>
          <Progress>
            <ProgressBar width={widths?.rate_3} color={"#A6EB8A"} />
            <Persentage>{data?.rate_3?.toFixed(2)}</Persentage>
          </Progress>
          <Progress>
            <ProgressBar width={widths?.rate_4} color={"#E4BF44"} />
            <Persentage>{data?.rate_4?.toFixed(2)}</Persentage>
          </Progress>
          <Discription>
            <Points>
              <Box style={{ backgroundColor: "#88B3E7" }}></Box>
              <Para>
                <Span>Coach Evaluation :</Span>
                What was your overall level of satisfaction with your executive
                coaching experience?
              </Para>
            </Points>
            <Points>
              <Box style={{ backgroundColor: "#434348" }}></Box>

              <Para>
                {" "}
                <Span>Coach Evaluation :</Span>To what degree do you believe you
                have gained new perspective, knowledge, or skills as a result of
                the coaching you received?
              </Para>
            </Points>
            <Points>
              <Box style={{ backgroundColor: "#A6EB8A" }}></Box>
              <Para>
                <Span>Coach Evaluation :</Span>o what degree do you believe you
                have experienced a shift in your professional behavior or
                performance as a result of the coaching you received?
              </Para>
            </Points>
            <Points>
              <Box style={{ backgroundColor: "#E4BF44" }}></Box>
              <Para>
                <Span>Coach Evaluation :</Span> How would you rate your coach's
                competency and skills as an executive coach?
              </Para>
            </Points>
          </Discription>
          <FinalReports>
            <ReportHeading>
              Coachee impressions about coaching session
            </ReportHeading>
            <Para>{data?.comment}</Para>
          </FinalReports>
        </>
      ) : (
        ""
      )}
    </Container>
  )
}

export default CoacheeReview
const Container = styled.div`
  padding: 0 2.2rem;
  height: fit-content;
`
const SubHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  margin: 0.8rem 0;
`
const Progress = styled.div`
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`
const Persentage = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
const Discription = styled.div``
const Points = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 95%;
`
const Box = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 4px;
`
const Para = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  gap: 0.5rem;
`
const Span = styled.span`
  font-weight: 800;
  white-space: nowrap;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
`
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const Name = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50.23%;
  color: #2b2b2b;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
`
const FinalReports = styled.div``
const ReportHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`
