import { BaseService } from "API/base"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import moment from "moment"
import { notificationTypes } from "Utils/Common"
import Loading from "Components/Loader/Loading"
import { GlobalStateContext } from "GlobalStateContext"
const { DateTime } = require("luxon")

const Notifications = () => {
  const [getNotificationloading, setGettNotificationLoading] = useState(false)
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)

  let isFirstDate = true

  const getNotification = async (isLoading = true) => {
    try {
      if (isLoading) {
        setGettNotificationLoading(true)
      }
      const response = await BaseService.get(
        `/api/v1/notifications/history/`,
        true
      )
      updateGlobalState("notifications", response?.data)

      setGettNotificationLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getNotification()
  }, [])

  // console.log(notificationData, "notificationdataarray")

  const Timedifferencehandle = givenDate => {
    // Convert current date and the given date to Luxon DateTime objects
    const currentDate = DateTime.local()
    const pastDate = DateTime.fromISO(givenDate)

    // Set the time zone to Eastern Standard Time (EST)
    const estCurrentDate = currentDate.setZone("Etc/GMT+5")
    const estPastDate = pastDate.setZone("Etc/GMT+5")

    const timeDifferenceInMilliseconds = estCurrentDate - estPastDate

    const timeDifferenceInMinutes = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60)
    )
    const timeDifferenceInHours = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60 * 60)
    )

    const timeDifferenceInDays = Math.floor(
      timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
    )

    let formattedTimeDifference

    if (timeDifferenceInDays > 0) {
      formattedTimeDifference = `${timeDifferenceInDays} day${
        timeDifferenceInDays > 1 ? "s" : ""
      } ${timeDifferenceInHours % 24} hour${
        timeDifferenceInHours % 24 > 1 ? "s" : ""
      }`
    } else if (timeDifferenceInHours >= 1) {
      formattedTimeDifference = `${timeDifferenceInHours} hour${
        timeDifferenceInHours > 1 ? "s" : ""
      }`
    } else {
      formattedTimeDifference = `${timeDifferenceInMinutes} minute${
        timeDifferenceInMinutes !== 1 ? "s" : ""
      }`
    }
    return formattedTimeDifference
  }

  const handleDay = date => {
    const currentDate = new Date()

    const formattedcurrentdate = moment(currentDate).format("MM-DD-YYYY")

    const pastDate = new Date(date)

    const formattedpastdate = moment(pastDate).format("MM-DD-YYYY")

    const formattedDate = moment(pastDate, "YYYY-MM-DD hh:mm:ss A").format(
      "DD MMM"
    )

    if (formattedcurrentdate === formattedpastdate) {
      return "Today"
    } else {
      return formattedDate
    }
  }

  function sortNotificationsByDate(notificationData) {
    return notificationData?.sort((a, b) => {
      const dateA = new Date(a?.date_time)
      const dateB = new Date(b?.date_time)
      return dateB - dateA
    })
  }
  sortNotificationsByDate(globalState?.notifications)

  const groupNotificationsByDate = notifications => {
    const groupedNotifications = {}

    notifications?.forEach(notification => {
      const dateKey = handleDay(notification?.date_time) // Replace with your date formatting logic

      if (!groupedNotifications[dateKey]) {
        groupedNotifications[dateKey] = []
      }
      groupedNotifications[dateKey].push(notification)
    })

    return groupedNotifications
  }
  const markRead = async id => {
    try {
      const response = await BaseService.patch(
        `/api/v1/notifications/read/${id}/`
      )
      // console.log(response?.data, "data");
      if (response.status === 200) {
        // console.log("updated successfully");
        getNotification(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const readAll = async () => {
    try {
      const response = await BaseService.patch(
        `/api/v1/notifications/read/`,
        true
      )
      console.log(response?.data, "data")
      if (response.status === 200) {
        // console.log("updated successfully");
        getNotification(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const groupedNotifications = groupNotificationsByDate(
    globalState?.notifications
  )

  const handleAction = notifications => {
    // if (notifications?.is_read) return;
    if (!notifications?.is_read) {
      markRead(notifications?.id)
    }

    switch (notifications?.action_type) {
      case notificationTypes?.coachingSession:
        navigate(
          `/app/coach/my-coachees/coachee-profile/${notifications?.data?.coachee_id}`
        )
        break
      case notificationTypes?.oneTime:
        return
        break
      case notificationTypes?.everyDayat8am:
        return
        break

      case notificationTypes?.coachReview:
        navigate(
          `/app/coachee/session-feedback/${notifications?.data?.coach_id}/${notifications?.data?.session_id}`
        )
        return
        break

      case notificationTypes?.coacheeFinalReportReview:
        navigate(
          `/app/coach/coachee-reports/${notifications?.data?.engagement_info_id}/${notifications?.data?.coachee_id}`
        )
        break
      case notificationTypes?.coacheeReview:
        navigate(
          `/app/coach/review-session/${notifications?.data?.session_id}/${notifications?.data?.coachee_id}`
        )
        return
        break

      case notificationTypes?.coachFinalReportReview:
        navigate(
          `/app/coachee/final-coach-feedback/${notifications?.data?.coach_id}/${notifications?.data?.engagement_info_id}`
        )

        return
        break

      case notificationTypes?.coachReviewForClient:
        navigate(
          `/app/client/employee-detail/${notifications?.data?.coachee_id}`
        )
        return
        break
      case notificationTypes?.coacheeFinalReportReviewForClient:
        navigate(
          `/app/client/employee-detail/${notifications?.data?.coachee_id}`
        )
        return
        break
      case notificationTypes?.assignCoaches:
        navigate(`/app/coachee/coaches`)
        return
        break

      default:
        break
    }
  }

  const handleOffset = givenDate => {
    // Parse the given date string to Luxon DateTime object
    const pastDate = DateTime.fromISO(givenDate)

    // Set the time zone to Eastern Time (ET) which accounts for DST
    const estDate = pastDate.setZone("Etc/GMT+5")

    // Format the date and time
    const formattedDateTime = estDate.toLocaleString({
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit"
    })

    return formattedDateTime
  }

  const navigate = useNavigate()

  return (
    <Container>
      {getNotificationloading && <Loading />}
      <Content>
        <Heading>Notifications</Heading>
      </Content>
      <Mainitem>
        {globalState?.notifications?.length > 0 ? (
          <>
            {Object.keys(groupedNotifications)?.map(dateKey => (
              <>
                <TodayContainer>
                  <ButtonContent>
                    <Todayheading>{dateKey}</Todayheading>
                    <>
                      {isFirstDate && (
                        <Btn onClick={() => readAll()}>Read all</Btn>
                      )}
                    </>
                  </ButtonContent>
                  {groupedNotifications[dateKey]?.map(item => (
                    <TodayContent
                      key={item.id}
                      is_read={!item?.is_read}
                      onClick={() => handleAction(item)}
                    >
                      <HeadingItem>
                        <Imgcontainer>
                          <Img
                            // src={
                            //   item?.data?.profile_picture
                            //     ? item?.data?.profile_picture
                            //     : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                            // }
                            src={
                              "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                            }
                            alt="Photo"
                          />
                        </Imgcontainer>
                        <ImgDescription>
                          <Title>{item?.notification_text}</Title>
                          <Descriptions>
                            {`${item?.notification_name} `}
                          </Descriptions>
                        </ImgDescription>
                      </HeadingItem>
                      <DateContent>
                        <DateInfo>{`${Timedifferencehandle(
                          item?.date_time
                        )} ${"ago"} `}</DateInfo>
                      </DateContent>
                    </TodayContent>
                  ))}
                </TodayContainer>
                {isFirstDate && (isFirstDate = false)}
              </>
            ))}
          </>
        ) : (
          <NoNotification>You don’t have any notifications yet</NoNotification>
        )}

        {/* <OtherNoticationContainer>
          <Othernotificationheading>14 Mar</Othernotificationheading>
          <OthernotificationContent>
            <HeadingItem>
              <Imgcontainer>
                <Img
                  src="https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                  alt=""
                />
              </Imgcontainer>
              <ImgDescription>
                <Title>Match</Title>
                <Descriptions>Pamela Safi match with Ramzi Rahal</Descriptions>
              </ImgDescription>
            </HeadingItem>
            <DateContent>
              <DateInfo>24 m ago</DateInfo>
            </DateContent>
          </OthernotificationContent>
        </OtherNoticationContainer> */}
      </Mainitem>
    </Container>
  )
}

export default Notifications

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
`

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1rem;
`

const Mainitem = styled.div`
  padding: 0rem 2rem;
`

const TodayContainer = styled.div``

const Todayheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`

const TodayContent = styled.div`
  /* background-color: ${({ theme }) => theme.backgroundDark}; */
  background-color: ${({ is_read, theme }) =>
    is_read ? theme.backgroundDark : "#fff"};
  border: 1px solid ${({ theme }) => theme.border2};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;
`

const HeadingItem = styled.div`
  padding: 0.6rem 0rem;
  display: flex;
  align-items: center;
`

const DateContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 0.8rem;
`

const DateInfo = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`
const Imgcontainer = styled.div`
  margin: 0 0.9rem;
  border-radius: 50%;
`

const Img = styled.img`
  border-radius: 50%;
  width: 3rem;
`
const ImgDescription = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
`

const Title = styled.h3`
  font-family: "Myriad-SemiBold";
  color: #2b2b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 20.332px */
  margin: 0;
`

const Descriptions = styled.p`
  font-family: "Myriad-Regular";
  color: #838181;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.227%; /* 14.523px */
  margin: 0;
`

const OtherNoticationContainer = styled.div``

const Othernotificationheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`

const OthernotificationContent = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 0.7rem;
  position: relative;

  svg {
    margin-right: 0.7rem;
    font-size: 1.2rem;
  }
`

const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Btn = styled.button`
  border: none;
  outline: none;
  background: transparent;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.dark};
  cursor: pointer;
`

const NoNotification = styled.h3`
  color: #1c1e21;
  font-family: "Myriad-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 105% */
  letter-spacing: -0.32px;
`
