import { BaseService } from "API/base";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as TermCondition } from "./term_and_conditon.svg";
import moment from "moment";
import Loading from "Components/Loader/Loading";

const SessionDetail = () => {
  const navigate = useNavigate();
  const [sessionData, setSessionData] = useState([]);
  const [getcoachloading, setGetCoachLoading] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const { id } = useParams();

  const [profileData, setProfileData] = useState();

  const getCoach = async () => {
    try {
      setGetCoachLoading(true);
      const response = await BaseService.get(`api/v1/coachees/coaches/`);
      if (response?.status === 200) {
        setProfileData(response?.data);
      }
      setGetCoachLoading(false);
    } catch (error) {
      Error(error?.detail);
    }
  };

  const CoacheesSession = async () => {
    try {
      setSessionLoading(true);
      const response = await BaseService.get(`/api/v1/coachees/session/${id}/`);
      if (response?.status === 200) {
        setSessionData(response?.data);
      }
      setSessionLoading(false);
    } catch (error) {
      Error(error?.detail);
    }
  };

  useEffect(() => {
    CoacheesSession();
  }, []);

  useEffect(() => {
    getCoach();
  }, []);

  return (
    <Container>
      {(getcoachloading && <Loading />) || (sessionLoading && <Loading />)}
      <Content>
        {/* <HeadingContent>
          <Heading>Coach Profile</Heading>
        </HeadingContent> */}
        {/* <Searchcontainer>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
            className="search_bar"
            style={{ borderRadius: "32px" }}
          />
        </Searchcontainer> */}
      </Content>
      {profileData?.map((item) => (
        <Mainitem>
          <>
            <MyProfile>
              <>
                <Profileimg>
                  <Image
                    src={
                      item?.profile_picture_url
                        ? item?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                    alt="profile"
                  />
                </Profileimg>
                <Profileinfo>
                  <ProfileHeading>
                    {item?.first_name} {item?.last_name}
                  </ProfileHeading>
                  <ProfilePosition>
                    {item?.years_of_experience} years coaching experience
                  </ProfilePosition>

                  <Profilelocation>
                    <Location style={{ marginBottom: "3px" }} />{" "}
                    {item?.city ? item?.city : "No city"}
                  </Profilelocation>
                </Profileinfo>
              </>
            </MyProfile>
          </>

          <ScheduleContainer>
            <Scheduleheading>Select Coaching Sessions</Scheduleheading>
          </ScheduleContainer>
          <PastContainer>
            {Array(item?.num_sessions)
              .fill(item?.num_sessions)
              .map((data, index) => (
                <>
                  <PastContent
                    onClick={() =>
                      navigate(`/app/coachee/schedule-session/${id}`)
                    }
                  >
                    <>
                      <HeadingItem>
                        <Imgcontainer>
                          <TermCondition style={{ marginRight: 0 }} />
                        </Imgcontainer>
                        <ImgDescription>
                          <Title>Coaching Session</Title>
                          <Descriptions isdark={sessionData}>
                            {sessionData[index]?.session_date
                              ? `${moment(
                                  sessionData[index]?.session_date
                                ).format("MMMM D, YYYY")} ${sessionData[
                                  index
                                ]?.start_time.slice(0, -2)} ${sessionData[
                                  index
                                ]?.start_time.slice(5, 8)} - ${sessionData[
                                  index
                                ]?.end_time.slice(0, -2)} ${sessionData[
                                  index
                                ]?.end_time.slice(5, 8)} EST`
                              : "Session not selected"}
                          </Descriptions>
                        </ImgDescription>
                      </HeadingItem>
                      <Wrapper>
                        <ArrowWrapper>
                          <MdKeyboardArrowRight />
                        </ArrowWrapper>
                      </Wrapper>
                    </>
                  </PastContent>
                </>
              ))}
          </PastContainer>
        </Mainitem>
      ))}
    </Container>
  );
};

export default SessionDetail;

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
`;

const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1rem;
  margin-bottom: 20px;
`;

const Mainitem = styled.div`
  padding: 1.7rem 3rem;
  /* border: 1px solid green; */
`;

const Wrapper = styled.div`
  position: relative;
  height: 70px;
`;
const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ScheduleContainer = styled.div``;

const Scheduleheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-top: 1.5rem;
`;

const HeadingItem = styled.div`
  padding: 0.6rem 0rem;
  display: flex;
  align-items: center;
`;
const Imgcontainer = styled.div`
  margin: 0 0.9rem;
  border-radius: 50%;
  /* padding: 9px 9px; */
  background-color: #f5f5f5;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgDescription = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
`;

const Title = styled.h3`
  font-family: "Myriad-SemiBold";
  color: #2b2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%;
  margin: 0;
`;

const Descriptions = styled.p`
  font-family: "Myriad-Regular";
  color: #838181;
  font-size: 12px;
  font-style: normal;
  font-weight: ${({ isdark }) => (isdark ? "700" : "400")};
  line-height: 145.227%; /* 17.427px */
  margin: 0;
`;

const PastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* border: 1px solid green; */
`;

const PastContent = styled.div`
  background-color: ${({ theme, is_notify }) =>
    is_notify && theme.backgroundDark};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1px solid rgba(151, 173, 182, 0.2);
  /* margin-bottom: 0.7rem; */
  position: relative;
  svg {
    margin-right: 0.7rem;
    font-size: 1.2rem;
  }
  cursor: pointer;
`;

const MyProfile = styled.div`
  display: flex;
  align-items: center;
`;
const Profileimg = styled.div`
  width: 84px;
  height: 84px;
  margin-right: 0.9rem;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Profileinfo = styled.div`
  margin-top: 7px;
`;

const ProfileHeading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

const ProfilePosition = styled.h5`
  color: #5b5b5b;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textMedium};
  margin: 0;
`;

const Profilelocation = styled.p`
  font-family: "Myriad-Regular";
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-style: normal;
  color: #5b5b5b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.24px;
  margin: 0;
  margin-top: 3px;

  .anticon svg {
    margin-right: 5px;
  }
`;
