import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LeftArrow } from "./left_arrow.svg";
import { Row, Tabs, Col } from "antd";
import CoachReview from "./CoachReview/CoachReview";
import CoacheeReview from "./CoacheeReview/CoacheeReview";
import EngagementStatus from "./EngagementStatus/EngagementStatus";
import { Data } from "../Analytics/mocData";
import { BaseService } from "API/base";
import Loading from "Components/Loader/Loading";
const MyEmployDetails = () => {
  const [listItems, setListItems] = useState();
  const [completedReport, setCompletedReport] = useState();
  const [engagmentinfo, setEngagmentinfo] = useState();
  const [coachDetail, setCoachdetail] = useState();

  const [getDetailloading, setgetDetailLoading] = useState(false);
  const [employeeLoading, setemployeeLoading] = useState(false);
  const [getcoachLoading, setgetcoachLoading] = useState(false);
  const [finalreportLoading, setfinalreportLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const onChange = (key) => {
    // console.log(key);
  };

  const items = [
    {
      key: "1",
      label: `Coach Review`,
      children: <CoachReview data={completedReport?.coach_review} />,
    },
    {
      key: "2",
      label: `Coachee Review`,
      children: <CoacheeReview data={completedReport?.coachee_review} />,
    },
    {
      key: "3",
      label: `Engagement Status`,
      children: <EngagementStatus data={engagmentinfo} />,
    },
  ];

  const getDetails = async () => {
    try {
      setgetDetailLoading(true);
      const response = await BaseService.get(`/api/v1/coachees/`, true);
      const filter = response?.data?.find((item) => item?.id == id);

      setListItems(filter);
      setgetDetailLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const employeeEngagmentInfo = async () => {
    try {
      setemployeeLoading(true);
      const response = await BaseService.get(
        `api/v1/clients/employees/engagement_info/${id}/`
      );

      if (response?.status === 200) {
        setEngagmentinfo(response?.data);
        setemployeeLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(engagmentinfo);
  useEffect(() => {
    if (!engagmentinfo) return;
    finalReport(engagmentinfo?.id);
    getCoach(engagmentinfo?.coach);
  }, [engagmentinfo]);

  const finalReport = async (engagmentInfoId) => {
    try {
      setfinalreportLoading(true);
      const response = await BaseService.get(
        `/api/v1/clients/employees/final_report/reviews/${engagmentInfoId}/`
      );
      if (response?.status === 200) {
        setCompletedReport(response?.data);
        setfinalreportLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCoach = async (id) => {
    try {
      setgetcoachLoading(true);
      const response = await BaseService.get(`api/v1/coaches/${id}/`);
      if (response?.status === 200) {
        setCoachdetail(response?.data);
      }
      setgetcoachLoading(false);
    } catch (error) {
      Error(error?.detail);
    }
  };

  useEffect(() => {
    if (!id) return;
    employeeEngagmentInfo();
  }, [id]);

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Container>
      {(getDetailloading && <Loading />) ||
        (finalreportLoading && <Loading />) ||
        (employeeLoading && <Loading />) ||
        (getcoachLoading && <Loading />)}
      <Heading>
        <LeftArrow style={{marginBottom:'0.2rem'}}  onClick={() => navigate(-1)}/>

        Analytics
      </Heading>
      <Outlet />
      <TabWrapper>
        {/* {Data?.map(
          (item, index) =>
            item?.id === +id && ( */}
        <Row>
          <Col span={16}>
            <ProfileSection>
              <ProfileImage
                src={
                  listItems?.profile_picture_url
                    ? listItems?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                alt=""
              />
              <ProfileDetails>
                <Name>
                  {listItems &&
                    `${listItems.first_name} ${listItems.last_name}`}
                </Name>
                <SubTitle>{listItems?.title}</SubTitle>
                <ProfileLocation>
                  {/* <Location /> */}
                  {listItems?.city || "No City"}
                </ProfileLocation>
              </ProfileDetails>
            </ProfileSection>
          </Col>
          <Col span={6}>
            <CoachSection>
              <CoachImage
                src={
                  coachDetail?.profile_picture_url
                    ? coachDetail?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
              />
              <CoachDetials>
                <CoachName>
                  {" "}
                  {coachDetail &&
                    `${coachDetail.first_name} ${coachDetail.last_name}`}
                </CoachName>
                <SubTitle>Coach</SubTitle>
              </CoachDetials>
            </CoachSection>
          </Col>
        </Row>
        {/* )
        )} */}

        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </TabWrapper>
    </Container>
  );
};

export default MyEmployDetails;
const Container = styled.div`
  height: 100vh;
  background-color: #ffffff;
  overflow-y: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e1dfdf;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Heading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  padding-left: 2.5rem;
  border-bottom: 1px solid rgba(194, 193, 189, 0.57);
  padding-bottom: 1.2rem;
  padding-top: 1rem;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
`;

const TabWrapper = styled.div``;
const ProfileSection = styled.div`
  padding: 1.5rem 2.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
const ProfileImage = styled.img`
  height: 84px;
  width: 84px;
  border-radius: 50%;
`;
const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const Name = styled.p`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
  margin: 0;
`;
const CoachSection = styled.div`
  border: 1px solid rgba(151, 173, 182, 0.2);
  width: 330px;
  height: 68px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  padding: 0rem 0.5rem;
`;
const CoachImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;
const CoachDetials = styled.div``;
const CoachName = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145.23%;
  margin: 0;
  display: flex;
  align-items: center;

  color: #2b2b2b;
`;
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #5b5b5b;
  margin: 0;
  padding: 0;
`;
const ProfileLocation = styled.p`
  font-family: "Myriad-Regular";
  color: #5b5b5b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* gap: 0.5rem; */
  margin: 0;
`;
