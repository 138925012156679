import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { ReactComponent as LeftArrow } from "./left_arrow.svg"
import { ReactComponent as Location } from "./location.svg"
import { ReactComponent as CertificateIconSvg } from "./certificate_Icon.svg"
import { useNavigate, useParams } from "react-router-dom"
import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import { Error } from "Responses/Response"

const CoachProfileDetail = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [coachDetail, setCoachDetail] = useState()
  const [isCoachdetailLoading, setIsCoachDetailLoading] = useState(false)

  const getCoachDetail = async () => {
    try {
      setIsCoachDetailLoading(true)
      const response = await BaseService.get(`api/v1/coaches/${id}/`)
      if (response?.status === 200) {
        setCoachDetail(response?.data)
      }
    } catch (error) {
      Error(error?.detail)
    } finally {
      setIsCoachDetailLoading(false)
    }
  }

  useEffect(() => {
    getCoachDetail()
  }, [])

  return (
    <Container>
      {isCoachdetailLoading && <Loading />}
      <Content>
        <HeadingContent>
          <LeftArrow onClick={() => navigate(-1)} />
          <Heading>Coach Profile</Heading>
        </HeadingContent>
      </Content>

      <CardContainer>
        <Card>
          <ProfileContent>
            <ProfileImage>
              <img
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                src={
                  coachDetail?.profile_picture_url
                    ? coachDetail?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                alt=""
              />
            </ProfileImage>
            <ProfileInfoContainer>
              <ProfileName>
                {" "}
                {coachDetail &&
                  `${coachDetail?.first_name} ${coachDetail?.last_name}`}
              </ProfileName>
              <ProfileExp>
                {coachDetail?.years_of_experience} years coaching experience
              </ProfileExp>
              <ProfileLocation>
                <Location style={{ marginBottom: "4.5px" }} />
                {coachDetail?.city}
              </ProfileLocation>
            </ProfileInfoContainer>
          </ProfileContent>
          <ReactPlayerContainer>
            {coachDetail?.intro_video_url && (
              <ReactPlayer
                // ref={playerRef}
                width={"100%"}
                url={
                  coachDetail?.intro_video_url
                    ? coachDetail?.intro_video_url
                    : "https://www.pexels.com/video/changes-in-form-and-appearance-of-a-submerged-material-3163534/"
                }
                // url={videoUrl ? videoUrl : values?.intro_video}
                controls={true}
                height={"auto"}

                // onProgress={handleProgress}
              />
            )}
            {/* <DeleteButton
              onClick={(e) => handleDelete(e, videoUrl, setFieldValue)}
            >
              <DeleteIconWhite />
            </DeleteButton> */}
          </ReactPlayerContainer>
          <Description>{coachDetail?.bio && coachDetail?.bio}</Description>
          <CertificateContainer>
            <CertificateHeading>
              Certificates and documentation
            </CertificateHeading>
            <CertificateWrapper>
              <CertificateIconSvg />

              {coachDetail?.certificates?.map(item => (
                <CertificateName>{item?.name}</CertificateName>
              ))}
            </CertificateWrapper>
          </CertificateContainer>
          <ViewButton
            onClick={() =>
              navigate(`/app/coachee/schedule-session/${coachDetail?.id}`)
            }
          >
            Schedule Chemistry Call
          </ViewButton>
        </Card>
      </CardContainer>
    </Container>
  )
}

export default CoachProfileDetail
const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
`

const Heading = styled.h3`
  margin: 0;
  margin-left: 1.7rem;
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`

const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 2rem;

  svg {
    margin-bottom: 0.3rem;
  }
`

const CardContainer = styled.div`
  padding: 3rem 3.5rem;
  /* height: 80vh; */
  display: flex;
  justify-content: center;
`

const Card = styled.div`
  /* border: 1px solid red; */
  width: 350px;
  min-height: 780px;
  height: 100%;
  border-radius: 24px;
  border: 1px solid #dcdcd9;
  display: flex;
  flex-direction: column;
  padding: 0rem 4.2rem;
`

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  /* border: 1px solid green; */
`
const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.3rem;
`

const ProfileImage = styled.div`
  width: 72px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
const ProfileName = styled.h3`
  margin: 0;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
`

const ProfileExp = styled.h4`
  margin: 0;
  margin-top: 2px;
  color: ${({ theme }) => theme.textMedium};
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  letter-spacing: -0.24px;
`

const ProfileLocation = styled.p`
  margin: 0;
  margin-top: 2px;
  font-family: "Myriad-Regular";
  gap: 0.3rem;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textMedium};
  font-size: 16px;
`

const Description = styled.p`
  /* border: 1px solid green; */
  margin: 0;
  margin-top: 2.1rem;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px; /* 153.125% */
  letter-spacing: -0.32px;
  overflow: hidden;
  height: 167px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`

const ViewButton = styled.button`
  border-radius: 100px;
  /* width: 440px; */
  margin: 1.7rem 0rem 3rem 0rem;
  padding: 10px;
  background: #e4bf44;
  border: none;
  outline: none;
  cursor: pointer;
  color: #221f20;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`

const ReactPlayerContainer = styled.div`
  margin-top: 1.2rem;
  min-height: 200px;
`

const CertificateContainer = styled.div`
  margin-top: 1.5rem;
`
const CertificateHeading = styled.h2`
  margin: 0;
  margin-top: 0.5rem;
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
`

const CertificateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.55rem;
  margin: 2rem 0rem 0.7rem 0rem;
`

const CertificateName = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.textDark};
  text-transform: capitalize;
  font-family: "Myriad-Regular";
  gap: 0.7rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%; /* 26.141px */
`
