import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Button from "Elements/Button/Button"
import { Modal } from "antd"
import { getCookie, removeAllCookie } from "Utils/StorageVariables"
import { userTypes } from "Utils/Common"
import { BaseService } from "API/base"
import { Error, Success } from "Responses/Response"
import { useNavigate } from "react-router-dom"

const DeleteModal = ({
  isDeleteModal,
  setisDeleteModal,
  onclick,
  isloading,
  Username
}) => {
  const navigate = useNavigate()
  // const [isLoading, setIsLoading] = useState(false)

  const onClose = () => {
    setisDeleteModal(false)
  }

  const userData = JSON.parse(getCookie("userData"))

  return (
    <>
      <Modal
        footer={false}
        className="delete_modal"
        open={isDeleteModal}
        onCancel={onClose}
      >
        <Container>
          <ModelContent>
            <ExclamationCircleOutlined />
            <Description> 
              
              Are you sure you want to delete {Username} account?
            </Description>
            <ButtonContent>
              <Button isBorder isDeleted disabled={isloading} onClick={onclick}>
                Delete 
              </Button>
              <Button
                isBorder
                isBackgroundLight
                onClick={() => onClose()}
                disabled={isloading}
              >
                Cancel
              </Button>
            </ButtonContent>
          </ModelContent>
        </Container>
      </Modal>
    </>
  )
}

export default DeleteModal

const Container = styled.div`
  height: 440px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .anticon svg {
    color: #f47665;
    font-size: 100px;
  }
`

const Description = styled.p`
  font-style: normal;
  width: 250px;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 2rem;
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 295px;
  gap: 1rem;
`
