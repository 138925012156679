import React, { Fragment, useContext, useEffect, useState } from "react"
import { Routes, Route, Outlet } from "react-router-dom"
import DisplayElements from "../DisplayElements/DisplayElements"
import Login from "Pages/Login/Login"
import ForgetPassword from "Pages/ForgetPassword/ForgetPassword"
import MainApp from "Pages/MainApp"
import Analytics from "Pages/MainApp/Analytics/Analytics"
import OptCode from "Pages/OptCode/OptCode"
import NewPassword from "Pages/NewPassword/NewPassword"
import ClientProfile from "Pages/MainApp/ClientProfile/ClientProfile"
import MyEmployees from "Pages/MainApp/MyEmployees/MyEmployees"
import Notifications from "Pages/MainApp/Notifications/Notifications"
import Settings from "Pages/MainApp/Settings/Settings"
import CoachProfile from "Pages/MainApp/CoachProfile/CoachProfile"
import MyCoaches from "Pages/MainApp/MyCoachees/MyCoachees"
import { useProtectedRoutes } from "Hooks/protectedRoutes"
import PrivacyPolicy from "Pages/PrivacyPolicy/PrivacyPolicy"
import TermCondition from "Pages/Term&Condition/TermCondition"
import { useGuestRoutes } from "Hooks/guestRoutes"
import EmployeesDetails from "Pages/MainApp/EmployeesDetails/EmployeesDetaIls"
import CoachCalendar from "Pages/MainApp/Calendar/Calendar"
import CalenderSetting from "Pages/CalenderSetting/CalenderSetting"
import CoachDetails from "Pages/MainApp/CoachDetails/CoachDetails"
import MyEmployDetails from "Pages/MainApp/MyEmployDetails/MyEmployDetails"
import ReviewCoachSession from "Pages/MainApp/Notifications/ReviewCoachSession/ReviewCoachSession"
import CoacheeReport from "Pages/MainApp/Notifications/CoacheeReport/CoacheeReport"
import CoachingSession from "Pages/MainApp/CoachingSession/CoachingSession"
import { getCookie, removeAllCookie } from "Utils/StorageVariables"
import { BaseService } from "API/base"
import CoacheeProfileDetails from "Pages/MainApp/MyCoachees/CoacheeProfile/CoacheeProfile"
import CoacheeProfile from "Pages/MainApp/CoacheeProfile/CoacheeProfile"
import Home from "Pages/MainApp/Home/Home"
import CoacheeSchedular from "Pages/MainApp/CoacheeSchedular/CoacheeSchedular"
import CoacheeList from "Pages/MainApp/CoacheeList/CoacheeList"
import CoachProfileDetail from "Pages/MainApp/CoachProfileDetail/CoachProfileDetail"
import ScheduleSession from "Pages/MainApp/ScheduleSession/ScheduleSession"
import SessionDetail from "Pages/MainApp/SessionDetail/SessionDetail"
import CoachSessionFeedback from "Pages/MainApp/CoachSessionFeedback/CoachSessionFeedback"
import CoachFInalReport from "Pages/MainApp/CoachFinalReport/CoachFinalReport"
import CoachFinalReport from "Pages/MainApp/CoachFinalReport/CoachFinalReport"
import VideoRoomDemo from "Pages/VideoRoom/VideoRoom"
import Connecting from "Pages/VideoRoom/Connecting"
// import { GlobalStateContext } from "GlobalStateContext";

const AppRoutes = () => {
  // const myEventsList = [
  //   {
  //     title: "Meeting with John",
  //     start: new Date(2023, 4, 3, 10, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 4, 3, 11, 0),
  //   },
  //   {
  //     title: "Lunch with Sarah",
  //     start: new Date(2023, 4, 4, 12, 0),
  //     end: new Date(2023, 4, 4, 13, 0),
  //   },
  // ];

  const { ProtectedRoute } = useProtectedRoutes()
  const { GuestRoute } = useGuestRoutes()

  return (
    <Fragment>
      <Routes>
        <Route path="/display" element={<DisplayElements />} />
        <Route path="/video_room/connecting/:id" element={<Connecting />} />
        <Route path="/video_connected/:token" element={<VideoRoomDemo />} />

        <Route
          path="app"
          element={
            <ProtectedRoute>
              <MainApp />
            </ProtectedRoute>
          }
        >
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="term-condition" element={<TermCondition />} />

          <Route path="notification" element={<Outlet />}>
            <Route index path="" element={<Notifications />} />
          </Route>
          <Route path="settings" element={<Settings />} />

          <Route path="coach" element={<Outlet />}>
            <Route
              path="review-session/:id/:profileId"
              element={<ReviewCoachSession />}
            />
            <Route
              path="coachee-reports/:id/:profileId"
              element={<CoacheeReport />}
            />
            <Route path="profile" element={<CoachProfile />} />
            {/* <Route path="calendar-setting" element={<CalenderSetting />} /> */}
            <Route path="calendar" element={<Outlet />}>
              <Route path="coach-calender" element={<CoachCalendar />} />
              <Route path="coach-setting" element={<CalenderSetting />} />
            </Route>
            <Route path="my-coachees" element={<Outlet />}>
              <Route index path="" element={<MyCoaches />} />
              <Route
                path="coachee-profile/:id"
                element={<CoacheeProfileDetails />}
              />
            </Route>
          </Route>

          <Route path="coachee" element={<Outlet />}>
            <Route
              path="coach-profile-detail/:id"
              element={<CoachProfileDetail />}
            />
            <Route
              path="session-feedback/:id/:sessionId"
              element={<CoachSessionFeedback />}
            />
            <Route
              path="final-coach-feedback/:id/:engagmentinfoId"
              element={<CoachFinalReport />}
            />

            <Route path="profile" element={<CoacheeProfile />} />
            {/* <Route path="" element={<CoacheeProfile />} /> */}
            <Route path="schedule-session/:id" element={<ScheduleSession />} />

            <Route path="coaches" element={<Home />}>
              <Route index element={<CoacheeList />} />
              <Route path="session-detail/:id" element={<SessionDetail />} />
              <Route path="Calender" element={<CoacheeSchedular />} />
            </Route>
          </Route>

          <Route path="client" element={<Outlet />}>
            <Route path="profile" element={<ClientProfile />} />
            <Route path="analytics" element={<Analytics />} />
            <Route
              path="analytics/coach-details/:id"
              element={<CoachDetails />}
            />
            <Route
              path="analytics/myemploy-details/:id"
              element={<MyEmployDetails />}
            />

            <Route path="my-employees" element={<MyEmployees />} />
            <Route path="employee-detail/:id" element={<EmployeesDetails />} />
            <Route
              path="coaching-session/:id/:profileId"
              element={<CoachingSession />}
            />
          </Route>
        </Route>
        <Route
          path="/"
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
        />
        <Route
          path="/verify"
          element={
            <GuestRoute>
              <OptCode />
            </GuestRoute>
          }
        />
        <Route
          path="/new-password/:uid/:token"
          element={
            <GuestRoute>
              <NewPassword />
            </GuestRoute>
          }
        />
        <Route
          path="/forget-password"
          element={
            <GuestRoute>
              <ForgetPassword />
            </GuestRoute>
          }
        />
      </Routes>
    </Fragment>
  )
}

export default AppRoutes
