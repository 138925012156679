import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Tabs } from "antd";
import MyEmployees from "./MyEmployees/MyEmployees";
import AggregateDataAnalytics from "./AggregateDataAnalysis/AggregateDataAnalytics";

const Analytics = () => {
  const onChange = (key) => {
    // console.log(key);
  };

  const items = [
    {
      key: "1",
      label: `Aggregate Data Analytics`,
      children: <AggregateDataAnalytics />,
    },
    {
      key: "2",
      label: `My Employees`,
      children: <MyEmployees />,
    },
  ];

  return (
    <Container>
      <Heading>Analytics</Heading>
      <Outlet />
      <TabWrapper>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </TabWrapper>
    </Container>
  );
};

export default Analytics;
const Container = styled.div`
  height: 100vh;
  background-color: #ffffff;
`;

const Heading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  padding-left: 2.5rem;
  border-bottom: 1px solid rgba(194, 193, 189, 0.57);
  padding-bottom: 1.2rem;
  margin-top: 0;
  padding-top: 1rem;
`;

const TabWrapper = styled.div`
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid rgba(194, 193, 189, 0.57);
  }
`;
