import React, { useState, useEffect, useContext } from "react"
import Room from "./Room"
import { ReactComponent as DarkLogo } from "./darkLogo.svg"
import styled from "styled-components"
import Loading from "Components/Loader/Loading"
import { connect } from "twilio-video"
import { GlobalStateContext } from "GlobalStateContext"
import { useNavigate, useParams } from "react-router-dom"
import { Error } from "Responses/Response"
import { getCookie } from "Utils/StorageVariables"

const VideoRoomDemo = ({}) => {
  const [progress, setProgress] = useState(1)
  const { token } = useParams()
  const [joinRoomLoading, setjoinRoomLoading] = useState(false)
  const [room, setRoom] = useState(null)
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)
  const userData = JSON.parse(getCookie("userData"))
  const [isShow, setisShow] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 3) {
        setProgress(prevProgress => prevProgress + 1)
      } else {
        clearInterval(interval)
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [progress])

  const joinRoom = async token => {
    setjoinRoomLoading(true)

    try {
      const room = await connect(token, {
        name: "cool-room",
        audio: true,
        video: true
      })
      console.log(room)
      setRoom(room)

      const localVideoTrack = room.localParticipant.videoTracks.values().next()
        .value?.track
      const localAudioTrack = room.localParticipant.audioTracks.values().next()
        .value?.track

      if (!globalState?.isAudio && !globalState?.isVideo) {
        localAudioTrack?.disable()

        localVideoTrack?.disable()
      } else if (!globalState?.isAudio) {
        localAudioTrack?.disable()
      } else if (!globalState?.isVideo) {
        localVideoTrack?.disable()
      }
    } catch (err) {
      Error(err?.message)
    } finally {
      setjoinRoomLoading(false)
    }
  }

  const leaveRoom = () => {
    setRoom(null)
  }

  useEffect(() => {
    if (!token) return
    joinRoom(token)
  }, [])

  // useEffect(() => {
  //   if (userData?.user_type === "Coachee" && room?.participants?.size === 0) {
  //     room?.disconnect()
  //     setisShow(true)
  //     leaveRoom()
  //     Error("Coach is Not Available");
  //     navigate("/app/coachee/coaches/Calender");
  //   }
  // }, [ room]);

  return (
    <div className="app">
      {room === null ? (
        <Loading />
      ) : (
        <Container isBackground>
          <Header>
            <LogoWrapper>
              <DarkLogo />
            </LogoWrapper>
            <Heading isColor>Coaching Session</Heading>
          </Header>
          <SubContainer>
            {!isShow && (
              <Room
                progress={progress}
                leaveRoom={leaveRoom}
                room={room}
                audio={globalState?.isAudio}
                video={globalState?.isVideo}
              />
            )}
          </SubContainer>
        </Container>
      )}
    </div>
  )
}

export default VideoRoomDemo

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ isBackground }) => isBackground && "#252525"};
`

const Header = styled.div`
  width: 100%;
  height: 92px;
  border-bottom: 1px solid #c2c1bd;
  display: flex;
  align-items: center;
`

const LogoWrapper = styled.div`
  padding-left: 1.5rem;
`

const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ isColor }) => (isColor ? "#fff" : "#2b2b2b")};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0.374px;
  text-align: center;
  width: calc(100% - 12.5rem);
  margin-right: 3rem;
`

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  flex-direction: column;
  /* position: relative;
  video{
    width: 750px !important;
    height: 463px !important;
    position: relative;
  } */
`
const AudioContainaer = styled.div`
  width: ${({ isWidth }) => (isWidth ? "263px" : "615px")} !important;
  height: ${({ isWidth }) => (isWidth ? "212px" : "463px")} !important  ;
  position: relative;
  border-radius: 24px;
  background: ${({ isWidth }) => (isWidth ? "#252525" : "#ECECEC")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Image = styled.img`
  width: ${({ isWidth }) => (isWidth ? "80px" : "150px")};
  height: ${({ isWidth }) => (isWidth ? "80px" : "150px")};
  border-radius: 50%;
`
const Button = styled.button`
  border-radius: 100px;
  background: #e4bf44;
  display: flex;
  width: 150px;
  padding: 10px;
  justify-content: center;
  color: #221f20;
  align-items: center;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
  margin-top: 1rem;
`
const JoiningHeading = styled.h1`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  margin-top: 1rem;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  letter-spacing: 0.374px;
`
const ButtonsWrapper = styled.div`
  height: 400px !important;
  width: 580px !important;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: flex-end;
  position: absolute;

  top: 0;

  bottom: 53%;
`
const AudioButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
`
const JoiningName = styled.p`
  margin: 0;

  padding: 0;
  color: #2b2b2b;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px; /* 153.125% */
  letter-spacing: -0.32px;
`
const SubContainerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;
  position: relative;
  border-radius: 25px;
  video {
    width: 750px !important;
    height: 463px !important;
    position: relative;
  }
`
