import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "Schemas";
import Button from "Elements/Button/Button";
import Input from "Elements/Input/Input";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { BaseService } from "API/base";
import { Error, Success } from "Responses/Response";
import { removeAllCookie } from "Utils/StorageVariables";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const handleForgotPassword = async (values) => {
    setIsLoading(true);
    // console.log({ values }, "forgot password values");
    try {
      const response = await BaseService.post(
        "rest-auth/password/reset/",
        values,
        false
      );
      if (response?.status === 200) {
        Success(response?.data?.detail);
        navigate("/");
        removeAllCookie();
      }
      // console.log({ response }, "forgot password response");
    } catch (error) {
      error?.response?.data?.map((err) => Error(err));
    } finally {
      setIsLoading(false);
    }
  };
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: () => handleForgotPassword(values),
  });

  // useEffect(() => {
  //   removeCookie("globalState");
  // }, []);

  return (
    <>
      <Main>
        <MainContainer>
          <Logocontainer>
            <Logo src="/Assets/Group 462.svg" alt="" />
          </Logocontainer>
          <Form onSubmit={handleSubmit}>
            <InputField>
              <BtnContent>
                <Forgetbtn>
                  {" "}
                  <LeftOutlined
                    onClick={() => navigate(-1)}
                    style={{
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                      fontWeight: "border",
                    }}
                  />
                </Forgetbtn>
                <Heading>Forgot password</Heading>
              </BtnContent>
              <Description>
                Please enter your registered email to reset your password!
              </Description>

              <InputWrapper>
                <Input
                  Error={errors.email && touched.email}
                  label={"Email"}
                  type="email"
                  placeholder={"Email"}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <Span>{errors.email}</Span>
                ) : null}
              </InputWrapper>
              <Buttonwrapper>
                <Button
                  isBorder
                  type="submit"
                  disabled={isSubmitting || isLoading}
                >
                  {isLoading ? "Loading..." : "Reset Password"}
                </Button>
              </Buttonwrapper>
            </InputField>
          </Form>
        </MainContainer>
      </Main>
    </>
  );
};

export default ForgetPassword;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div``;
const Logocontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;

const Logo = styled.img``;

const Form = styled.form`
  width: 458px;
  min-height: 341px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid green;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 24px;
`;

const InputField = styled.div`
  width: 80%;
  padding: 1.2rem;
`;

const BtnContent = styled.div`
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  width: 100%;
`;

const Forgetbtn = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 2rem;
  margin-left: 1rem;
`;

const Description = styled.p`
  text-align: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 1rem;
`;

const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`;

const InputWrapper = styled.div`
  margin-bottom: 1.2rem;
`;

const Buttonwrapper = styled.div`
  margin-top: 1.5rem;
`;

const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 458px;
  min-height: 341px;
  height: 100%;
`;

const MessageDescription = styled.h3`
  text-align: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;
