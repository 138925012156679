import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdArrowBackIos } from "react-icons/md";
import { BaseService } from "API/base";
import Loading from "Components/Loader/Loading";
import { useNavigate } from "react-router-dom";

const TermCondition = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    CallAPI();
  }, []);

  const CallAPI = async () => {
    setIsLoading(true);
    try {
      const response = await BaseService.get(
        "modules/terms-and-conditions/",
        false
      );
      if (response?.status === 200) {
        setData(response?.data[0]?.body);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Main>
      <Content>
        <Button onClick={() => navigate(-1)}>
          <MdArrowBackIos />
        </Button>
        <Heading>Terms and Conditions</Heading>
      </Content>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Span>
            Returns and Cancellations <br /> 2 Kids SA Gradloup Money Back
            Guarantee
          </Span>
          <Description dangerouslySetInnerHTML={{ __html: data }}></Description>
        </>
      )}
    </Main>
  );
};

export default TermCondition;

const Main = styled.div`
  background-color: ${({ theme }) => theme.white};
  /* border: 1px solid red; */
  height: 100vh;
  overflow-x: auto;
`;

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 10vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  padding: 0rem 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`;

const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-right: 0.8rem;

  svg {
    font-size: 20px;
    margin-top: 0.4rem;
  }
`;

const Span = styled.span`
  font-family: "Myriad-Light";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  padding: 2rem 1.7rem 1rem 1.7rem;
  display: inline-block;
  position: relative;
  top: 4.6rem;
`;
const Description = styled.p`
  font-family: "Myriad-Light";
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  padding: 0.5rem 1.7rem;
  margin-top: 0;
  position: relative;
  top: 2.7rem;
`;
