import Button from "Elements/Button/Button";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Row, Col, Checkbox } from "antd";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BaseService } from "API/base";
import { useNavigate } from "react-router-dom";
import AddEmployesModal from "Components/AddEmployesModal/AddEmployesModal";
import { Error } from "Responses/Response";
import { ReactComponent as Trash } from "./dustbin.svg";
import DeleteModal from "Components/DeleteModal/DeleteModal";
import Loading from "Components/Loader/Loading";
import NoDataFound from "Components/NoDataFound/NoDataFound";

const MyEmployees = () => {
  const navigate = useNavigate();
  const [isDeleteModal, setisDeleteModal] = useState(false);
  const [isshown, setisshown] = useState(true);
  const [allLists, setAllLists] = useState([]);
  const [isShown, setisShown] = useState(false);
  const [isDeleteLoading, setisDeleteLoading] = useState(false);
  const [inpVal, setInpVal] = useState("");
  const [listItems, setListItems] = useState([]);
  const [addEmployee, setAddEmployee] = useState(false);
  const [getEmployeesLoading, setGetEmployeesLoading] = useState(false);

  const getEmployees = async () => {
    try {
      setGetEmployeesLoading(true);
      const response = await BaseService.get(`/api/v1/coachees/`, true);
      setAllLists(response?.data);
      setListItems(response?.data);
      setGetEmployeesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEmployees();
  }, []);

  const handleDelete = async (id) => {
    setisDeleteModal(false);
    setisDeleteLoading(true);
    try {
      const response = await BaseService.remove(
        `/api/v1/coachees/${id}/`,
        true
      );
      if (response?.status === 204) {
        getEmployees();
      }
    } catch (error) {
      Error(error);
    } finally {
      setisDeleteLoading(false);
    }
  };

  useEffect(() => setListItems(allLists), []);

  const handleChange = (e) => {
    if (e.target.value.length >= 1) {
      setisShown(true);
    }
    setInpVal(e.target.value);
  };

  const handleSearch = (value) => {
    const copyItems = [...listItems];
    let finalReports = copyItems?.filter((item) =>
      item?.first_name?.toLowerCase().includes(value?.toLowerCase())
    );
    if (value?.length === 0) {
      setListItems(allLists);
    } else {
      setListItems(finalReports);
    }
  };

  return (
    <Container>
      {getEmployeesLoading && <Loading />}
      <Content>
        <Heading>My Employees</Heading>
        <Searchcontainer>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
            className="search_bar"
            value={inpVal}
            onChange={(e) => {
              handleChange(e);
              handleSearch(e?.target?.value, listItems);
            }}
            style={{ borderRadius: "32px" }}
          />
          <br />
          <BtnContent>
            <Button isBorder onClick={() => setAddEmployee(true)}>
              Add Employee
            </Button>
          </BtnContent>
        </Searchcontainer>
      </Content>
      <Mainitem>
        {listItems.length > 0 && (
          <EditButton>
            <Btn onClick={() => setisshown(!isshown)}>
              {isshown ? "Edit" : "Done"}
            </Btn>
          </EditButton>
        )}

        {isshown ? (
          <Row>
            {listItems[0] ? (
              listItems?.map((item) => (
                <Col span={24}>
                  <ModelContent>
                    <ButtonDropdown
                      onClick={() =>
                        navigate(`/app/client/employee-detail/${item?.id}`)
                      }
                    >
                      <HeadingItem>
                        <Imgcontainer>
                          <Img
                            src={
                              item?.profile_picture_url
                                ? item?.profile_picture_url
                                : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                            }
                            alt="picture"
                          />
                        </Imgcontainer>
                        <ImgDescription>
                          <Title>{`${item?.first_name} ${item?.last_name} `}</Title>
                          <Descriptions>{item?.department}</Descriptions>
                        </ImgDescription>
                      </HeadingItem>
                      <MdKeyboardArrowRight />
                    </ButtonDropdown>
                  </ModelContent>
                </Col>
              ))
            ) : (
              <NoDataFound />
            )}
          </Row>
        ) : (
          <Row>
            {listItems[0] ? (
              listItems?.map((item) => (
                <Col span={24}>
                  <ModelContent>
                    <ButtonDropdown onClick={() => {}}>
                      <HeadingItem>
                        <Checkboxcontainer>
                          <Checkbox />
                        </Checkboxcontainer>

                        <Imgcontainer>
                          <Img
                            src={
                              item?.profile_picture_url
                                ? item?.profile_picture_url
                                : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                            }
                            alt="picture"
                          />
                        </Imgcontainer>
                        <ImgDescription>
                          <Title>{`${item?.first_name} ${item?.last_name}`}</Title>
                          <Descriptions>{item?.department}</Descriptions>
                        </ImgDescription>
                      </HeadingItem>
                      <Deletebuttoncontainer>
                        <Deletebtn
                          disabled={isDeleteLoading}
                          onClick={() => setisDeleteModal(true)}
                          // onClick={() => handleDelete(item?.id)}
                        >
                          <Trash alt="trash" />
                        </Deletebtn>
                        <DeleteModal
                          Username={`${item?.first_name} ${item?.last_name}`}
                          isDeleteModal={isDeleteModal}
                          setisDeleteModal={setisDeleteModal}
                          onclick={() => handleDelete(item?.id)}
                        />
                      </Deletebuttoncontainer>
                    </ButtonDropdown>
                  </ModelContent>
                </Col>
              ))
            ) : (
              <NoDataFound />
            )}
          </Row>
        )}
      </Mainitem>
      {addEmployee && (
        <AddEmployesModal
          onSuccess={(status) => getEmployees()}
          onCancel={() => setAddEmployee(false)}
        />
      )}
    </Container>
  );
};

export default MyEmployees;
const Container = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 10vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`;

const EditButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
`;
const Btn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Myriad-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.dark};
  cursor: pointer;
`;

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`;
const Searchcontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BtnContent = styled.div`
  width: 210px;
  margin-left: 1.2rem;
`;

const Mainitem = styled.div`
  /* margin-top: 3rem; */
`;

const ModelContent = styled.div`
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 1.1rem;
  }
`;
const ButtonDropdown = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 0rem 1rem;
`;
const HeadingItem = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  align-items: center;
  /* border: 1px solid green; */
`;

const Imgcontainer = styled.div`
  /* border: 1px solid red; */
  border-radius: 50%;
  display: flex;
  margin-right: 1rem;
`;
const Img = styled.img`
  border-radius: 50%;
  height: 50px;
  width: 3rem;
`;
const ImgDescription = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
`;

const Title = styled.h3`
  font-family: "Myriad-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

const Descriptions = styled.p`
  font-family: "Myriad-Light";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.border};
  margin: 0;
`;

const Checkboxcontainer = styled.div`
  margin-right: 1rem;

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #e4bf44 !important;
    border-color: transparent !important;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #e4bf44 !important;
    border-color: transparent !important;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #e4bf44 !important ;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e4bf44;
    border-color: transparent;
  }
  /* .ant-checkbox:checked:hover .ant-checkbox-inner {
  border-color: transparent !important;
} */

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #e4bf44;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #e4bf44 !important;
    border-color: transparent !important;
  }
`;
const Deletebuttoncontainer = styled.div``;

const Deletebtn = styled.button`
  background-color: #eb4f4f;
  padding: 8px 10px;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
`;
