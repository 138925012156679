import React, { useEffect, useState } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { Row, Tabs, Col } from "antd"
import CoachReview from "./CoachReview/CoachReview"
import CoacheeReview from "./CoacheeReview/CoacheeReview"
import { Data } from "../Analytics/mocData"
import { ReactComponent as LeftArrow } from "./left_arrow.svg"
import { ReactComponent as Location } from "./location.svg"
import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import { DateTime } from "luxon"
import moment from "moment"

const CoachingSession = () => {
  const navigate = useNavigate()
  const { id, profileId } = useParams()
  const [listItems, setListItems] = useState()
  const [coacheeAndCoachList, setCoacheeAndCoachList] = useState()
  const [Filteredsession, setFilteredsession] = useState()
  const [getdatalLoading, setGetDataloading] = useState(false)
  const [reviewLoading, setReviewLoading] = useState(false)
  const [coacheesSessionLoading, setCoacheesSessionLoading] = useState(false)
  const [coachDetail, setCoachdetail] = useState()

  const items = [
    {
      key: "1",
      label: `Coach Review`,
      children: <CoachReview data={coacheeAndCoachList?.coach_review} />
    },
    {
      key: "2",
      label: `Coachee Review`,
      children: <CoacheeReview data={coacheeAndCoachList?.coachee_review} />
    }
  ]

  const convertBackendDataToUserTimeZone = backendData => {
    // Convert backend data to the UK/London time zone (UTC)
    const backendDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    )

    // Convert to the UK/London time zone with the desired UTC offset (+02:00)
    const userTime = backendDateTime.setZone("America/New_York")

    // Calculate end_time based on backend.end_time
    const endDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    )

    // Convert end_time to the UK/London time zone with the desired UTC offset (+02:00)
    const userEndTime = endDateTime.setZone("America/New_York")

    // Format the UTC offset
    const formattedUtcOffset = "-04:00"

    // Return the converted data
    return {
      ...backendData,
      date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"), // Use userEndTime
      utc_offset: formattedUtcOffset
    }
  }

  const reviewCoachandCoachee = async () => {
    try {
      setReviewLoading(true)
      const response = await BaseService.get(
        `/api/v1/clients/employees/session/reviews/${id}/`
      )
      if (response?.status === 200) {
        setCoacheeAndCoachList(response?.data)
        setReviewLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const employeeEngagmentInfo = async () => {
    try {
      const response = await BaseService.get(
        `api/v1/clients/employees/engagement_info/${profileId}/`
      )

      if (response?.status === 200) {
        setListItems(prevListItems => ({
          ...prevListItems,
          engagmentinfo: response?.data?.id
        }))
        getCoach(response?.data?.coach)

        // console.log(response?.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const employeeSession = async () => {
    try {
      setCoacheesSessionLoading(true)
      const response = await BaseService.get(
        `api/v1/clients/employees/sessions/${listItems?.engagmentinfo}/`
      )
      if (response?.status === 200) {
        let ChangedData = response?.data?.map(convertBackendDataToUserTimeZone)

        let filtersession = ChangedData?.find(item => item?.id == id)
        setFilteredsession(filtersession)

        setCoacheesSessionLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDetails = async () => {
    try {
      setGetDataloading(true)
      const response = await BaseService.get(`/api/v1/coachees/`, true)
      const filter = response?.data?.find(item => item?.id == profileId)

      setListItems(filter)
      setGetDataloading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getCoach = async id => {
    try {
      // setIsCoachesLoading(true);
      const response = await BaseService.get(`api/v1/coaches/${id}/`)
      if (response?.status === 200) {
        setCoachdetail(response?.data)
      }
      // setIsCoachesLoading(false);
    } catch (error) {
      Error(error?.detail)
    }
  }

  useEffect(() => {
    if (!listItems?.engagmentinfo) return
    employeeSession()
  }, [listItems])

  useEffect(() => {
    if (!id && !profileId) return
    reviewCoachandCoachee()
    employeeEngagmentInfo()
  }, [])

  useEffect(() => {
    getDetails()
    // getCoach();
  }, [])

  // const handleDelete = async (id) => {
  //   setisDeleteLoading(true);
  //   try {
  //     const response = await BaseService.remove(
  //       `/api/v1/coachees/${id}/`,
  //       true
  //     );
  //     if (response?.status === 204) {
  //       getEmployees();
  //     }
  //   } catch (error) {
  //     Error(error);
  //   } finally {
  //     setisDeleteLoading(false);
  //   }
  // };

  // useEffect(() => setListItems(allLists), []);

  // const handleChange = (e) => {
  //   if (e.target.value.length >= 1) {
  //     setisShown(true);
  //   }
  //   setInpVal(e.target.value);
  // };

  // const handleSearch = (value) => {
  //   const copyItems = [...listItems];
  //   let finalReports = copyItems?.filter((item) =>
  //     item?.first_name?.toLowerCase().includes(value?.toLowerCase())
  //   );
  //   if (value?.length === 0) {
  //     setListItems(allLists);
  //   } else {
  //     setListItems(finalReports);
  //   }
  // };

  const onChange = key => {
    console.log(key)
  }

  return (
    <Container>
      {(getdatalLoading && <Loading />) ||
        (coacheesSessionLoading && <Loading />) ||
        (reviewLoading && <Loading />)}
      <Content>
        <HeadingContent>
          <LeftArrow onClick={() => navigate(-1)} />
          <Div>
            <Heading> Coaching Session</Heading>
            <Span>
              {" "}
              {` ${moment(Filteredsession?.session_date).format(
                "MMMM D, YYYY"
              )} ${
                Filteredsession?.session_date &&
                Filteredsession?.start_time.slice(0, -2)
              } ${
                Filteredsession?.session_date &&
                Filteredsession?.start_time.slice(5, 8)
              } - ${Filteredsession?.end_time.slice(0, -2)} ${
                Filteredsession?.session_date &&
                Filteredsession?.end_time.slice(5, 8)
              }`}
            </Span>
          </Div>
        </HeadingContent>
        {/* <Searchcontainer>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
            className="search_bar"
            value={inpVal}
            onChange={(e) => {
              handleChange(e);
              handleSearch(e?.target?.value, listItems);
            }}
            style={{ borderRadius: "32px" }}
          />
          <br />
          <BtnContent>
           
            <Button isBorder>Add Employee</Button>
          </BtnContent>
        </Searchcontainer> */}
      </Content>
      <Outlet />
      <TabWrapper>
        <Row>
          <Col span={16}>
            <ProfileSection>
              <ProfileImage
                src={
                  listItems?.profile_picture_url
                    ? listItems?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                alt=""
              />
              <ProfileDetails>
                <Name>
                  {listItems &&
                    `${listItems.first_name} ${listItems.last_name}`}
                </Name>
                <SubTitle>{listItems?.title}</SubTitle>
                <ProfileLocation>
                  <Location />
                  {listItems?.city || "No City"}
                </ProfileLocation>
              </ProfileDetails>
            </ProfileSection>
          </Col>
          <Col span={7}>
            <CoachSection>
              <CoachImage
                src={
                  coachDetail?.profile_picture_url
                    ? coachDetail?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
              />
              <CoachDetials>
                <CoachName>
                  {" "}
                  {coachDetail &&
                    `${coachDetail.first_name} ${coachDetail.last_name}`}
                </CoachName>
                <SubTitle>Coach</SubTitle>
              </CoachDetials>
            </CoachSection>
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </TabWrapper>
    </Container>
  )
}

export default CoachingSession
const Container = styled.div`
  height: 100vh;
  background-color: #ffffff;
  overflow-y: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e1dfdf;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 10vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`

const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 2rem;

  svg {
    margin-bottom: 0.3rem;
  }
`

const EditButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem;
`
const Btn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  font-family: "Myriad-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.dark};
  cursor: pointer;
`

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.374px;
  margin: 0;
  color: ${({ theme }) => theme.textDark};
  display: flex;
  flex-direction: column;
  gap: 1.55rem;
`
const Span = styled.span`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145.23%;
  display: flex;
  align-items: center;
  color: #838181;
`
const Searchcontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BtnContent = styled.div`
  width: 210px;
  margin-left: 1.2rem;
`

const TabWrapper = styled.div``
const ProfileSection = styled.div`
  padding: 1.7rem 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.7rem;
`
const ProfileImage = styled.img`
  height: 78px;
  width: 78px;
  border-radius: 50%;
`
const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
`
const Name = styled.p`
  font-family: "Myriad-Regular";
  color: #2b2b2b;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
  margin: 0;
`
const CoachSection = styled.div`
  border: 1px solid rgba(151, 173, 182, 0.2);
  width: 330px;
  height: 68px;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  padding: 0rem 0.5rem;
`
const CoachImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
`
const CoachDetials = styled.div``
const CoachName = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145.23%;
  margin: 0;
  display: flex;
  align-items: center;

  color: #2b2b2b;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: #5b5b5b;
  margin: 0;
`
const ProfileLocation = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.24px;
  color: #5b5b5b;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
`

const Div = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2rem;
`
