import PicUploadModal from "Components/ProfilePicUploadModal/PicUploadModal"
// import AvatarDropdown from "Elements/AvatarDropdown/AvatarDropdown";
import Button from "Elements/Button/Button"
import Input from "Elements/Input/Input"
import React, { Fragment, useEffect, useRef, useState, useContext } from "react"
import { CoacheeProfileSchema } from "Schemas"
import styled from "styled-components"
import { BaseService } from "API/base"
import { Form, Formik } from "formik"
import { Error } from "Responses/Response"
import { getCookie, setCookie } from "Utils/StorageVariables"
// import { Select } from "antd"
import AddEmployesModal from "Components/AddEmployesModal/AddEmployesModal"
import { ReactComponent as ProfileAvatar } from "./profile_avatar.svg"
import { ReactComponent as Employee } from "./employees.svg"
import { Select } from "antd"
import { GlobalStateContext } from "GlobalStateContext"
import { stateList } from "Utils/StorageVariables"
import Loading from "Components/Loader/Loading"

const ClientProfile = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [addEmployee, setAddEmployee] = useState(false)
  const [employees, setEmployees] = useState(null)
  const userData = JSON.parse(getCookie("userData"))
  const [getClientLoading, setGetClientLoading] = useState(false)
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)
  const [getEmployeesLoading, setGetemployeesLoading] = useState(false)
  const formik = useRef()
  const [isShowImage, setisShowImage] = useState(null)

  const renderImg = values => {
    if (isShowImage) return isShowImage
    if (avatarUrl) return avatarUrl
    if (values?.profile_picture) return values?.profile_picture
  }
  var initialValues = {
    profile_picture: "",
    first_name: "",
    last_name: "",
    title: "",
    department: "",
    state: "",
    city: "",
    zip_code: ""
  }

  const setValues = data => {
    formik?.current?.setFieldValue("title", data?.title)
    formik?.current?.setFieldValue("profile_picture", data?.profile_picture_url)
    formik?.current?.setFieldValue("first_name", data?.first_name)
    formik?.current?.setFieldValue("last_name", data?.last_name)
    formik?.current?.setFieldValue("department", data?.department)
    formik?.current?.setFieldValue("email", data?.email)

    // console.log(data?.state , 'azeem')
    formik?.current?.setFieldValue("city", data?.city)
    formik?.current?.setFieldValue("zip_code", data?.zip_code)

    console.log(data?.zip_code, "hhh")
  }

  const handleSelect = (key, e) => {
    // console.log(e, "hello")
    e.preventDefault()
    if (key === "state") {
      setValues({ state: e })
    }
  }

  const getClient = async () => {
    try {
      setGetClientLoading(true)
      const response = await BaseService.get(
        `api/v1/coachees/${userData?.user_type_id}/`,
        true
      )
      setValues(response?.data)
      updateGlobalState("profile_details", response?.data)
      // setCookie("globalState", JSON.stringify(globalState));
      setGetClientLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setGetClientLoading(false)
      }, 5000) //
    }
  }
  useEffect(() => {
    getClient()
  }, [])

  const onSubmit = async values => {
    setIsLoading(true)
    try {
      const form = new FormData()
      if (typeof values?.profile_picture === "object") {
        form.append("profile_picture", values?.profile_picture)
      }
      form.append("first_name", values?.first_name)
      form.append("last_name", values?.last_name)
      form.append("title", values?.title)
      form.append("department", values?.department || "")
      form.append("email", values?.email || "")
      form.append("city", values?.city || "")
      form.append("zip_code", values?.zip_code || "")
      form.append("company_employees", values?.company_employees)

      const response = await BaseService.put(
        `api/v1/coachees/${userData?.user_type_id}/`,
        form,
        true,
        true
      )
      if (response?.status === 200) {
        // console.log(response?.data);
        setValues(response?.data)
        updateGlobalState("profile_details", response?.data)
        setisShowImage(null)
        setAvatarUrl(null)
      }
    } catch (error) {
      console.error({ error })
      Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  // const getEmployees = async () => {
  //   try {
  //     setGetemployeesLoading(true)
  //     const response = await BaseService.get(`/api/v1/coachees/`, true)

  //     setEmployees(response?.data)
  //     if (response?.status === 200) {
  //       updateGlobalState(response?.data)
  //       setCookie("globalState", JSON.stringify(globalState))
  //     }
  //     setGetemployeesLoading(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => {
  //   getEmployees()
  // }, [])
  return (
    <Fragment>
      <MainContainer>
        {(getClientLoading && <Loading />) ||
          (getEmployeesLoading && <Loading />)}
        <Header>
          <Heading>My Profile</Heading>
        </Header>
        <Screen>
          <Forms>
            <Formik
              initialValues={initialValues}
              innerRef={formik}
              validationSchema={CoacheeProfileSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, setFieldValue, handleSubmit, values }) => {
                // console.log({ values }, "kmkmkm");
                return (
                  <Form onSubmit={handleSubmit}>
                    <PicUploadModal
                      setAvatarUrl={setAvatarUrl}
                      avatarUrl={avatarUrl}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      setFieldValue={setFieldValue}
                      isShowImage={isShowImage}
                      values={values}
                      setisShowImage={setisShowImage}
                      title={"Upload Photo"}
                    />
                    <CompanyLogo onClick={() => setIsOpen(true)}>
                      {values?.profile_picture ? (
                        <UploadImageContainer>
                          <UploadedImage src={renderImg(values)} alt="avatar" />
                          <UploadLogoText>Upload Photo*</UploadLogoText>
                        </UploadImageContainer>
                      ) : (
                        <>
                          <ProfileAvatar />
                          <LogoText>Upload Photo*</LogoText>
                        </>
                      )}
                    </CompanyLogo>
                    {errors.profile_picture && touched.profile_picture ? (
                      <Span style={{ textAlign: "center" }}>
                        {errors.profile_picture}
                      </Span>
                    ) : null}
                    <InputWrapper>
                      <Input
                        id="first_name"
                        type="text"
                        label={"First Name*"}
                        value={values?.first_name}
                        placeholder={"First Name*"}
                        name="first_name"
                        onChange={event => {
                          setFieldValue("first_name", event.target.value)
                        }}
                      />
                      {errors.first_name && touched.first_name ? (
                        <Span>{errors.first_name}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        name="last_name"
                        label={"Last Name*"}
                        value={values?.last_name}
                        placeholder={"Last Name*"}
                        id="last_name"
                        onChange={event => {
                          setFieldValue("last_name", event.target.value)
                        }}
                      />
                      {errors.last_name && touched.last_name ? (
                        <Span>{errors.last_name}</Span>
                      ) : null}
                    </InputWrapper>

                    <InputWrapper>
                      <Input
                        type="text"
                        name="title"
                        label={"Title*"}
                        id="title"
                        value={values?.title}
                        onChange={event => {
                          setFieldValue("title", event.target.value)
                        }}
                        placeholder={"Title*"}
                      />

                      {errors.title && touched.title ? (
                        <Span>{errors.title}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        name="department"
                        label={"Department"}
                        id="department"
                        value={values?.department ? values?.department : ""}
                        onChange={event => {
                          setFieldValue("department", event.target.value)
                        }}
                        placeholder={"Department"}
                      />
                      {errors.department && touched.department ? (
                        <Span>{errors.department}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        placeholder={"city"}
                        name="city"
                        id="city"
                        label={"City"}
                        value={values?.city ? values?.city : ""}
                        onChange={event => {
                          setFieldValue("city", event.target.value)
                        }}
                      />
                      {errors.city && touched.city ? (
                        <Span>{errors.city}</Span>
                      ) : (
                        ""
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="number"
                        name="zip_code"
                        id="zip_code"
                        label={"Zip Code"}
                        value={
                          values?.zip_code === "null" ? null : values?.zip_code
                        }
                        onChange={event => {
                          setFieldValue("zip_code", event.target.value)
                        }}
                        placeholder={"Zip code"}
                      />
                      {errors.zip_code && touched.zip_code ? (
                        <Span>{errors.zip_code}</Span>
                      ) : (
                        ""
                      )}
                    </InputWrapper>
                    <SaveBtnWrapper>
                      <Button type="submit" isBorder disabled={isLoading}>
                        {isLoading ? "Saving..." : "Save"}
                      </Button>
                    </SaveBtnWrapper>
                  </Form>
                )
              }}
            </Formik>
          </Forms>
        </Screen>
      </MainContainer>
    </Fragment>
  )
}

export default ClientProfile

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  height: 100vh;
`
const Header = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  height: 11vh;
  border-bottom: 1px solid #dbd9d5;
  display: flex;
  align-items: center;
  padding: 0 3rem;
`
const Heading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  color: ${({ theme }) => theme.textDark};
  line-height: 41px;
  font-family: "Myriad-Regular";
`

const Screen = styled.div`
  height: calc(100vh - 8vh);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Forms = styled.div`
  background-color: #ffffff;
  width: 50%;
  padding: 2rem 4rem 2rem 4rem;
  height: auto;
  border: 1px solid #dbd9d5;
  border-radius: 20px;
  height: calc(100vh - 26vh);
  overflow-y: auto;
`

const InputWrapper = styled.div`
  margin: 10px 0;
`

const CompanyLogo = styled.div`
  cursor: pointer;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundDark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
`
const Image = styled.img`
  width: 50px;
  height: auto;
  object-fit: fill;
`
const LogoText = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.primary_dark};
  font-family: "Myriad-Regular";
`

const EmployeesContainer = styled.div``
const HeadingEmployee = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
`
const EmployeeDetailListSection = styled.div`
  height: 14vh;
`
const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NumberofEmployees = styled.p`
  padding-left: 10px;
`

const SaveBtnWrapper = styled.div`
  margin: 30px 0px 60px 0px;
`
const AddButton = styled.button`
  border-radius: 20px;
  width: 4rem;
  padding: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-family: "Myriad-SemiBold";
  margin: 0;
`

const UploadedImage = styled.img`
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
`
const UploadImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const UploadLogoText = styled.p`
  position: absolute;
  bottom: 8px;
  left: 35px;
  font-size: 14px;
  color: ${({ theme }) => theme.textWhite};
  font-weight: 600;
`
const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`
