import { useNavigate, useLocation } from "react-router-dom";
import { setCookie } from "Utils/StorageVariables";

export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname !== "/login") {
    setCookie("redirect", `${window.location.origin}${location.pathname}`);
  }
  const Navigate = (route) => {
    navigate(route);
  };
  return { Navigate };
};
