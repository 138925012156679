import ProgressBar from "Elements/ProgressBar/ProgressBar";
import React, { useState } from "react";
import styled from "styled-components";

const EngagementStatus = ({ data }) => {
  console.log(data, "hhh");
  const [aggregateData, setAggregateData] = useState(data)

  const AggregatePercentage = data => {
    if (data?.total_sessions_allotted_for_all_employees === 0) {
      return 0
    }
    let percentage =
      (data?.total_sessions_completed /
        data?.total_sessions_allocated) *
      100

    percentage = Math.max(0, Math.min(100, percentage))

    return percentage
  }

  const width = AggregatePercentage(aggregateData)
  console.log(width)
  return (
    <Container>
      <SubHeading>Total Sessions Allotted </SubHeading>
      <Progress>
        <ProgressBar width={`${width}%`} color={"#88B3E7"} />
        <Persentage>{data?.total_sessions_allocated}</Persentage>
      </Progress>
      <UL>
        <LI>
          Total Sessions Allotted: <Span>{data?.total_sessions_allocated}</Span>{" "}
        </LI>
        <LI>
          Total Sessions Completed:<Span>{data?.total_sessions_completed}</Span>
        </LI>
        <LI>
          Total Sessions Remaining:<Span>{data?.total_sessions_remaining}</Span>
        </LI>
      </UL>
    </Container>
  );
};

export default EngagementStatus;
const Container = styled.div`
  padding: 1rem 2.2rem;
`;
const SubHeading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  margin: 0;
`;
const Progress = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 55%;
`;
const Persentage = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`;
const UL = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.8rem;
`;
const LI = styled.li`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`;
const Span = styled.span`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2b2b2b;
`;
