import React from "react";
import styled from "styled-components";
// import { useFormik } from "formik";
// import { LoginSchemas } from "Schemas";
import Button from "Elements/Button/Button";
import OtpInput from "react-otp-input";
import { LeftOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseService } from "API/base";

const Optcode = () => {
  const navigate = useNavigate();
  const [buttondisabled, setButtondisabled] = useState(true);
  const [otp, setOTP] = useState("");

  const handleOTPChange = (otp) => {
    setOTP(otp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e);
    // console.log({ otp }, "otp val");
    try {
      const response = BaseService.post(
        "rest-auth/registration/verify",
        { key: otp },
        false
      );
      if (response?.status === 200) {
        console.log({ response });
      }
    } catch (error) {
      console.error({ error });
      alert("Login failed");
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!otp) return;
    if (otp.length >= 6) return setButtondisabled(false);
    return setButtondisabled(true);
  }, [otp]);

  return (
    <>
      <Main>
        <MainContainer>
          <Logocontainer>
            <Logo src="/Assets/Group 462.svg" alt="" />
          </Logocontainer>
          <Form onSubmit={handleSubmit}>
            <InputField>
              <BtnContent>
                <Forgetbtn>
                  {" "}
                  <LeftOutlined
                    onClick={() => navigate("/forget-password")}
                    style={{
                      fontSize: "1.2rem",
                      marginRight: "1rem",
                      fontWeight: "border",
                    }}
                  />
                </Forgetbtn>
                <Heading>Check your Email</Heading>
              </BtnContent>

              <InputWrapper>
                <OtpContent>
                  <OtpContainer>
                    <CustomOptInput
                      value={otp}
                      onChange={(value) => handleOTPChange(value)}
                      numInputs={6}
                      // separator={<span>-</span>}
                      isInputNum={true}
                      errorStyle={{
                        border: "1px solid #ff333a",
                        color: "#ff333a",
                      }}
                      hasErrored={false}
                    />
                  </OtpContainer>
                </OtpContent>
              </InputWrapper>
              <Buttonwrapper>
                <Button isBorder type={"submit"} disabled={buttondisabled}>
                  Verify
                </Button>
              </Buttonwrapper>
              <Description>
                Did not receive the OTP? <Tag href="#">Resend</Tag>
              </Description>
              {/* <Time>00:59</Time> */}
            </InputField>
          </Form>
        </MainContainer>
      </Main>
    </>
  );
};

export default Optcode;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div``;
const Logocontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;

const Logo = styled.img``;

const Form = styled.form`
  width: 458px;
  min-height: 368px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid green;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 24px;
`;

const InputField = styled.div`
  width: 80%;
  padding: 1.2rem;
`;

const BtnContent = styled.div`
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  width: 100%;
`;

const Forgetbtn = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 2rem;
  margin-left: 1rem;
`;

const Description = styled.p`
  text-align: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textDark};
  margin-top: 1.2rem;
`;

// const Time = styled.p`
// text-align: center;
// `

const Tag = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textDark};
  text-decoration: none;
`;

const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`;

const InputWrapper = styled.div`
  margin-bottom: 1.2rem;
`;

const Buttonwrapper = styled.div`
  margin-top: 1.5rem;
`;

const OtpContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  input {
    margin: 0rem 0.42rem;
    padding: 0.65rem;
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.textDark};
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.OptBorderDark};
    outline: none;
    font-size: 1.1rem;

    &:focus {
      border-bottom: 2px solid ${({ theme }) => theme.OptBorderlight};
    }
  }
`;

const OtpContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const CustomOptInput = styled(OtpInput)``;
