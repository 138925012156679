import { BaseService } from "API/base"
import AppRoutes from "AppRoutes/AppRoutes"
import { useThemeMode } from "Hooks/themeColor"
import { useEffect, useState } from "react"
import { BrowserRouter as Router, useNavigate } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import { getCookie, removeAllCookie, setCookie } from "Utils/StorageVariables"
import { messaging } from "./firebase"
import { getToken } from "firebase/messaging"
import { useContext } from "react"
import { GlobalStateContext } from "GlobalStateContext"

function App() {
  const [isToken, setIsToken] = useState()
  const [isCalled, setIsCalled] = useState(false)

  const requestPermission = async () => {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BIk2H1RAEHLUzHuUPqzwRWreQfs7u-iA1ENzHxzB_w7ipLGIQpwfcZXFcYvRs5uSj3ZyCg6WdJa0j6UCbKUTH9U"
      })
      console.log(token)
      setIsToken(token)

      // console.log("token", token)
    } else if (permission === "denied") {
    }
  }

  const registerFcm = async token => {
    try {
      const response = await BaseService.post(
        `/api/v1/store_fcm_token/${token}`
      )
    } catch (error) {
      Error(error?.response?.data?.detail)
    } finally {
      setIsCalled(true)
    }
  }

  useEffect(() => {
    requestPermission()
  }, [])

  useEffect(() => {
    if (isCalled) return
    if (!isToken) return
    return registerFcm(isToken)
  }, [isToken])

  const { theme } = useThemeMode()
  useEffect(() => {
    // let data = {
    //   id: 0,
    //   email: "user@example.com",
    //   username: "LsrTZolXbAbN-zX9UjTd",
    //   first_name: "string",
    //   last_name: "string",
    //   user_type: "Coach",
    //   is_new_user: true,
    //   user_type_id: "string",
    // };
    // setCookie("userData", JSON.stringify(data));
    // setCookie("accessToken", "eff0dd6bff9811f1e54b44dc4a5d729d3c898861");
  }, [])
  const [getCoachLoading, setGetCoachLoading] = useState(false)
  const userData = JSON.parse(getCookie("userData"))
  const getCoach = async () => {
    try {
      setGetCoachLoading(true)
      const response = await BaseService.get(
        `api/v1/coaches/${userData?.user_type_id}/`,
        true
      )
      // console.log(response?.data, "hhhhhhhhhhh");
      setGetCoachLoading(false)
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        removeAllCookie()
      }
    }
  }

  // const getNotification = async () => {
  //   try {
  //     const response = await BaseService.get(
  //       `/api/v1/notifications/history/`,
  //       true
  //     )

  //     setCookie("noti", JSON.stringify(response?.data))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // useEffect(() => {
  //   getNotification()
  // }, [])

  return (
    <>
      <Router>
        <>
          <ThemeProvider theme={theme}>
            <AppRoutes />
          </ThemeProvider>
        </>
      </Router>
    </>
  )
}

export default App
