import React, { useContext, useEffect, useState } from "react"
import Participant from "./Participant"
import styled from "styled-components"
import { ReactComponent as Audio } from "./Audio.svg"
import { ReactComponent as VideoIcon } from "./Video.svg"
import { ReactComponent as VideoHiddenIcon } from "./videoHidden.svg"
import { ReactComponent as AudioMuteIcon } from "./AudioMute.svg"
import { ReactComponent as RotateCamera } from "./roatateCamera.svg"
import { ReactComponent as DisConnectCal } from "./diconnectCal.svg"
import { ReactComponent as ParticpantAudio } from "./partispantAudioHidden.svg"

import html2canvas from "html2canvas"
import { useNavigate } from "react-router-dom"
import { getCookie } from "Utils/StorageVariables"
import { Error, Success } from "Responses/Response"
import { GlobalStateContext } from "GlobalStateContext"

const Room = props => {
  const { globalState, updateGlobalState, clearGlobalState } =
    useContext(GlobalStateContext)

  const navigate = useNavigate()
  const [remoteParticipants, setRemoteParticipants] = useState([])
  const [VideoTrackPartispant, setVideoTrackParticipant] = useState(true)
  const [AudioTrackPartispant, setAudioTrackPartispant] = useState(true)

  const [disConnecting, setDisConnecting] = useState(false)
  const localPartispant = JSON.parse(getCookie("localPartispant"))
  const remotePartispant = JSON.parse(getCookie("remotePartispant"))
  const [connectionLost, setConnectionLost] = useState(false)
  const userData = JSON.parse(getCookie("userData"))

  useEffect(() => {
    setRemoteParticipants(Array.from(props.room.participants.values()))

    const handleParticipantConnected = participant => {
      setDisConnecting(false)
      setRemoteParticipants(prevParticipants => [
        ...prevParticipants,
        participant
      ])
      const { tracks } = participant
      updateTracksState(tracks)
    }

    const handleParticipantDisconnected = participant => {
      setDisConnecting(true)
      setRemoteParticipants(prevParticipants =>
        prevParticipants.filter(p => p.identity !== participant.identity)
      )
      const { tracks } = participant
      updateTracksState(tracks)
    }

    const updateTracksState = tracks => {
      tracks.forEach(track => {
        if (track.kind === "video") {
          console.log(track.isTrackEnabled)
          setVideoTrackParticipant(track.isTrackEnabled)
        } else if (track.kind === "audio") {
          setAudioTrackPartispant(track.isEnabled)
          console.log(track, "audio")
        }
      })
    }

    const eventListeners = [
      {
        eventName: "participantConnected",
        callback: handleParticipantConnected
      },
      {
        eventName: "participantDisconnected",
        callback: handleParticipantDisconnected
      },
      { eventName: "reconnecting", callback: handleReconnecting },
      { eventName: "reconnected", callback: handleReconnected }
    ]

    eventListeners.forEach(({ eventName, callback }) => {
      props.room.on(eventName, callback)
    })

    return () => {
      eventListeners.forEach(({ eventName, callback }) => {
        props.room.off(eventName, callback)
      })
    }
  }, [props.room, props.leaveRoom])

  const handleReconnecting = error => {
    if (error.code === 53001) {
      Error(error.message)
    } else if (error.code === 53405) {
      setConnectionLost(true)
      Error("Connection issue")
    }
  }

  const handleReconnected = () => {
    Success("Connection restored")
    setConnectionLost(false)
  }

  const toggleAudio = () => {
    const localAudioTrack = props?.room?.localParticipant?.audioTracks
      ?.values()
      .next().value?.track
    if (localAudioTrack) {
      localAudioTrack.isEnabled
        ? localAudioTrack.disable()
        : localAudioTrack.enable()
      updateGlobalState("isAudio", localAudioTrack.isEnabled)
    }
  }

  const toggleVideo = () => {
    const localVideoTrack = props?.room?.localParticipant?.videoTracks
      ?.values()
      .next().value?.track
    if (localVideoTrack) {
      localVideoTrack.isEnabled
        ? localVideoTrack.disable()
        : localVideoTrack.enable()

      updateGlobalState("isVideo", localVideoTrack.isEnabled)
    }
  }

  const takeScreenshot = () => {
    html2canvas(document.body).then(canvas => {
      const link = document.createElement("a")
      link.href = canvas.toDataURL()
      link.download = "screenshot.png"
      link.click()
    })
  }

  const leaveRoom = () => {
    props.room.disconnect()
    props.leaveRoom()
    clearGlobalState()

    // updateGlobalState(  "isVideo", null);
    navigate(
      userData?.user_type === "Coach"
        ? "/app/coach/calendar/coach-calender"
        : "/app/coachee/coaches/Calender"
    )
  }

  return (
    <>
      <Container>
        <RoomVideoWrapper>
          {globalState?.isVideo ? (
            <>
              <Participant
                key={props?.room?.localParticipant?.identity}
                localParticipant="true"
                participant={props?.room?.localParticipant}
              />
              {!globalState?.isAudio && (
                <IconWrapper>
                  <ParticpantAudio />
                </IconWrapper>
              )}
            </>
          ) : (
            <VideoHiddenWrapper>
              <ProfileAvatar
                src={
                  localPartispant?.profile_picture_url
                    ? localPartispant?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
              />
              {!globalState?.isAudio && <Text>You Are Muted</Text>}
            </VideoHiddenWrapper>
          )}
        </RoomVideoWrapper>
        <ItemWrapper>
          <VideoWrapper>
            {remoteParticipants?.length !== 0 ? (
              remoteParticipants?.map(participant => (
                <>
                  <Wrapper>
                    <Participant
                      key={participant?.identity}
                      participant={participant}
                      VideoTrackPartispant={VideoTrackPartispant}
                      setVideoTrackParticipant={setVideoTrackParticipant}
                      AudioTrackPartispant={AudioTrackPartispant}
                      setAudioTrackPartispant={setAudioTrackPartispant}
                    />
                    <BottomHeadingName>
                      {remotePartispant?.first_name}{" "}
                      {remotePartispant?.last_name}
                      {!AudioTrackPartispant && <ParticpantAudio />}
                    </BottomHeadingName>
                  </Wrapper>
                  {!VideoTrackPartispant && (
                    <AudioContainerPartispant>
                      <ProfileWrapper>
                        <ProfileImageIcon
                          src={
                            remotePartispant?.profile_picture_url
                              ? remotePartispant?.profile_picture_url
                              : ` https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg`
                          }
                        />
                      </ProfileWrapper>
                      <BottomHeading>
                        {remotePartispant?.first_name}{" "}
                        {remotePartispant?.last_name}
                        {!AudioTrackPartispant && <ParticpantAudio />}
                      </BottomHeading>
                    </AudioContainerPartispant>
                  )}
                  {connectionLost && (
                    <ConnectionLostContainer>
                      <ConnectionHeading>
                        Connection Issue{" "}
                        {Array?.from({ length: props.progress }, (_, index) => (
                          <span key={index}>.</span>
                        ))}
                      </ConnectionHeading>
                    </ConnectionLostContainer>
                  )}
                </>
              ))
            ) : (
              <ConnectingContainer>
                <Heading>
                  Waiting for Participant{Array?.from({ length: props.progress }, (_, index) => (
                    <span key={index}>.</span>
                  ))}
                </Heading>
              </ConnectingContainer>
            )}
          </VideoWrapper>
        </ItemWrapper>

        <ButtonsWrapperVideo>
          {" "}
          <AudioButton onClick={toggleVideo}>
            {globalState?.isVideo ? <VideoIcon /> : <VideoHiddenIcon />}
          </AudioButton>
          <AudioButton onClick={toggleAudio}>
            {globalState?.isAudio ? <Audio /> : <AudioMuteIcon />}
          </AudioButton>
          <AudioButton onClick={takeScreenshot}>
            <RotateCamera />
          </AudioButton>
          <AudioButton onClick={leaveRoom}>
            <DisConnectCal />
          </AudioButton>
        </ButtonsWrapperVideo>
      </Container>
      {/* )} */}
    </>
  )
}

export default Room

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 0.7rem;
  position: relative;
`
const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 24px;
  height: 69.5vh !important;
  video {
    height: 69.5vh !important;
  }
`
const RoomVideoWrapper = styled.div`
  /* margin-top: 1rem; */
  transform: translateY(3vh);
  border-radius: 20px;
  height: 200px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 13.2%;
  z-index: 9999;

  video {
    height: 200px !important;
  }
`
const ButtonsWrapperVideo = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.7rem 0rem;
  border-radius: 48px;
  background: #222;
  width: 329px;
  /* height: 86px; */
  margin-bottom: 0.5rem;
`
const AudioButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`
const VideoHiddenWrapper = styled.div`
  border-radius: 24px;
  background: #252525;
  width: 266.66px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  /* margin-top: 0.5rem; */
`

const ProfileAvatar = styled.img`
  width: 88px;
  height: 88px;
  border-radius: 50%;
`
const Text = styled.p`
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.227%;
`
const AudioContainerPartispant = styled.div`
  width: 100%;
  height: 69.9vh;
  background: #2e2e2e;
  border-radius: 24px;
  position: absolute;
  top: 0;
`
const ConnectionLostContainer = styled.div`
  width: 100%;
  height: 69.8vh;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 24px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ItemWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProfileWrapper = styled.div`
  height: 66vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProfileImageIcon = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 50%;
`
const BottomHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%;
  margin-left: 2.5rem;
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  transform: translateY(-2rem);
`
/* isVideoEnabled={participant.videoTracks.size > 0 && participant.videoTracks.values().next().done !== true} */
const ConnectingContainer = styled.div`
  width: 100%;
  height: 69.8vh;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
`
const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 113.889% */
  letter-spacing: 0.374px;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 75%;
`

const ConnectionHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 113.889% */
  letter-spacing: 0.374px;
`

const BottomHeadingName = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 145.227%;
  margin-left: 2.5rem;
  text-transform: capitalize;
  position: absolute;
  bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const IconWrapper = styled.div`
  z-index: 99999;
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
`
