import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ReactSVG } from "react-svg"
import styled from "styled-components"
import { activeTabHandler, validateURL } from "Utils/Reusable-Functions"
import { getCookie, removeAllCookie, setCookie } from "Utils/StorageVariables"
import { userTypes } from "Utils/Common"
import LogoutModal from "Components/LogoutModal/LogoutModal"
import { BaseService } from "API/base"
import { ReactComponent as Analytics } from "./analytics.svg"
import { ReactComponent as Calender } from "./calender.svg"
import { ReactComponent as Employees } from "./employees.svg"
import { ReactComponent as Notification } from "./notifications.svg"
import { ReactComponent as Setting } from "./Setting.svg"
import { ReactComponent as SloanLogo } from "./sloan_logo.svg"
import { GlobalStateContext } from "GlobalStateContext"
import Loading from "Components/Loader/Loading"
const SideNav = () => {
  const [isLogout, setisLogout] = useState(false)
  const [profileDetails, setProfileDetails] = useState(null)
  const [getCoachLoading, setGetCoachLoading] = useState(false)
  const [getClientLoading, setGetClientLoading] = useState(false)

  const { globalState, updateGlobalState } = useContext(GlobalStateContext)

  // console.log(globalState, "global");

  const location = useLocation().pathname

  const navigate = useNavigate()
  let userData = JSON.parse(getCookie("userData"))

  const getCoach = async () => {
    try {
      setGetCoachLoading(true)
      const response = await BaseService.get(
        `api/v1/coaches/${userData?.user_type_id}/`,
        true
      )
      // updateGlobalState("profile_details", response?.data);
      setProfileDetails(response?.data)

      setGetCoachLoading(false)
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        removeAllCookie()
      }
    }
  }
  const getCoachees = async () => {
    try {
      setGetCoachLoading(true)
      const response = await BaseService.get(
        `api/v1/coachees/${userData?.user_type_id}/`,
        true
      )
      // updateGlobalState("profile_details", response?.data);
      setProfileDetails(response?.data)
      // setCookie("globalState", JSON.stringify(globalState));

      setGetCoachLoading(false)
    } catch (error) {
      console.log(error)
      if (error?.response?.status === 401) {
        removeAllCookie()
      }
    }
  }
  const getClient = async () => {
    try {
      setGetClientLoading(true)
      const response = await BaseService.get(
        `api/v1/clients/${userData?.user_type_id}/`,
        true
      )
      // updateGlobalState("profile_details", response?.data);
      setProfileDetails(response?.data)
      // setCookie("globalState", JSON.stringify(globalState));

      setGetClientLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (userData?.user_type === userTypes?.client) {
      getClient()
    } else if (userData?.user_type === userTypes?.coachee) {
      getCoachees()
    } else if (userData?.user_type === userTypes?.coach) {
      getCoach()
    }
  }, [])

  // const [getNotificationloading, setGettNotificationLoading] = useState(false)

  const getNotification = async () => {
    try {
      // setGettNotificationLoading(true)
      const response = await BaseService.get(
        `/api/v1/notifications/history/`,
        true
      )

      updateGlobalState("notifications", response?.data)
      updateGlobalState("profile_details", profileDetails)
      // setnotificationData(filteredItem)
    } catch (error) {
      console.log(error)
    } finally {
      // setGettNotificationLoading(false)
    }
  }
  useEffect(() => {
    getNotification()
  }, [])

  // console.log(notificationData, "notificationdataarray")
  // console.log(globalState, "global data");
  // console.log(profileDetails, "profile");

  const sideNavClientRoutes = [
    {
      logo: <Analytics />,
      title: "Analytics",
      route: "analytics",
      path: "client/analytics",
      splitRange: 3,
      role: "Client"
    },
    {
      logo: <Employees />,
      title: "My Employees",
      route: "my-employees",
      path: "client/my-employees",
      splitRange: 3,
      role: "Client"
    },
    {
      logo: <Calender />,
      title: "Calendar",
      route: "coach-calender",
      path: "coach/calendar/coach-calender",
      splitRange: 4,
      role: "Coach"
    },

    {
      logo: <Employees />,
      title: "My Coachees",
      route: "my-coachees",
      path: "coach/my-coachees",
      splitRange: 3,
      role: "Coach"
    },
    {
      logo: <Calender />,
      title: "Home",
      route: "coaches", // Use the "coachee" route as the route identifier
      path: "coachee/coaches", // Use the "coachee" path
      splitRange: 3,
      role: "Coachee"
    },
    {
      logo: <Notification />,
      title: "Notifications",
      route: "notification",
      path: "notification",
      splitRange: 2,
      role: ""
    },

    {
      logo: <Setting />,
      title: "Settings",
      route: "settings",
      path: "settings",
      splitRange: 2,
      role: ""
    }
  ]
  // console.log({ profileDetails });

  // console.log(sideNavClientRoutes, "harim");

  // console.log(userData?.user_type, "data");
  // console.log(location, "path");
  return (
    <MainContainer>
      {/* {getNotificationloading && <Loading />} */}
      {/* {(getCoachLoading && <Loading />) || (getClientLoading && <Loading />)} */}
      <InnerContainer>
        <LogoContainer>
          {/* <Image width={"100px"} src="/Assets/sloan_logo.svg" alt="logo" /> */}
          <SloanLogo />
          <Heading>SLOAN LEADERS</Heading>
        </LogoContainer>

        <>
          <MyProfileContainer
            onClick={() => {
              if (userData?.user_type === userTypes?.client) {
                navigate("client/profile")
              } else if (userData?.user_type === "Coachee") {
                navigate("coachee/profile") // Redirect to coachee/profile for Coachee user_type
              } else if (userData?.user_type === "Coach") {
                navigate("coach/profile")
              }
            }}
            status={activeTabHandler(
              validateURL(location, "/", 3),
              "profile",
              true,
              false
            )}
          >
            <ProfileSection>
              <Image
                isRound
                width={"58px"}
                src={
                  globalState?.profile_details?.profile_picture_url ||
                  profileDetails?.profile_picture_url
                    ? globalState?.profile_details?.profile_picture_url ||
                      profileDetails?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                alt="profile-logo"
              />

              <ProfileDetails>
                <h2>
                  {userData?.user_type === userTypes?.client
                    ? globalState?.profile_details?.client_name ||
                      profileDetails?.client_name
                    : `${
                        globalState?.profile_details?.first_name ||
                        profileDetails?.first_name
                      } ${
                        globalState?.profile_details?.last_name ||
                        profileDetails?.last_name
                      }`}
                </h2>
                <h4>My Profile</h4>
              </ProfileDetails>
            </ProfileSection>
          </MyProfileContainer>
        </>

        <LinkContainer>
          {sideNavClientRoutes?.map(item => (
            <>
              {item?.role === userData?.user_type || item?.role === "" ? (
                <NavigationLink
                  onClick={() => navigate(item?.path)}
                  status={activeTabHandler(
                    validateURL(location, "/", item?.splitRange),
                    item?.route,
                    true,
                    false
                  )}
                >
                  <LinkImage
                    status={activeTabHandler(
                      validateURL(location, "/", item?.splitRange),
                      item?.route,
                      true,
                      false
                    )}
                    src={item?.logo}
                    alt="logo"
                  />
                  <IconWrapper
                    status={activeTabHandler(
                      validateURL(location, "/", item?.splitRange),
                      item?.route,
                      true,
                      false
                    )}
                  >
                    {item?.logo}
                  </IconWrapper>
                  <LinkText
                    status={activeTabHandler(
                      validateURL(location, "/", item?.splitRange),
                      item?.route,
                      true,
                      false
                    )}
                  >
                    {item?.title}
                  </LinkText>
                  {item?.title === "Notifications" &&
                  globalState?.notifications?.filter(item => !item.is_read)
                    .length > 0 ? (
                    <NotificationIcon
                      status={activeTabHandler(
                        validateURL(location, "/", item?.splitRange),
                        item?.route,
                        true,
                        false
                      )}
                    >
                      <NotiHeading>
                        {" "}
                        {
                          globalState?.notifications?.filter(
                            item => !item.is_read
                          ).length
                        }
                      </NotiHeading>
                    </NotificationIcon>
                  ) : null}
                </NavigationLink>
              ) : null}
            </>
          ))}
        </LinkContainer>
      </InnerContainer>
      <LogoutModal isLogout={isLogout} setisLogout={setisLogout} />
    </MainContainer>
  )
}

export default SideNav
const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  padding: 1rem 0;
  width: 24rem;
  height: auto;
  border-right: 1px solid rgba(194, 193, 189, 0.57);
`

const Heading = styled.h2`
  margin-top: 10px;
  font-size: 20px;
  color: ${({ theme }) => theme.textDark};
  font-style: normal;
  font-weight: 400;
  font-family: "Myriad-Regular";
`

const InnerContainer = styled.div`
  /* padding: 0.5rem; */
`

const LinkContainer = styled.div`
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.backgroundDark};
  cursor: pointer;
`

const NavigationLink = styled.div`
  background-color: ${({ status, theme }) =>
    status ? theme.primary : theme.backgroundLight} !important;
  font-family: "Myriad-Regular";
  font-size: 0.938rem;
  text-decoration: none;
  display: flex;
  line-height: 20px;
  letter-spacing: 0;
  align-items: center;
  padding: 15px 45px;
  height: 55px;
  padding-top: 1.2rem;
  position: relative;
  /* justify-content: center; */
  /* border: 1px solid red; */
`
const LinkImage = styled(ReactSVG)`
  /* width: 44px; */
  height: auto;
  path {
    fill: ${({ status, theme }) => (status ? theme.textDark : theme.textLight)};
  }
`
const IconWrapper = styled.div`
  /* width: 44px; */
  height: auto;
  path {
    fill: ${({ status, theme }) => (status ? theme.textDark : theme.textLight)};
  }
`
const LinkText = styled.div`
  margin: 0;
  padding: 0;
  color: ${({ status, theme }) => (status ? theme.textDark : theme.textLight)};
  font-size: 20px;
  font-weight: 400;
  display: flex;

  line-height: 21px;
  white-space: nowrap;
  padding-left: 20px;
`

const LogoContainer = styled.div`
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const Image = styled.img`
  width: 58px;
  height: 58px;
  border-radius: ${({ isRound }) => (isRound ? "50%" : "0%")};
  /* margin-left: 0.2rem; */
`

const MyProfileContainer = styled.div`
  border-top: 1px solid #dbd9d5;
  border-bottom: 1px solid #dbd9d5;
  /* background: red; */
  display: flex;
  align-items: center;
  height: 90px;
  padding: 5px 45px;
  background-color: ${({ status, theme }) =>
    status ? theme.primary : theme.backgroundLight} !important;
  cursor: pointer;
`

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
`

const ProfileDetails = styled.div`
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: ${({ theme }) => theme.textDark};
    font-family: "Myriad-Regular";
  }
  h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: ${({ theme }) => theme.textMedium};
    font-family: "Myriad-Light";
  }
`
const NotificationIcon = styled.div`
  width: 23px;
  height: 23px;
  background-color: ${({ status, theme }) =>
    status ? theme.backgroundLight : theme.primary} !important;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotiHeading = styled.h5`
  color: #1c1e21;
  text-align: center;
  font-family: "Myriad-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 5px;
  margin: 0;
  padding: 0;
`
