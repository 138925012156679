import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging"


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "BDDnshDPlAqZy2OH1t9_7smH3Xkmxu6gYkjrflzNRq5GKpZqxOM93KxTdUSSalhHH_eXz8T5anWQVSGjIUCAmOo",
    authDomain: "push--notification-6906d.firebaseapp.com",
    projectId: "push--notification-6906d",
    storageBucket: "push--notification-6906d.appspot.com",
    messagingSenderId: "793596879023",
    appId: "1:793596879023:web:58734d828f3a0b1438b45a"
};
// const firebaseConfig = {
//     apiKey: "AIzaSyBo9HrWoIQSrbI-v8Q7kBKnVL4kMBQuHM8",
//     authDomain: "push--notification-6906d.firebaseapp.com",
//     projectId: "push--notification-6906d",
//     storageBucket: "push--notification-6906d.appspot.com",
//     messagingSenderId: "793596879023",
//     appId: "1:793596879023:web:58734d828f3a0b1438b45a"
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app)