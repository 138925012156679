import React from "react";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";
// import { useSelector } from "react-redux";

const Loading = () => {
  // const { loading: listLoading } = useSelector(
  //   (state) => state.listLoadingReducer
  // );
  // const isLoading = [listLoading];
  return (
    <>
      {/* {isLoading?.includes(true) && ( */}
      <LoaderContainer>
        <Oval secondaryColor="#00000083" color="#E4BF44" height={140} width={140} />
      </LoaderContainer>
      {/* )} */}
    </>
  );
};
export default Loading;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #00000049;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
`;
