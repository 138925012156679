import React, { useEffect, useState } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import moment from "moment"
import styled from "styled-components"
import "./coacheeCalender.scss"
import { BaseService } from "API/base"
import { DateTime } from "luxon"
import VideocCallModal from "Components/VideocCallModal/VideocCallModal"
import InfoModal from "Components/InfoModal/InfoModal"
import Loading from "Components/Loader/Loading"
const CoacheeSchedular = () => {
  const localizer = momentLocalizer(moment)
  const [videoRoomData, setVideoRoomData] = useState()
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const [coacheeData, setcoacheeData] = useState()
  const [roomId, setRoomId] = useState()
  const [sessionDetail, setsessionDetail] = useState()
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [sessionLoading, setSessionLoading] = useState(false)
  const [sessionArray, setSessionArrays] = useState([
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 8, 27, 12, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 27, 1, 0),
    // },
    // {
    //   title: "Meeting with Harim",
    //   start: new Date(2023, 8, 11, 2, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 11, 3, 0),
    // },
    // {
    //   title: "Meeting with Azeem",
    //   start: new Date(2023, 8, 11, 3, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 11, 4, 0),
    // },
    // {
    //   title: "Meeting with John",
    //   start: new Date(2023, 8, 11, 4, 0), // year, month (0-based), day, hour, minute
    //   end: new Date(2023, 8, 11, 5, 0),
    // },
  ])

  const convertBackendDataToUserTimeZone = backendData => {
    const backendDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    )

    const userTime = backendDateTime.setZone("America/New_York")

    const endDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    )

    const userEndTime = endDateTime.setZone("America/New_York")

    const formattedUtcOffset = "-05:00"

    return {
      ...backendData,
      date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"), // Use userEndTime
      utc_offset: formattedUtcOffset
    }
  }

  const getCalenderSession = async () => {
    setSessionLoading(true)
    try {
      const response = await BaseService.get(`api/v1/coachees/calendar/`)

      const slotsAvailable = response?.data?.map(item => {
        if (item?.sessions) {
          const sessions = item?.sessions?.map(session =>
            convertBackendDataToUserTimeZone(session)
          )

          return {
            ...item,
            sessions
          }
        }

        return item
      })
      setsessionDetail(slotsAvailable)

      const res = slotsAvailable
        ?.map(item =>
          item?.sessions?.map(session => ({
            title: `${session?.call_type} with ${item?.first_name} ${item?.last_name}`,
            start: convertToDateObject(
              session?.session_date,
              session?.start_time
            ),
            end: convertToDateObject(session?.session_date, session?.end_time),
            id: session?.id,
            call_type: session?.call_type
          }))
        )
        ?.filter(item => item?.length > 0)

      const flattenedSessions = res.flat()

      setSessionArrays(flattenedSessions)
    } catch (error) {
      Error(error?.detail)
      setSessionLoading(false)
    } finally {
      setSessionLoading(false)
    }
  }

  useEffect(() => {
    getCalenderSession()
  }, [])

  const convertToDateObject = (dateString, timeString) => {
    const dateParts = dateString.split("-")
    const timeParts = timeString.match(/(\d+):(\d+)(\w+)/)

    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1]) - 1 // Months are zero-based in JavaScript
    const day = parseInt(dateParts[2])

    let hour = parseInt(timeParts[1])
    const minute = parseInt(timeParts[2])
    const meridiem = timeParts[3].toLowerCase()

    if (meridiem === "pm" && hour < 12) {
      hour += 12 // Convert 12-hour format to 24-hour format for PM times
    } else if (meridiem === "am" && hour === 12) {
      hour = 0 // Convert 12 AM to 0 (midnight) in 24-hour format
    }

    return new Date(year, month, day, hour, minute)
  }

  const Toolbar = ({ label, onView, view, onNavigate, localizer }) => {
    return (
      <MainToolbarContainer className="my-toolbar">
        <Header>
          <ButtonContent>
            <Button1 onClick={() => onNavigate("PREV")}>
              <LeftOutlined />
            </Button1>
            <Button1 onClick={() => onNavigate("NEXT")}>
              <RightOutlined />
            </Button1>
            <Span>{label}</Span>
          </ButtonContent>

          <Btncontainer>
            <Buttoncontent2>
              <Button2
                active={view === "week"}
                onClick={() => {
                  onView("week")
                  // setViewdot("week");
                }}
              >
                Week
              </Button2>
              <Button2
                active={view === "month"}
                onClick={() => {
                  onView("month")
                  // setViewdot("month");
                }}
              >
                Month
              </Button2>
            </Buttoncontent2>
          </Btncontainer>
        </Header>
      </MainToolbarContainer>
    )
  }

  const CustomHeader = ({ date, sessionArray }) => {
    const day = moment(date).format("ddd")
    const dateNumber = moment(date).format("DD")

    return (
      <HeaderContent className="rbc-header-content">
        <HeaderDay className="rbc-header-day">{day}</HeaderDay>
        <HeaderDate className="rbc-header-date">
          {dateNumber}
          {/* <span
            style={{
              position: "absolute",
              top: "8px",
              left: "13px",
            }}
          >
            {sessionArray?.find(
              (session) => moment(session?.end)?.format("DD") == dateNumber
            )
              ? "."
              : null}
          </span> */}
        </HeaderDate>
      </HeaderContent>
    )
  }

  const handleConnectVideo = (start, end, event) => {
    if (!sessionDetail[0]?.calendly_connection_status)
      return setIsInfoModalOpen(true)

    const newStart = new Date(start)
    const newEnd = new Date(end)
    console.log({ sessionDetail })
    sessionDetail?.map(item =>
      item?.sessions?.map(
        itemOne =>
          itemOne?.id === event?.id && setcoacheeData(itemOne, event?.id)
      )
    )
    const matchingSlot = sessionArray.find(
      slot =>
        new Date(slot?.start).getTime() === newStart.getTime() ||
        new Date(slot?.end).getTime() === newEnd.getTime()
    )
    if (matchingSlot && matchingSlot?.call_type === "Coaching Session") {
      setRoomId(matchingSlot?.id)
      setOpenVideoModal(true)
    }
  }
  const CustomEvent = ({ event }) => {
    const { start, end, title } = event

    return (
      <>
        {" "}
        <AvailableSlotdiv onClick={() => handleConnectVideo(start, end, event)}>
          <EventTitle>{title}</EventTitle>
          <EventDate>
            {moment(start).format("h:mm A")} - {moment(end).format("h:mm A")}
          </EventDate>
        </AvailableSlotdiv>
      </>
    )
  }

  return (
    <Container>
      {sessionLoading && <Loading />}
      <Calendar
        localizer={localizer}
        startAccessor="start"
        events={sessionArray}
        endAccessor="end"
        style={{}}
        className="my-calendar-class"
        components={{
          toolbar: props => <Toolbar {...props} />,
          event: CustomEvent,
          week: {
            header: props => (
              <CustomHeader sessionArray={sessionArray} {...props} />
            )
          }
        }}
        min={new Date(0, 0, 0, 0, 0, 0)}
        max={new Date(0, 0, 0, 23, 1, 0)}
      />

      {isInfoModalOpen && (
        <InfoModal
          isInfoModalOpen={isInfoModalOpen}
          setIsInfoModalOpen={setIsInfoModalOpen}
          description={"Your coach's  calendly is not linked."}
        />
      )}
      <VideocCallModal
        roomId={roomId}
        coacheeData={coacheeData}
        isVideoOpen={openVideoModal}
        setIsVideoOpen={setOpenVideoModal}
        videoRoomData={videoRoomData}
      />
    </Container>
  )
}

export default CoacheeSchedular

const Container = styled.div`
  height: 100%;
  /* border: 1px solid red; */
  background-color: #fff;
`
const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 10vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`

const BtnContent = styled.div`
  width: 123px;
  margin-left: 1.2rem;
`

const MainToolbarContainer = styled.div`
  background-color: #fff;
  height: 60px;
`
const Header = styled.div`
  /* border: 1px solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
`
const ButtonContent = styled.div`
  /* border: 1px solid red; */
  width: 31.7%;
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 2.2rem;
`
const Span = styled.span`
  margin: 0;
  font-family: "Myriad-regular";
  font-style: normal;
  font-size: 20px;
  font-weight: 600;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`
const Button1 = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 9px;
`

const Btncontainer = styled.div`
  /* border: 1px solid red; */

  /* display: flex; */
  /* justify-content: center; */
`

const Buttoncontent2 = styled.div`
  padding: 2px;
  width: 343px;
  height: 36px;
  display: flex;
  background: ${({ theme }) => theme.border2};
  border-radius: 8px;
`

const Button2 = styled.button`
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.primary : theme.border2};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.textDark};
`

const HeaderContent = styled.div`
  min-height: 65px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`
const HeaderDay = styled.div`
  font-family: "Myriad-Light";
  font-weight: 600;
  font-size: 14px;
  line-height: 124.5%;
  text-align: center;
  color: #45485b;
`
const HeaderDate = styled.div`
  position: relative;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 124.5%;
  color: #45485b;
  /* color: red; */
  margin-top: 3px;
  padding: 2px 4px;
  border-radius: 50%;
`

const EventTitle = styled.div`
  color: #2b2b2b;
  margin: 0 4px;

  /* background-color: ${({ res }) => (res ? "red" : "yellow")}; */
`

const AvailableSlotdiv = styled.div`
  background-color: transparent;
  font-size: 10px;
  font-weight: 600;
  display: flex;
`

const EventDate = styled.p`
  margin: 0;
  color: #2b2b2b;
`
const FootarButtoncontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.15);
  height: 132px;
  padding: 0.2rem 3.5rem;
  flex-shrink: 0;
`
const FootarButton = styled.div`
  /* width: 25%; */
  width: 292px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`
const FooterContent = styled.p`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px; /* 122.5% */
  letter-spacing: -0.32px;
`
