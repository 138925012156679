import React, { useContext, useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"
import styled from "styled-components"
import { ReactComponent as Audio } from "./Audio.svg"
import { ReactComponent as VideoIcon } from "./Video.svg"
import { ReactComponent as VideoHiddenIcon } from "./videoHidden.svg"
import { ReactComponent as AudioMuteIcon } from "./AudioMute.svg"
import { ReactComponent as DarkLogo } from "./darkLogo.svg"
import { useNavigate, useParams } from "react-router-dom"
import { BaseService } from "API/base"
import { getCookie } from "Utils/StorageVariables"
import { GlobalStateContext } from "GlobalStateContext"
import { Error } from "Responses/Response"

const Connecting = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [progress, setProgress] = useState(1)
  const remotePartispant = JSON.parse(getCookie("remotePartispant"))
  const localPartispant = JSON.parse(getCookie("localPartispant"))

  const userData = JSON.parse(getCookie("userData"))
  const { globalState, updateGlobalState, clearGlobalState } =
    useContext(GlobalStateContext)
  const [timeoutId, setTimeoutId] = useState(null)

  const getRoomId = async () => {
    try {
      const response = await BaseService.post(
        `/api/v1/session_call/create_room/${id}`,
        true
      )

      if (response?.status === 200) {
        const token = response?.data?.token
        HandleTimeOut(token)
      }
    } catch (err) {
      Error(err?.response?.message)
    }
  }
  const onCancelClick = () => {
    clearTimeoutHandler()
    clearGlobalState()
    navigate(
      userData?.user_type === "Coach"
        ? "/app/coach/calendar/coach-calender"
        : "/app/coachee/coaches/Calender"
    )
  }

  useEffect(() => {
    if (!id) return

    getRoomId()
  }, [id])

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 3) {
        setProgress(prevProgress => prevProgress + 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [progress])

  const HandleTimeOut = token => {
    const timeoutId = setTimeout(() => {
      navigate(`/video_connected/${token}`)
    }, 5000)
    setTimeoutId(timeoutId)
  }
  const clearTimeoutHandler = () => {
    clearTimeout(timeoutId)
  }

  return (
    <>
      <Container>
        <Header>
          <LogoWrapper>
            <DarkLogo />
          </LogoWrapper>
          <Heading>Coaching Session</Heading>
        </Header>
        <SubContainerWrap>
          {globalState?.isVideo ? (
            <Webcam
              audio={globalState?.isAudio}
              video={globalState?.isVideo}
              width={"40rem"}
              height={"40rem"}
              mirrored={true}
            />
          ) : (
            <AudioContainaer>
              <Image
                src={
                  localPartispant?.profile_picture_url
                    ? localPartispant?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                alt="image"
              />
            </AudioContainaer>
          )}

          <ButtonsWrapper>
            {" "}
            <AudioButton
              onClick={() =>
                updateGlobalState("isVideo", !globalState?.isVideo)
              }
            >
              {globalState?.isVideo ? <VideoIcon /> : <VideoHiddenIcon />}
            </AudioButton>
            <AudioButton
              onClick={() =>
                updateGlobalState("isAudio", !globalState?.isAudio)
              }
            >
              {globalState?.isAudio ? <Audio /> : <AudioMuteIcon />}
            </AudioButton>
          </ButtonsWrapper>
          <JoiningHeading>
            Joining call
            {Array?.from({ length: progress }, (_, index) => (
              <span key={index}>.</span>
            ))}
          </JoiningHeading>
          <JoiningName>
            Call will start when {remotePartispant?.first_name}{" "}
            {remotePartispant?.last_name} joins.
          </JoiningName>
          <Button onClick={onCancelClick}>Cancel</Button>
        </SubContainerWrap>
      </Container>
    </>
  )
}

export default Connecting
const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${({ isBackground }) => isBackground && "#252525"};
`
const Header = styled.div`
  width: 100%;
  height: 92px;
  border-bottom: 1px solid #c2c1bd;
  display: flex;
  align-items: center;
`

const LogoWrapper = styled.div`
  padding-left: 1.5rem;
`

const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ isColor }) => (isColor ? "#fff" : "#2b2b2b")};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0.374px;
  text-align: center;
  width: calc(100% - 12.5rem);
  margin-right: 3rem;
`

const AudioContainaer = styled.div`
  width: ${({ isWidth }) => (isWidth ? "263px" : "615px")} !important;
  height: ${({ isWidth }) => (isWidth ? "212px" : "463px")} !important  ;
  position: relative;
  border-radius: 24px;
  background: ${({ isWidth }) => (isWidth ? "#252525" : "#ECECEC")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Image = styled.img`
  width: ${({ isWidth }) => (isWidth ? "80px" : "150px")};
  height: ${({ isWidth }) => (isWidth ? "80px" : "150px")};
  border-radius: 50%;
`
const Button = styled.button`
  border-radius: 100px;
  background: #e4bf44;
  display: flex;
  width: 150px;
  padding: 10px;
  justify-content: center;
  color: #221f20;
  align-items: center;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
  margin-top: 1rem;
  cursor: pointer;
  :disabled {
    cursor: progress;
  }
`
const JoiningHeading = styled.h1`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  margin-top: 1rem;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 170.833% */
  letter-spacing: 0.374px;
`
const ButtonsWrapper = styled.div`
  height: 400px !important;
  width: 580px !important;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: flex-end;
  position: absolute;

  top: 0;

  bottom: 53%;
`
const AudioButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
`
const JoiningName = styled.p`
  margin: 0;

  padding: 0;
  color: #2b2b2b;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px; /* 153.125% */
  letter-spacing: -0.32px;
`
const SubContainerWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;
  position: relative;
  border-radius: 25px;
  video {
    width: 750px !important;
    height: 463px !important;
    position: relative;
  }
`
