import PicUploadModal from "Components/ProfilePicUploadModal/PicUploadModal"
// import AvatarDropdown from "Elements/AvatarDropdown/AvatarDropdown";
import Button from "Elements/Button/Button"
import Input from "Elements/Input/Input"
import React, { Fragment, useEffect, useRef, useState, useContext } from "react"
import { clientProfileSchema } from "Schemas"
import styled from "styled-components"
import { BaseService } from "API/base"
import { Form, Formik } from "formik"
import { Error } from "Responses/Response"
import { getCookie, setCookie } from "Utils/StorageVariables"
// import { Select } from "antd"
import AddEmployesModal from "Components/AddEmployesModal/AddEmployesModal"
import { ReactComponent as ProfileAvatar } from "./profile_avatar.svg"
import { ReactComponent as Employee } from "./employees.svg"
import { Select } from "antd"
import { GlobalStateContext } from "GlobalStateContext"
import { stateList } from "Utils/StorageVariables"
import Loading from "Components/Loader/Loading"

const ClientProfile = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [addEmployee, setAddEmployee] = useState(false)
  const [employees, setEmployees] = useState(null)
  const userData = JSON.parse(getCookie("userData"))
  const [getClientLoading, setGetClientLoading] = useState(false)
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)
  const [getEmployeesLoading, setGetemployeesLoading] = useState(false)
  const formik = useRef()
  const [isShowImage, setisShowImage] = useState(null)

  const renderImg = values => {
    if (isShowImage) return isShowImage
    if (avatarUrl) return avatarUrl
    if (values?.profile_picture) return values?.profile_picture
  }
  var initialValues = {
    profile_picture: "",
    client_name: "",
    industry: "",
    email: "",
    city: "",
    zip_code: "",
    state: "",
    contact_name: "",
    contact_number: "",
    company_employees: 2332525
  }

  const setValues = data => {
    formik?.current?.setFieldValue("email", data?.email)
    formik?.current?.setFieldValue("profile_picture", data?.profile_picture_url)
    formik?.current?.setFieldValue("client_name", data?.client_name)
    formik?.current?.setFieldValue("industry", data?.industry)
    formik?.current?.setFieldValue("zip_code", data?.zip_code)
    formik?.current?.setFieldValue("state", data?.state)
    // console.log(data?.state , 'azeem')

    formik?.current?.setFieldValue("city", data?.city)
    formik?.current?.setFieldValue("contact_name", data?.contact_name)
    formik?.current?.setFieldValue("contact_number", data?.contact_number)
    formik?.current?.setFieldValue("company_employees", 23534)
  }

  const handleSelect = (key, e) => {
    // console.log(e, "hello")
    e.preventDefault()
    if (key === "state") {
      setValues({ state: e })
    }
  }

  const getClient = async () => {
    try {
      setGetClientLoading(true)
      const response = await BaseService.get(
        `api/v1/clients/${userData?.user_type_id}/`,
        true
      )
      setValues(response?.data)
      setGetClientLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setGetClientLoading(false)
      }, 5000) // 50
    }
  }
  useEffect(() => {
    getClient()
  }, [])

  const onSubmit = async values => {
    setIsLoading(true)
    try {
      const form = new FormData()
      if (typeof values?.profile_picture === "object") {
        form.append("profile_picture", values?.profile_picture)
      }
      form.append("client_name", values?.client_name)
      form.append("industry", values?.industry)
      form.append("email", values?.email)
      form.append("state", values?.state)
      form.append("city", values?.city || "")
      form.append("zip_code", values?.zip_code || "")

      form.append("contact_name", values?.contact_name)
      form.append("contact_number", values?.contact_number)
      form.append("company_employees", values?.company_employees)

      const response = await BaseService.put(
        `api/v1/clients/${userData?.user_type_id}/`,
        form,
        true,
        true
      )
      if (response?.status === 200) {
        // console.log(response?.data);
        setValues(response?.data)
        updateGlobalState("profile_details", response?.data)
        setisShowImage(null)
        setAvatarUrl(null)
      }
    } catch (error) {
      console.error({ error })
      Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getEmployees = async () => {
    try {
      setGetemployeesLoading(true)
      const response = await BaseService.get(`/api/v1/coachees/`, true)

      setEmployees(response?.data)
      if (response?.status === 200) {
        updateGlobalState("profile_details", response?.data)
        // setCookie("globalState", JSON.stringify(globalState));
      }
      setGetemployeesLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getEmployees()
  }, [])
  return (
    <Fragment>
      <MainContainer>
        {(getClientLoading && <Loading />) ||
          (getEmployeesLoading && <Loading />)}
        <Header>
          <Heading>My Profile</Heading>
        </Header>
        <Screen>
          <Forms>
            <Formik
              initialValues={initialValues}
              innerRef={formik}
              validationSchema={clientProfileSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, setFieldValue, handleSubmit, values }) => {
                // console.log({ values }, "kmkmkm")
                return (
                  <Form onSubmit={handleSubmit}>
                    <PicUploadModal
                      setAvatarUrl={setAvatarUrl}
                      avatarUrl={avatarUrl}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      setFieldValue={setFieldValue}
                      isShowImage={isShowImage}
                      values={values}
                      setisShowImage={setisShowImage}
                      title={"Upload Company Logo"}
                    />
                    <CompanyLogo onClick={() => setIsOpen(true)}>
                      {values?.profile_picture ? (
                        <UploadImageContainer>
                          <UploadedImage src={renderImg(values)} alt="avatar" />
                          <UploadLogoText>Upload Logo</UploadLogoText>
                        </UploadImageContainer>
                      ) : (
                        <>
                          <ProfileAvatar />
                          <LogoText> Company Logo*</LogoText>
                        </>
                      )}
                    </CompanyLogo>
                    {errors.profile_picture && touched.profile_picture ? (
                      <Span style={{ textAlign: "center" }}>
                        {errors.profile_picture}
                      </Span>
                    ) : null}
                    <InputWrapper>
                      <Input
                        id="client_name"
                        type="text"
                        label={"Client Name*"}
                        value={values?.client_name}
                        placeholder={"Client Name*"}
                        name="client_name"
                        onChange={event => {
                          setFieldValue("client_name", event.target.value)
                        }}
                      />
                      {errors.client_name && touched.client_name ? (
                        <Span>{errors.client_name}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        name="industry"
                        label={"Industry*"}
                        value={values?.industry}
                        placeholder={"Industry*"}
                        id="industry"
                        onChange={event => {
                          setFieldValue("industry", event.target.value)
                        }}
                      />
                      {errors.industry && touched.industry ? (
                        <Span>{errors.industry}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        disabled
                        label={"Email"}
                        type="email"
                        name="email"
                        placeholder={"Email"}
                        value={values?.email}
                        id="email"
                        onChange={event => {
                          setFieldValue("email", event.target.value)
                        }}
                      />
                      {/* {errors.email && touched.email ? (
                        <Span>{errors.email}</Span>
                      ) : null} */}
                    </InputWrapper>
                    {/* <InputWrapper>
                      <AvatarDropdown listItems={listItems} />
                      <Input type="text" placeholder={"stateklkj*"} />
                    </InputWrapper> */}
                    <InputWrapper>
                      {/* <Input
                        type="text"
                        name="state"
                        id="state"
                        value={values?.state}
                        onChange={(event) => {
                          setFieldValue("state", event.target.value);
                        }}
                        placeholder={"state"}
                      /> */}
                      {/* <Select
                        className="select_dropdown"
                        defaultValue="State"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => {
                          setFieldValue("state", e);
                          handleSelect("state", e);
                        }}
                        options={stateList}
                      /> */}
                      <Select
                        showSearch
                        className="select_dropdown"
                        style={{
                          width: "100%"
                        }}
                        value={values?.state || undefined}
                        placeholder="State"
                        optionFilterProp="children"
                        onChange={e => {
                          setFieldValue("state", e)
                          handleSelect("state", e.target.name)
                        }}
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? "").includes(
                            input.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          optionA?.label
                            ?.toLowerCase()
                            .localeCompare(optionB?.label?.toLowerCase())
                        }
                        options={stateList}
                      />
                      {errors.state && touched.state ? (
                        <Span>{errors.state}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        name="city"
                        label={"City"}
                        id="city"
                        value={values?.city}
                        onChange={event => {
                          setFieldValue("city", event.target.value)
                        }}
                        placeholder={"City"}
                      />
                      {/* <Select
                        className="select_dropdown"
                        value={values?.city}
                        style={{
                          width: "100%"
                        }}
                        onChange={e => {
                          setFieldValue("city", e)
                          handleSelect("city", e)
                        }}
                        options={[
                          {
                            value: "Karachi",
                            label: "Karachi"
                          },
                          {
                            value: "Lahore",
                            label: "Lahore"
                          },
                          {
                            value: "Islamabad",
                            label: "Islamabad"
                          }
                        ]}
                      /> */}
                      {/* {errors.city && touched.city ? (
                        <Span>{errors.city}</Span>
                      ) : null} */}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        name="zip_code"
                        label={"ZIP Code"}
                        id="zip_code"
                        value={values?.zip_code}
                        onChange={event => {
                          setFieldValue("zip_code", event.target.value)
                        }}
                        placeholder={"ZIP Code"}
                      />
                      {/* {errors.zip_code && touched.zip_code ? (
                        <Span>{errors.zip_code}</Span>
                      ) : null} */}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        placeholder={"Contact Name*"}
                        name="contact_name"
                        id="contact_name"
                        label={"Contact Name*"}
                        value={values?.contact_name}
                        onChange={event => {
                          setFieldValue("contact_name", event.target.value)
                        }}
                      />
                      {errors.contact_name && touched.contact_name ? (
                        <Span>{errors.contact_name}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        type="text"
                        name="contact_number"
                        id="contact_number"
                        label={"Contact Number*"}
                        value={values?.contact_number}
                        onChange={event => {
                          setFieldValue("contact_number", event.target.value)
                        }}
                        placeholder={"Contact Number*"}
                      />
                      {errors.contact_number && touched.contact_number ? (
                        <Span>{errors.contact_number}</Span>
                      ) : null}
                    </InputWrapper>
                    <SaveBtnWrapper>
                      <Button type="submit" isBorder disabled={isLoading}>
                        {isLoading ? "Saving..." : "Save"}
                      </Button>
                    </SaveBtnWrapper>
                  </Form>
                )
              }}
            </Formik>
            <EmployeesContainer>
              <HeadingEmployee>Company Employees</HeadingEmployee>
              <EmployeeDetailListSection>
                <Details>
                  <Detail>
                    <Employee alt="employee" />
                    <NumberofEmployees>
                      {employees?.length}&nbsp;Employees
                    </NumberofEmployees>
                  </Detail>
                  <AddButton onClick={() => setAddEmployee(true)}>
                    Add
                  </AddButton>
                </Details>
              </EmployeeDetailListSection>
            </EmployeesContainer>
          </Forms>
        </Screen>
      </MainContainer>
      {addEmployee && (
        <AddEmployesModal
          onSuccess={status => getEmployees()}
          onCancel={() => setAddEmployee(false)}
        />
      )}
    </Fragment>
  )
}

export default ClientProfile

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  height: 100vh;
`
const Header = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  height: 8vh;
  border-bottom: 1px solid #dbd9d5;
  display: flex;
  align-items: center;
  padding: 0 3rem;
`
const Heading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  color: ${({ theme }) => theme.textDark};
  line-height: 41px;
  font-family: "Myriad-Regular";
`

const Screen = styled.div`
  height: calc(100vh - 8vh);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Forms = styled.div`
  background-color: #ffffff;
  width: 50%;
  padding: 2rem 4rem 4rem 4rem;
  height: auto;
  border: 1px solid #dbd9d5;
  border-radius: 20px;
  height: calc(100vh - 26vh);
  overflow-y: auto;
`

const InputWrapper = styled.div`
  margin: 10px 0;
`

const CompanyLogo = styled.div`
  cursor: pointer;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundDark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
`
const Image = styled.img`
  width: 50px;
  height: auto;
  object-fit: fill;
`
const LogoText = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.primary_dark};
  font-family: "Myriad-Regular";
`

const EmployeesContainer = styled.div``
const HeadingEmployee = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
`
const EmployeeDetailListSection = styled.div`
  height: 14vh;
`
const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Detail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NumberofEmployees = styled.p`
  padding-left: 10px;
`

const SaveBtnWrapper = styled.div`
  margin: 30px 0px 60px 0px;
`
const AddButton = styled.button`
  border-radius: 20px;
  width: 4rem;
  padding: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.primary};
  border: none;
  font-family: "Myriad-SemiBold";
  margin: 0;
`

const UploadedImage = styled.img`
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
`
const UploadImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const UploadLogoText = styled.p`
  position: absolute;
  bottom: 8px;
  left: 35px;
  font-size: 14px;
  color: ${({ theme }) => theme.textWhite};
  font-weight: 600;
`
const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`
