import { Input } from "antd"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { SearchOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ArrowDown } from "./arrow_down.svg"
import { ReactComponent as ArrowUp } from "./arrow_up.svg"
import { ReactComponent as RightArrow } from "./right_arrow.svg"
import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import NoDataFound from "Components/NoDataFound/NoDataFound"
import VideocCallModal from "Components/VideocCallModal/VideocCallModal"

const MyCoachees = () => {
  const [allLists, setAllLists] = useState([])
  const [inpVal, setInpVal] = useState("")
  const [dummyData, setDummyData] = useState([])
  const [getCoacheesLoading, setGetCoacheesLoading] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [ishow, setIsShow] = useState(false)
  const [isShown, setIsShown] = useState(false)
  
  const getCoachees = async () => {
    try {
      setGetCoacheesLoading(true)
      const response = await BaseService.get(
        `api/v1/coaches/session/students/`,
        true
      )
      setIsShow(response?.data)
      setAllLists({
        ...response?.data
      })
      setDummyData(response?.data)
      setGetCoacheesLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCoachees()
  }, [])

  const handleSelectedList = key => {
    if (selectedList.includes(key)) {
      setSelectedList(selectedList.filter(item => item !== key))
    } else {
      setSelectedList([...selectedList, key])
    }
  }

  const navigate = useNavigate()

  const handleChange = e => {
    const value = e.target.value
    if (value.length >= 1) {
      setIsShown(true)
    } else {
      setIsShown(false)
    }
    setInpVal(value)
    handleSearch(value)
  }


  const handleSearch = value => {
    const copyItems = { ...dummyData }
    if (value.length === 0) {
      setAllLists(copyItems)
    } else {
      const filteredData = {}
      Object.keys(copyItems).forEach(key => {
        const filteredArray = copyItems[key].filter(item => {
          const firstNameMatch = item.first_name
            ? item.first_name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : false
          const lastNameMatch = item.last_name
            ? item.last_name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : false
          return firstNameMatch || lastNameMatch
        })

        if (filteredArray.length > 0) {
          filteredData[key] = filteredArray
        }
      })
      setAllLists(filteredData)
    }
  }

  const handleData = item => {
    navigate(`coachee-profile/${item?.id}`)
  }
  return (
    <Container>
      {getCoacheesLoading && <Loading />}
      <Header>
        <Heading>My Coachees</Heading>
        <InputWrapper>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
            className="search_bar"
            value={inpVal}
            onChange={handleChange}
            style={{ borderRadius: "32px" }}
          />
        </InputWrapper>
      </Header>

      <CollapseContainer>
        {allLists && Object.keys(allLists).length > 0 ? (
          Object.keys(allLists).map(key => (
            <React.Fragment key={key}>
              <CollapseHeader onClick={() => handleSelectedList(key)}>
                <SubHeading>{key}</SubHeading>
                {selectedList.includes(key) ? (
                  <ArrowDown style={{ marginTop: "0.4rem" }} />
                ) : (
                  <ArrowUp style={{ marginTop: "0.4rem" }} />
                )}
              </CollapseHeader>
              <CollapseContent>
                {!selectedList.includes(key) &&
                  (filteredData[key] || allLists[key])?.map(item => (
                    <EmployeDetail
                      key={item.id}
                      onClick={() => {
                        handleData(item)
                      }}
                    >
                      <ProfileSection>
                        <Profile>
                          <div>
                            <ImageProfile
                              src={
                                item?.profile_picture_url
                                  ? item?.profile_picture_url
                                  : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                              }
                              alt="profile"
                            />
                          </div>
                          <div>
                            <Name>
                              {item?.first_name} {item?.last_name}
                            </Name>
                            <SubTitle>{item?.title}</SubTitle>
                          </div>
                        </Profile>
                      </ProfileSection>
                      <Icon1>
                        <RightArrow />
                      </Icon1>
                    </EmployeDetail>
                  ))}
              </CollapseContent>
            </React.Fragment>
          ))
        ) : (
          <NoDataFound />
        )}
      </CollapseContainer>

     <VideocCallModal/> 
    </Container>
  )
}

export default MyCoachees
const Container = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.white};
`
const Header = styled.div`
  height: 11vh;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
`
const InputWrapper = styled.div`
  width: 30%;
`
const Heading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  /* or 171% */

  display: flex;
  align-items: center;
  letter-spacing: 0.374px;

  color: ${({ theme }) => theme.textDark};
  padding-left: 1rem;
`
const CollapseContainer = styled.div`
  margin-bottom: 20px;
  padding: 0.5rem 2rem;
  /* border: 1px solid green; */
`

const CollapseHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
`
const SubHeading = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.textDark};
`
const Icon = styled.img`
  margin-top: 0.4rem;
`
const CollapseContent = styled.div`
  padding: 10px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
const EmployeDetail = styled.div`
  border: 1px solid rgba(151, 173, 182, 0.2);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
`
const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ImageProfile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`
const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`
const Name = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 50.23%;
  color: #2b2b2b;
`
const SubTitle = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: #838181;
`
const Icon1 = styled.div``
// const IconImg = styled.img``;
