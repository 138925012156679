import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as AiOutlineEye } from "./InvisibleEye.svg";
import { ReactComponent as AiOutlineEyeInvisible } from "./Eye.svg";

import PhoneInputWithCountry from "react-phone-number-input";
// import Box from "@mui/material/Box";
import TextField from "@material-ui/core/TextField";

const Input = ({
  type,
  label,
  placeholder,
  onChange,
  value,
  disabled,
  Error,
  name,
  isLeft,
  isRight,
  ...rest
}) => {
  const [isShow, setIsShow] = useState(type);
  // const [isCheck, setIsCheck] = useState(false);
  // console.log(type);
  return (
    <Wrapper>
      {/* {label && <Label>{label}</Label>} */}

      {type === "tel" && (
        <>
          <PhoneNumberInput
            placeholder={placeholder}
            isRight={isRight}
            disabled={disabled}
            type={type}
            name={name}
            value={value}
            Error={Error}
            onChange={() => onChange}
          />
        </>
      )}
      {type === "password" && (
        <InputWrapper Error={Error}>
          <CustomInput
            type={isShow}
            placeholder={placeholder}
            Error={Error}
            onChange={onChange}
            value={value}
            disabled={disabled}
            name={name}
            {...rest}
          />
          <EyeWrap>
            {isShow === "password" ? (
              <>
                <AiOutlineEyeInvisible
                  onClick={() => setIsShow("text")}
                  size={20}
                  style={{ color: "#5B5B5B" }}
                />
              </>
            ) : (
              <>
                <AiOutlineEye
                  size={22}
                  onClick={() => setIsShow("password")}
                  style={{
                    color: "E4BF44",
                  }}
                />
              </>
            )}
          </EyeWrap>
        </InputWrapper>
      )}

      {(type === "text" || type === "email" || type === "number") && (
        <InputWrapper Error={Error}>
          {/* <CustomInput
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
            Error={Error}
            disabled={disabled}
            {...rest}
          /> */}
          <CustomInput
            type={type}
            // placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
            Error={Error}
            disabled={disabled}
            {...rest}
            id="standard-basic"
            label={label}
            variant="standard"
          />
        </InputWrapper>
      )}
      {Error && <ErrorMessage>{Error}</ErrorMessage>}
    </Wrapper>
  );
};
export default Input;

const Wrapper = styled.div`
  width: 100%;
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ type }) =>
    type === "tel" && "password" ? "0.5rem" : "0"};
  color: ${({ theme }) => theme.textDark};
  width: 100%;
  /* border: none; */
  /* border-bottom: 1px solid #43434340; */
  /* border-radius: 4px; */
  border-bottom: 1px solid
    ${({ Error, theme }) => (Error ? theme.danger : "#43434340")};
`;
const Label = styled.label`
  font-family: "Myriad-Light";
  font-weight: 600;
  font-size: 0.7rem;
  line-height: 28px;
  color: ${({ theme }) => theme.textDark};
`;
const CustomInput = styled(TextField)`
  width: 100%;
  border: none;
  font-size: 1rem;
  padding: 0.59rem 0rem;
  /* height: 2.42rem ; */
  background: transparent;
  color: ${({ theme, Error }) => (Error ? theme.danger : theme.textDark)};

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.dark};
    font-size: 0.85rem;
    font-family: "Myriad-Regular";
  }
`;
const CustomInputLabel = styled.label``;
const EyeWrap = styled.div`
  padding-right: 0.35rem;
  transform: translateY(3px);
`;
const ErrorMessage = styled.p`
  font-size: 1rem;
  margin-top: 0rem;
  color: ${({ theme }) => theme.danger};
  font-family: "Myriad-Light";
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
`;
const PhoneNumberInput = styled(PhoneInputWithCountry)`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ isRight }) => isRight && "row-reverse"};
  align-items: center;
  color: ${({ theme }) => theme.textDark};
  width: 100%;
  ${({ Error, theme }) => (Error ? theme.danger : theme.textDark)};
  height: 2.387rem;
  border-bottom: 1px solid
    ${({ Error, theme }) => (Error ? theme.danger : "#43434340")};

  .PhoneInputInput {
    width: 100%;
    border: none;
    font-size: 0.85rem;
    background: transparent;
    color: ${({ theme, Error }) => (Error ? theme.danger : theme.textDark)};
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &::placeholder {
      color: ${({ theme, Error }) => (Error ? theme.danger : theme.dark)};
    }
  }

  .PhoneInputCountry {
    .PhoneInputCountryIcon {
      display: none;
      .PhoneInputCountryIconImg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        color: ${({ theme, Error }) => (Error ? theme.danger : theme.textDark)};
      }
    }
  }
  .PhoneInputCountrySelect {
    display: none;
    &.PhoneInputCountryIcon {
      font-size: 1rem;
    }
  }
`;
