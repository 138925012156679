import React, { useContext, useEffect, useState } from "react";
import Button from "Elements/Button/Button";
import styled from "styled-components";
import { Modal } from "antd";
import Input from "Elements/Input/Input";
import { addEmployeesSchemas } from "Schemas";
import { useFormik } from "formik";
import { BaseService } from "API/base";
import { Error, Success } from "Responses/Response";
import { getCookie } from "Utils/StorageVariables";

const AddEmployesModal = ({ onCancel, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const globalState = JSON.parse(getCookie("globalState"));
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    title: "",
    client_name: globalState?.data?.client_name,
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    console.log(values);
    try {
      const form = new FormData();

      form.append("first_name", values?.first_name);
      form.append("last_name", values?.last_name);
      form.append("email", values?.email);
      form.append("title", values?.title);
      form.append("contact_number", values?.contact_number);
      form.append("client_name", values?.client_name);

      const response = await BaseService.post(
        `/api/v1/coachees/`,
        form,
        true,
        true
      );
      if (response?.status === 201) {
        setValues(response?.data);
        onCancel();
        onSuccess(true);
        Success("Employee added successfully...");
      }
    } catch (error) {
      console.error({ error });
      Error(error?.response?.data?.detail);
      onCancel();
    } finally {
      setIsLoading(false);
    }
  };

  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: addEmployeesSchemas,
    onSubmit: onSubmit,
  });
  const setValues = (data) => {
    setFieldValue("email", data?.email);
    setFieldValue("first_name", data?.first_name);
    setFieldValue("last_name", data?.last_name);
    setFieldValue("title", data?.title);
    setFieldValue("contact_number", data?.contact_number);
    setFieldValue("client_name", data?.client_name);
  };
  const handleSelect = (value) => {
    setFieldValue("title", value);
  };
  console.log({ errors });
  return (
    <>
      <Modal
        open={true}
        footer={false}
        onCancel={() => onCancel()}
        className="add_employee_modal"
      >
        <Container>
          <ModelContent>
            <Heading>Add Employees</Heading>
            <InputWrapper>
              <Input
                Error={errors.first_name && touched.first_name}
                type="text"
                label={"First Name"}
                name={"first_name"}
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.first_name && touched.first_name ? (
                <Span>{errors.first_name}</Span>
              ) : null}
            </InputWrapper>
            <InputWrapper>
              <Input
                Error={errors.last_name && touched.last_name}
                type="text"
                label={"Last Name"}
                name={"last_name"}
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.last_name && touched.last_name ? (
                <Span>{errors.last_name}</Span>
              ) : null}
            </InputWrapper>
            <InputWrapper>
              <Input
                Error={errors.email && touched.email}
                type="email"
                label={"Email"}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <Span>{errors.email}</Span>
              ) : null}
            </InputWrapper>
            <InputWrapper>
              <Input
                Error={errors.contact_number && touched.contact_number}
                type="text"
                name="contact_number"
                value={values.contact_number}
                onChange={handleChange}
                onBlur={handleBlur}
                label={"Phone"}
              />
              {errors.contact_number && touched.contact_number ? (
                <Span>{errors.contact_number}</Span>
              ) : null}
            </InputWrapper>
            <InputWrapper>
              <Input
                Error={errors.contact_number && touched.contact_number}
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                label={"Title"}
              />
              {errors.title && touched.title ? (
                <Span>{errors.title}</Span>
              ) : null}
            </InputWrapper>

            <ButtonContent>
              <Button
                isBorder
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {isLoading ? "Creating..." : "Create"}
              </Button>
            </ButtonContent>
          </ModelContent>
        </Container>
      </Modal>
    </>
  );
};

export default AddEmployesModal;

const Container = styled.div`
  /* min-height: 426px; */
  height: 100%;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const ModelContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 340px;
`;
const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin: 1.5rem 0;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;
const InputWrapper = styled.div`
  margin-bottom: 0.9rem;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme, isborder, Error }) =>
      isborder ? "#43434340" : Error ? theme.danger : "none"};

  .ant-select-selector,
  .ant-select:not(.ant-select-disabled).ant-select-focused
    .ant-select-selector {
    border: transparent !important;
    box-shadow: none !important;
  }
`;

const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;
