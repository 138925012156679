export const lightTheme = {
  white: "#ffffff",
  light: "#f1f1f2",
  dark: "#303436",
  dark2: "#131415",
  grey: "#a1a9b3",
  grey2: "#ebebeb",
  grey3: "#f8f8f8",
  border: "#a1a9b3",
  border2: "#ebebeb",
  border3: "#f8f8f8",
  primary: "#E4BF44",
  secondary: "#31c451",
  primary_dark: "#A68108",
  success: "#31c451",
  danger: "#FF5740",
  warning: "#f5c84c",
  fadedSuccess: "#31c451ad",
  fadedDanger: "#ff333aab",
  fadedWarning: "#f5c84ca1",
  lightGrey: "#f6f6f7",
  label: "#838687",
  textLight: "#838181",
  textDark: "#2B2B2B",
  textMedium: "#5B5B5B",
  textWhite: "#ffffff",
  backgroundDark: "#F5F5F5",
  backgroundLight: "#ffffff",
  OptBorderDark : "#7B7B7B",
  OptBorderlight : "#D9D9D9",
};

export const darkTheme = {
  // white: "#ffffff",
  // light: "#f1f1f2",
  // dark: "#303436",
  // dark2: "#131415",
  // grey: "#a1a9b3",
  // grey2: "#ebebeb",
  // grey3: "#f8f8f8",
  // border: "#ebebeb",
  // border2: "#a1a9b3",
  // border3: "#a1a9a9",
  // primary: "#1775f9",
  // secondary: "#31c451",
  // primary_light: "#90b8f1",
  // success: "#31c451",
  // danger: "#ff333a",
  // warning: "#f5c84c",
  // fadedSuccess: "#31c451ad",
  // fadedDanger: "#ff333aab",
  // fadedWarning: "#f5c84ca1",
  // yellow: "#e6ba00",
  // sourcedBlue: "#007bff",
  // lightGrey: "#748B9C",
  // purple: "#6f52ed",
  // label: "#838687",
  // textLight: "#d9d9d9",
  // textDark: "#80868B",
  // textWhite: "#FFFFFF",
  // backgroundDark: "#282A2D",
  // backgroundLight: "#3C4043",
  // shadowLight: "#323232",
  // shadowDark: "#212121",
};
