import { Navigate } from "react-router-dom"
import { getCookie } from "Utils/StorageVariables"
import { userTypes } from "Utils/Common"

export const useGuestRoutes = () => {
  let userData = JSON.parse(getCookie("userData"))
  // console.log({ userData });

  const GuestRoute = ({ children }) => {
    if (getCookie("accessToken")) {
      switch (userData?.user_type) {
        case userTypes?.client:
          ;<Navigate to={"/app/client/profile"} />
          break
        case userTypes?.coach:
          ;<Navigate to={"/app/coach/profile"} />
          break
        case userTypes?.coachee:
          ;<Navigate to={"/app/coachee/profile"} />

          break

        default:
          break
      }
    }

    return children
  }
  return { GuestRoute }
}
