import React, { useEffect, useState } from "react"
import Track from "./Track"

const Participant = props => {
  const [tracks, setTracks] = useState([])


  useEffect(() => {
    const existingTracks = Array.from(props.participant.tracks.values())
      .map(publication => publication.track)
      .filter(track => track !== null)

    setTracks(existingTracks)

    if (!props.localParticipant) {
      const eventListeners = [
        { eventName: "trackSubscribed", callback: trackSubscribedCallback }
      ]

      eventListeners.forEach(({ eventName, callback }) => {
        props.participant.on(eventName, callback)
      })

      return () => {
        eventListeners.forEach(({ eventName, callback }) => {
          props.participant.off(eventName, callback)
        })
      }
    }
  }, [props.participant, props.localParticipant, props.isR])

  const trackSubscribedCallback = track => {
    setTracks(prevTracks => [...prevTracks, track])
  }


  const handleVideoAndAudioTrack = () => {
    props.participant.on("trackSubscribed", track => {
      track.on("disabled", () => {
        if (track.kind === "audio") {
          console.log(`${props.participant.identity} disabled mic.`)
          props?.setAudioTrackPartispant(false)
        }
        if (track.kind === "video") {
          console.log(`${props.participant.identity} disabled camera.`)
          props?.setVideoTrackParticipant(false)
        }
      })

      track.on("enabled", () => {
        if (track.kind === "audio") {
          console.log(`${props.participant.identity} enabled mic.`)
          props?.setAudioTrackPartispant(true)
        }
        if (track.kind === "video") {
          console.log(`${props.participant.identity} enabled camera.`)
          props?.setVideoTrackParticipant(true)
        }
      })
    })
  }



  useEffect(() => {
    handleVideoAndAudioTrack()
  }, [])




  useEffect(() => {
    props.participant.on("trackSubscribed", track => {
      if (track.kind === "audio") {
        props?.setAudioTrackPartispant(track.isEnabled)
      }
      if (track.kind === "video") {
        props?.setVideoTrackParticipant(track.isEnabled)
      }
    })
  }, [tracks])

  return (
    <div className="participant" id={props?.participant?.identity}>
      {tracks.map(track => (
        <Track key={track} track={track} />
      ))}
    </div>
  )
}

export default Participant
