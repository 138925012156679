import React, { createContext, useState } from "react";

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    isAudio: true,
    isVideo: true,
  });

  const updateGlobalState = (key, value) => {
    setGlobalState({
      ...globalState,
      [key]: value,
    });
  };
  const clearGlobalState = () => {
    setGlobalState(
      {
        isAudio: true,
        isVideo: true,
      
    });
  };
  return (
    <GlobalStateContext.Provider value={{ globalState, updateGlobalState,clearGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
