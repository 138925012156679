import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Col, Row } from "antd"
import { MdKeyboardArrowRight } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import ChangePasswordModal from "Components/ChangePasswordModal/ChangePasswordModal"
import DeleteModal from "Components/DeleteModal/DeleteModal"
import FeedbackModal from "Components/FeedbackModal/FeedbackModal"
import LogoutModal from "Components/LogoutModal/LogoutModal"
import { ReactComponent as FeebackIcon } from "./Group14.svg"
import { ReactComponent as TermandConditionIcon } from "./terms-and-conditions1.svg"
import { ReactComponent as PadLockIcon } from "./padlock1.svg"
import { ReactComponent as AddUserIcon } from "./add-user1.svg"
import { ReactComponent as Calendly } from "./Calendlylogo.svg"
import { ReactComponent as ExitIcon } from "./exit1.svg"
import { getCookie, removeAllCookie } from "Utils/StorageVariables"
import { userTypes } from "Utils/Common"
import { BaseService } from "API/base"
import { Error, Success } from "Responses/Response"
import Loading from "Components/Loader/Loading"
import { GlobalStateContext } from "GlobalStateContext"
import CalendlyUnlinkModal from "Components/CalendlyUnlinkModal/CalendlyUnlinkModal"
const Settings = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isSlotLoading, setIsSlotLoading] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [changePassword, setchangePassword] = useState(false)
  const [isDeleteModal, setisDeleteModal] = useState(false)
  const [isLogout, setisLogout] = useState(false)
  const [isUnlinkModalOpen, setIsUnlinkModalOpen] = useState(false)
  const [isConnectionStatus, setIsConnectionStatus] = useState()
  const { globalState, updateGlobalState } = useContext(GlobalStateContext)

  const userData = JSON.parse(getCookie("userData"))

  console.log(userData, "hhh")

  const handleAccountDelete = () => {
    if (userData?.user_type === userTypes?.client) {
      DeleteClientAccount(userData?.user_type_id)
    } else if (userData?.user_type === userTypes?.coachee) {
      DeleteCoacheesAccount(userData?.user_type_id)
    } else {
      DeleteCoachAccount(userData?.user_type_id)
    }
  }

  const DeleteClientAccount = async id => {
    setIsLoading(true)
    try {
      const response = await BaseService.remove(`api/v1/clients/${id}/`, true)
      if (response?.status === 204) {
        Success("Account deleted successfully!")
        removeAllCookie()
        navigate("/")
      }
    } catch (error) {
      console.error({ error })
      Error(error)
    } finally {
      setIsLoading(false)
    }
  }
  const DeleteCoachAccount = async id => {
    setIsLoading(true)
    try {
      const response = await BaseService.remove(`api/v1/coaches/${id}/`, true)
      if (response?.status === 204) {
        Success("Account deleted successfully!")
        removeAllCookie()
        navigate("/")
      }
    } catch (error) {
      console.error({ error })
      Error(error)
    } finally {
      setIsLoading(false)
    }
  }
  const DeleteCoacheesAccount = async id => {
    setIsLoading(true)
    try {
      const response = await BaseService.remove(`api/v1/coachees/${id}/`, true)
      if (response?.status === 204) {
        Success("Account deleted successfully!")
        removeAllCookie()
        navigate("/")
      }
    } catch (error) {
      console.error({ error })
      Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleShowFeedbackModal = () => {
    setisOpen(true)
  }

  const handleShowChangepasswordModal = () => {
    setchangePassword(true)
  }

  const handleDeleteModal = () => {
    setisDeleteModal(true)
  }

  const handleLogout = () => {
    setisLogout(true)
  }

  const handleUnlinkCalendly = () => {
    setIsUnlinkModalOpen(true)
  }

  const unlinkCalendly = async () => {
    try {
      const response = await BaseService.post(
        "/api/v1/calendly_calender/deactivate"
      )

      if (response?.status === 200) {
        setIsUnlinkModalOpen(false)
        Success("Calendly Unlinked successfully!")
        getCalendlyStatus()
      }
    } catch (error) {
      Error(error?.detail)
    } finally {
    }
  }

  const getCalendlyStatus = async () => {
    setIsSlotLoading(true)
    try {
      const response = await BaseService.get("/rest-auth/user/")
      console.log(response?.data)
      setIsConnectionStatus(response?.data?.calendly_connection_status)
    } catch (error) {
      Error(error?.detail)
      setIsSlotLoading(false)
    } finally {
      setIsSlotLoading(false)
    }
  }

  useEffect(() => {
    getCalendlyStatus()
  }, [])

  const linkCalendly = async () => {
    try {
      const response = await BaseService.get(
        "/modules/calendly/auth/?redirectUrl=https://calm-darkness-38642.botics.co/app/coach/calendar/coach-calender"
      )

      if (response?.status === 200) {
        const redirectUrl = response?.data?.redirect_url
        window.open(redirectUrl, "_blank")
      }
    } catch (error) {
      Error(error?.detail)
    } finally {
    }
  }

  return (
    <Container>
      {isLoading || (isSlotLoading && <Loading />)}
      <Content>
        <Heading>Settings</Heading>
      </Content>
      <ButtonContent>
        <Row>
          <Col span={24} key={"id"}>
            <ModelContent onClick={handleShowFeedbackModal}>
              <HeadingItem>
                <FeebackIcon />
                <Title>Send Feedback</Title>
              </HeadingItem>
              <MdKeyboardArrowRight />
            </ModelContent>
            <FeedbackModal isOpen={isOpen} setisOpen={setisOpen} />
          </Col>
          <Col span={24} key={"id"}>
            <ModelContent
              onClick={() => {
                navigate("/app/term-condition")
              }}
            >
              <HeadingItem>
                <TermandConditionIcon />
                <Title>Terms and Conditions</Title>
              </HeadingItem>
              <MdKeyboardArrowRight />
            </ModelContent>
          </Col>
          <Col span={24} key={"id"}>
            <ModelContent
              onClick={() => {
                navigate("/app/privacy-policy")
              }}
            >
              <HeadingItem>
                <TermandConditionIcon />
                <Title>Privacy Policy</Title>
              </HeadingItem>
              <MdKeyboardArrowRight />
            </ModelContent>
          </Col>

          <Col span={24} key={"id"}>
            {userData?.user_type === "Coach" && (
              <>
                {isConnectionStatus ? (
                  <ModelContent onClick={handleUnlinkCalendly}>
                    <HeadingItem>
                      <Calendly />
                      <Title>Unlink Calendly Account</Title>
                    </HeadingItem>
                    <MdKeyboardArrowRight />
                  </ModelContent>
                ) : (
                  <ModelContent onClick={linkCalendly}>
                    <HeadingItem>
                      <Calendly />
                      <Title>Link Calendly Account</Title>
                    </HeadingItem>
                    <MdKeyboardArrowRight />
                  </ModelContent>
                )}

                <CalendlyUnlinkModal
                  Info={`Are you sure you want to unlink your Calendly account. All future sessions will be cancelled?`}
                  Secondarybtntext={"Yes"}
                  Primarybtntext={"Cancel"}
                  setIsUnlinkModalOpen={setIsUnlinkModalOpen}
                  isUnlinkModalOpen={isUnlinkModalOpen}
                  onClick={unlinkCalendly}
                />
              </>
            )}
          </Col>

          <Col span={24} key={"id"}>
            <ModelContent onClick={handleShowChangepasswordModal}>
              <HeadingItem>
                <PadLockIcon />
                <Title>Change Password</Title>
              </HeadingItem>
              <MdKeyboardArrowRight />
            </ModelContent>
            <ChangePasswordModal
              changePassword={changePassword}
              setchangePassword={setchangePassword}
            />
          </Col>
          <Col span={24} key={"id"}>
            <ModelContent onClick={handleDeleteModal}>
              <HeadingItem>
                <AddUserIcon />
                <Title>Delete Account</Title>
              </HeadingItem>
              <MdKeyboardArrowRight />
            </ModelContent>
            <DeleteModal
              isDeleteModal={isDeleteModal}
              setisDeleteModal={setisDeleteModal}
              onclick={handleAccountDelete}
              isloading={isLoading}
            />
          </Col>
          <Col span={24} key={"id"}>
            <ModelContent onClick={handleLogout}>
              <HeadingItem>
                <ExitIcon />
                <Title>Logout</Title>
              </HeadingItem>
              <MdKeyboardArrowRight />
            </ModelContent>
            <LogoutModal isLogout={isLogout} setisLogout={setisLogout} />
          </Col>
        </Row>
      </ButtonContent>
    </Container>
  )
}

export default Settings
const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 11vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  padding: 0 3rem;
`

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`
const ButtonContent = styled.div``

const ModelContent = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 1.1rem;
  }
`
const HeadingItem = styled.div`
  padding: 0.5rem 0rem;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
`

const Title = styled.h3`
  font-family: "Myriad-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  color: ${({ theme }) => theme.textDark};
`

const Logo = styled.img`
  width: 24.03px;
  height: 22px;
  margin-right: 16px;
`
