import React, { useEffect, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import { useFormik } from "formik"
import { NewPasswordSchemas } from "Schemas"
import Button from "Elements/Button/Button"
import Input from "Elements/Input/Input"
import { LeftOutlined } from "@ant-design/icons"
import { BaseService } from "API/base"
import { Error, Success } from "Responses/Response"
import { useNavigate, useParams } from "react-router-dom"
import { removeAllCookie } from "Utils/StorageVariables"

const NewPassword = () => {
  const { token, uid } = useParams()
  const navigate = useNavigate()
  console.log({ token, uid })
  const [isLoading, setIsLoading] = useState(false)
  const [updatePasswordError, setUpdatePasswordError] = useState(null)
  const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false)

  useEffect(() => {
    if (updatePasswordError) {
      alert(updatePasswordError)
    }
  }, [updatePasswordError])

  useLayoutEffect(() => {
    removeAllCookie()
  }, [])

  const initialValues = {
    new_password1: "",
    new_password2: ""
  }

  const handleUpdatePassword = async values => {
    // console.log(values);
    setIsLoading(true)
    try {
      const response = await BaseService.post(
        `new-password/${uid}/${token}/`,
        values,
        false
      )

      // console.log({ response });
      setUpdatePasswordSuccess(true)
      if (response?.status === 200) {
        Success(response?.data?.detail)
        navigate("/")
      }
      // console.log({ response }, "update password response");
    } catch (error) {
      // console.log(error);
      error?.response?.data?.non_field_errors?.map(err => Error(err))
      // Error(
      //   "Password must be at least 8 characters long with at least one number and one special character"
      // );
    } finally {
      setIsLoading(false)
    }
  }

  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    isSubmitting
  } = useFormik({
    initialValues: initialValues,
    validationSchema: NewPasswordSchemas,
    onSubmit: () => handleUpdatePassword(values)
  })
  console.log({ errors })
  return (
    <>
      <Main>
        <MainContainer>
          <Logocontainer>
            <Logo src="/Assets/Group 462.svg" alt="" />
          </Logocontainer>
          <Form onSubmit={handleSubmit}>
            {updatePasswordSuccess ? (
              <Message>
                <MessageDescription>
                  An email has been sent with further instructions. Please check
                  your inbox to proceed further!
                </MessageDescription>
              </Message>
            ) : (
              <InputField>
                <BtnContent>
                  <Forgetbtn>
                    {" "}
                    <LeftOutlined
                      style={{
                        fontSize: "1.2rem",
                        marginRight: "1rem",
                        fontWeight: "border"
                      }}
                    />
                  </Forgetbtn>
                  <Heading>New Password</Heading>
                </BtnContent>
                <Description>
                  Please enter the new password with confirmation and don’t
                  share it with others!
                </Description>

                <InputWrapper>
                  <Input
                    Error={errors.new_password1 && touched.new_password1}
                    type="password"
                    placeholder={"New Password"}
                    name="new_password1"
                    value={values.new_password1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.new_password1 && touched.new_password1 ? (
                    <Span>{errors.new_password1}</Span>
                  ) : null}
                </InputWrapper>
                <InputWrapper>
                  <Input
                    Error={errors.new_password2 && touched.new_password2}
                    type="password"
                    placeholder={"Confirm Password"}
                    name="new_password2"
                    value={values.new_password2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.new_password2 && touched.new_password2 ? (
                    <Span>{errors.new_password2}</Span>
                  ) : null}
                </InputWrapper>
                <Text>
                  Must be at least 8 characters with one number and one special
                  character
                </Text>
                <Buttonwrapper>
                  <Button
                    isBorder
                    type={"submit"}
                    disabled={isSubmitting || isLoading}
                  >
                    {isLoading ? "Updating..." : "Update"}
                  </Button>
                </Buttonwrapper>
              </InputField>
            )}
          </Form>
        </MainContainer>
      </Main>
    </>
  )
}

export default NewPassword

const Main = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainContainer = styled.div``
const Logocontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`

const Logo = styled.img``

const Form = styled.form`
  width: 458px;
  min-height: 454px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid green;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 24px;
`

const InputField = styled.div`
  width: 80%;
  padding: 1.2rem;
`

const BtnContent = styled.div`
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  width: 100%;
`

const Forgetbtn = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 2rem;
  margin-left: 1rem;
`

const Description = styled.p`
  text-align: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 1rem;
`

const Heading = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`

const InputWrapper = styled.div`
  margin-bottom: 1.2rem;
`

const Buttonwrapper = styled.div`
  margin-top: 1.5rem;
`

const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`
const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 458px;
  min-height: 341px;
  height: 100%;
`

const MessageDescription = styled.h3`
  text-align: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`
const Text = styled.p`
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.24px;
`
