import styled  from 'styled-components';
import React, { useEffect, useRef } from 'react';

const Track = (props) => {
//   const ref = useRef();

const ref = useRef(null); // Initialize with null for clarity

useEffect(() => {
  if (props.track) {
    const child = props.track.attach();
    ref.current?.classList.add(props.track.kind);
    ref.current?.appendChild(child);
  }

  return () => {
    if (props.track) {
      const child = ref.current?.firstChild;
      props.track.detach(child);
    }
  };
}, [props.track]);

  return (
    <>
    
  <div className={`track ${props.track && 'mirror'}`} ref={ref}></div>
  </>
  )
};

export default Track;

const Container = styled.div`
color: #Fff;
z-index: 999;
`