export const ROOT_API_URL = `https://calm-darkness-38642.botics.co/`;

export const userTypes = {
  superAdmin: "Super Admin",
  admin: "Admin",
  client: "Client",
  coach: "Coach",
  coachee: "Coachee",
};

export const notificationTypes = {
  oneTime: "one_time",
  everyDayat8am: "every_day_at_8_am",
  coachReview: "coach_review",
  coacheeReview: "coachee_review",
  coacheeFinalReportReview: "coachee_final_report_review",
  coachFinalReportReview: "coach_final_report_review",
  coachingSession: "coaching_session",
  chemistryCall: "chemistry_call",
  coachReviewForClient: "coach_review_for_client",
  coacheeFinalReportReviewForClient: "coach_final_report_review_for_client",
  assignCoaches: "assign_coaches",




};
