import React, { useState } from "react"
import { Modal } from "antd"
import styled from "styled-components"
import { ReactComponent as CameraIcon } from "./camera.svg"
import { ReactComponent as GelleryIcon } from "./gellery.svg"
import { ReactComponent as CloseIcon } from "./Close.svg"
import { useParams } from "react-router-dom"
import { getCookie } from "Utils/StorageVariables"
import CameraPhoto from "react-html5-camera-photo"
import "react-html5-camera-photo/build/css/index.css"
import { dataURItoBlob } from "data-uri-to-blob"
const PicUploadModal = ({
  isOpen,
  setIsOpen,
  setAvatarUrl,
  setFieldValue,
  isShowImage,
  setisShowImage,
  values,
  title
}) => {
  const handleClose = () => {
    setIsOpen(false)
  }
  const [isCamera, setisCamera] = useState(false)

  const [capturedImage, setCapturedImage] = useState(null)
  const [showButtons, setShowButtons] = useState(false)
  const handleTakePhoto = dataUri => {
    const binaryData = dataURItoBlob(dataUri)
    setisShowImage(URL.createObjectURL(binaryData))

    setCapturedImage(binaryData)
    const reader = new FileReader()

    reader.onload = () => {
      setAvatarUrl(binaryData.target.result)
    }

    if (dataUri) {
      setisCamera(false)
    }
    setShowButtons(true)
  }
  const dataURItoBlob = dataURI => {
    setisShowImage(dataURI)
    const byteString = atob(dataURI.split(",")[1])
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new File([ab], { type: mimeString })
  }
  const handleOkButtonClick = () => {
    console.log(capturedImage)
    setFieldValue("profile_picture", capturedImage)
    setCapturedImage(null)
    setShowButtons(false)
  }
  // console.log(values)
  const handleRetakePhotoButtonClick = () => {
    // Reset the state to allow capturing a new photo
    setisShowImage(values?.profile_picture)
    setCapturedImage(null)
    setShowButtons(false)
  }

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const imageUrl = reader.result;
  //     setAvatar(imageUrl);
  //     setIsOpen(false);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleAvatarUpload = event => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      setAvatarUrl(event.target.result)

      setIsOpen(false)
    }

    reader.readAsDataURL(file)
  }
  const getRole = JSON.parse(getCookie("userData"))
  return (
    <>
      <div>
        <ModalModified
          footer={null}
          closable={null}
          bodyStyle={{ padding: "0" }}
          open={isOpen}
        >
          <Heading>{title}</Heading>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <ButtonWrapper>
            <Label for="profile_picture">
              <Input
                id="profile_picture"
                name="profile_picture"
                type="file"
                onChange={event => {
                  setFieldValue("profile_picture", event.currentTarget.files[0])
                  handleAvatarUpload(event)
                }}
                accept="image/png, image/jpeg"
              />
              <GelleryIcon />
              &nbsp; Open photo library
            </Label>
            &nbsp;
            {/* {getRole?.user_type === "Client" && (
              <Label for="profile_picture">
                <Input
                  id="profile_picture"
                  name="profile_picture"
                  type="file"
                  onChange={(event) => {
                    setFieldValue(
                      "profile_picture",
                      event.currentTarget.files[0]
                    );
                    handleAvatarUpload(event);
                  }}
                  accept="image/png, image/jpeg"
                />
                <CameraIcon />
                &nbsp; Camera
              </Label>
            
            )} */}
            <Button
              onClick={() => {
                setisCamera(true)
                handleClose()
              }}
            >
              {" "}
              <CameraIcon />
              &nbsp; Camera
            </Button>
          </ButtonWrapper>
        </ModalModified>
      </div>
      {isCamera && (
        <Wrapper>
          <CameraPhoto
            onTakePhoto={dataUri => {
              handleTakePhoto(dataUri)
            }}
          />
        </Wrapper>
      )}
      {showButtons && (
        <SaveImageContainer>
          <Img src={isShowImage} alt="capturedImage" />
          <BtnWrapper>
            <Button onClick={handleOkButtonClick}>Ok</Button>
            <Button onClick={handleRetakePhotoButtonClick}>Retake Photo</Button>
          </BtnWrapper>
        </SaveImageContainer>
      )}
    </>
  )
}

export default PicUploadModal

const Heading = styled.h2`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.textDark};
`

const ModalModified = styled(Modal)`
  .ant-modal-content {
    position: relative;
  }
`

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;
  background-color: transparent;
  border: none;
`
const Image = styled.img`
  width: 20px;
  height: auto;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Label = styled.label`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 40px;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Myriad-Regular";
  font-weight: 700;
  font-size: 1rem;
`
const Input = styled.input`
  display: none;
`
const Button = styled.button`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 40px;
  width: 100%;
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Myriad-Regular";
  font-weight: 700;
  font-size: 1rem;
  outline: none;
  border: none;
`
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .react-html5-camera-photo {
    width: 500px;
    height: 600px;

    video {
      height: 500px !important;
    }
  }
`
const SaveImageContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BtnWrapper = styled.div`
  display: flex;
  width: 30%;
  margin-top: 3rem;
  gap: 3rem;
`
const Img = styled.img`
  border-radius: 20px;
`
