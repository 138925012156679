import Button from "Elements/Button/Button";
import { Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BaseService } from "API/base";
import moment from "moment";
import { ReactComponent as LeftArrow } from "./left_arrow.svg";
import { ReactComponent as TermCondition } from "./term_and_conditon.svg";
import { ReactComponent as Location } from "./location.svg";
import { DateTime } from "luxon";
import { useLocation } from "react-router-dom";
import Loading from "Components/Loader/Loading";
import NoDataFound from "Components/NoDataFound/NoDataFound";

const CoacheeProfileDetails = () => {
  const { id, item } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [isNoti, setisNoti] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const [isOpenList, setIsOpenList] = useState([]);
  const [coacheesData, setCoacheesData] = useState();
  const [CoacheesDetailLoading, setCoacheesDetailLoading] = useState(false);
  const [getCoacheesLoading, setGetCoacheesLoading] = useState(false);
  const [profileData, setProfileData] = useState();
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // console.log("jhell");
      setIsOpenList([]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    // setIsOpenList(new Array(coacheesData?.length).fill(false));
    setIsOpenList([]);
  }, [coacheesData]);

  // Function to toggle isOpen for a specific index
  const toggleBoxWrapper = (index) => {
    const updatedIsOpenList = [...isOpenList];
    updatedIsOpenList[index] = !updatedIsOpenList[index];
    setIsOpenList(updatedIsOpenList);
  };

  // const convertBackendDataToUserTimeZone = (backendData) => {
  //   console.log(backendData, "harimmmm");
  //   // Get the user's current time zone
  //   const userTimeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;

  //   // Convert backend data to user's time zone
  //   const backendDateTime = DateTime?.fromFormat(
  //     `${backendData.session_date} ${backendData.start_time}`,
  //     "yyyy-MM-dd hh:mma",
  //     { zone: backendData.timezone }
  //   );

  //   // Convert to the user's time zone
  //   const userTime = backendDateTime.setZone(userTimeZone);

  //   const userUtcOffsetMinutes = userTime.offset;

  //   // Convert the minutes to the desired format
  //   const hours = Math.floor(userUtcOffsetMinutes / 60);
  //   const minutes = userUtcOffsetMinutes % 60;
  //   const formattedUtcOffset =
  //     (hours >= 0 ? "+" : "-") +
  //     Math.abs(hours).toString().padStart(2, "0") +
  //     ":" +
  //     minutes.toString().padStart(2, "0");

  //   // Return the converted data
  //   return {
  //     ...backendData,
  //     session_date: userTime.toFormat("yyyy-MM-dd"),
  //     start_time: userTime.toFormat("hh:mma"),
  //     end_time: userTime.plus({ hours: 1 }).toFormat("hh:mma"), // Assuming end_time is 1 hour after start_time
  //     timezone: userTimeZone,
  //     utc_offset: formattedUtcOffset,
  //   };
  // };

  const convertBackendDataToUserTimeZone = (backendData) => {
    // Convert backend data to the UK/London time zone (UTC)
    const backendDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    );

    // Convert to the UK/London time zone with the desired UTC offset (+02:00)
    const userTime = backendDateTime.setZone("America/New_York");

    // Calculate end_time based on backend.end_time
    const endDateTime = DateTime.fromFormat(
      `${backendData.session_date} ${backendData.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData.timezone }
    );

    // Convert end_time to the UK/London time zone with the desired UTC offset (+02:00)
    const userEndTime = endDateTime.setZone("America/New_York");

    // Format the UTC offset
    const formattedUtcOffset = "-04:00";

    // Return the converted data
    return {
      ...backendData,
      date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"), // Use userEndTime
      utc_offset: formattedUtcOffset,
    };
  };



  const CoacheesDetail = async () => {
    try {
      setCoacheesDetailLoading(true);
      const response = await BaseService.get(
        `/api/v1/coaches/session/${id}/`,
        true
      );

      let ChangedData = response?.data?.map(convertBackendDataToUserTimeZone);

      // console.log(ChangedData, "changedata");

      setCoacheesData(ChangedData);
      setCoacheesDetailLoading(false);
      // Rest of your code...
    } catch (error) {
      console.log(error);
    }
  };


  // console.log(coacheesData, "hhhh");

  const getCoachees = async () => {
    try {
      setGetCoacheesLoading(true);
      const response = await BaseService.get(
        `api/v1/coaches/session/students/`,
        true
      );
      Object.keys(response?.data).forEach((key) => {
        const filteredArray = response?.data[key].filter((item) => {
          if (item?.id == id) {
            setProfileData(item);
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    CoacheesDetail();
    getCoachees();
  }, []);

  // console.log(coacheesData, "data");

  // const params = useParams();
  // const serializedObject = decodeURIComponent(params?.item);
  // const studentData = JSON.parse(serializedObject);
  // console.log({ studentData }, "myObject");

  // console.log(profileData, "harim");
  
  return (
    <Container>
      {CoacheesDetailLoading && <Loading />}
      <Content>
        <HeadingContent>
          <LeftArrow onClick={() => navigate(-1)} />
          <Heading>Coachee Profile</Heading>
        </HeadingContent>
        {/* <Searchcontainer>
          <Input
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined />}
            className="search_bar"
            style={{ borderRadius: "32px" }}
          />
        </Searchcontainer> */}
      </Content>
      <Mainitem>
        <MyProfile>
          <>
            <Profileimg>
              <Image
                src={
                  profileData?.profile_picture_url
                    ? profileData?.profile_picture_url
                    : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                }
                alt="profile"
              />
            </Profileimg>
            <Profileinfo>
              <ProfileHeading>{`${profileData?.first_name} ${profileData?.last_name}`}</ProfileHeading>
              <ProfilePosition>{profileData?.title}</ProfilePosition>

              <Profilelocation>
                {" "}
                <Location />
                {profileData?.city}
              </Profilelocation>
            </Profileinfo>
          </>
        </MyProfile>

        <ScheduleContainer>
          <Scheduleheading>Reports</Scheduleheading>
        </ScheduleContainer>

        <PastContainer>
          {coacheesData?.map((item, index) => (
            <>
              <PastContent $is_notify key={item?.id}>
                <HeadingItem>
                  <Imgcontainer>
                    <TermCondition style={{ marginRight: 0 }} />
                  </Imgcontainer>
                  <ImgDescription>
                    <Title>{item?.call_type}</Title>
                    <Descriptions>
                      {` ${moment(item?.session_date).format(
                        "MMMM D, YYYY"
                      )} ${item?.start_time.slice(
                        0,
                        -2
                      )} ${item?.start_time.slice(
                        5,
                        8
                      )} - ${item?.end_time.slice(
                        0,
                        -2
                      )} ${item?.end_time.slice(5, 8)} EST `}
                    </Descriptions>
                  </ImgDescription>
                </HeadingItem>

                <Wrapper>
                  <ArrowWrapper>
                    <MdKeyboardArrowRight
                      onClick={() => toggleBoxWrapper(index)}
                    />
                  </ArrowWrapper>

                  {item?.is_notify && !item?.is_reviewed_by_coach && (
                    <Notification>!</Notification>
                  )}
                </Wrapper>

                {isOpenList[index] && (
                  <BoxWrapper ref={ref}>
                    <CustomRow
                      onClick={() =>
                        navigate(
                          `/app/coach/review-session/${item?.id}/${profileData?.id}`
                        )
                      }
                    >
                      <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                      <Text>Review Session</Text>
                    </CustomRow>
                    {index === coacheesData?.length - 1 && (
                      <CustomRow
                        onClick={() =>
                          navigate(
                            `/app/coach/coachee-reports/${item?.engagement_info_id}/${profileData?.id}`
                          )
                        }
                      >
                        <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                        <Text>Review Coachee</Text>
                      </CustomRow>
                    )}
                  </BoxWrapper>
                )}
              </PastContent>
            </>
          ))}

          {/* <PastContent>
            <HeadingItem>
              <Imgcontainer>
                <TermCondition />
              </Imgcontainer>
              <ImgDescription>
                <Title>Coaching Session</Title>
                <Descriptions>March 5, 2023 10:00 - 10:30 AM</Descriptions>
              </ImgDescription>
            </HeadingItem>
            <Wrapper>
              <ArrowWrapper>
                <MdKeyboardArrowRight onClick={() => setisOpen(!isOpen)} />
              </ArrowWrapper>

              {isNoti === true && <Notification>!</Notification>}
            </Wrapper>
            {isNoti === true && isOpen === false && (
              <BoxWrapper ref={ref}>
                <CustomRow
                  onClick={() =>
                    navigate(`/app/notification/review-coach-session/${id}`)
                  }
                >
                  <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                  <Text>Review Coachee</Text>
                </CustomRow>
                <CustomRow
                  onClick={() =>
                    navigate(`/app/notification/coachee-reports/${id}`)
                  }
                >
                  <img src="/Assets/terms-and-conditions 1.svg" alt="" />
                  <Text>Coachee Report</Text>
                </CustomRow>
              </BoxWrapper>
            )}
          </PastContent> */}
        </PastContainer>
      </Mainitem>
    </Container>
  );
};

export default CoacheeProfileDetails;

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
`;

const Content = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
`;

const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 1rem;
  margin-bottom: 20px;
`;
const Searchcontainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
`;

const BtnContent = styled.div`
  width: 210px;
  margin-left: 1.2rem;
`;

const Mainitem = styled.div`
  padding: 1.5rem 2rem;
`;

const MyProfile = styled.div`
  display: flex;
  align-items: center;
`;
const Profileimg = styled.div`
  width: 70px;
  height: 70px;
  margin-right: 0.9rem;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Profileinfo = styled.div``;

const ProfileHeading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

const ProfilePosition = styled.h5`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textMedium};
  margin: 0;
`;

const Profilelocation = styled.p`
  font-family: "Myriad-Regular";
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.24px;
  color: ${({ theme }) => theme.textMedium};
  margin: 0;

  .anticon svg {
    margin-right: 5px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  height: 70px;
`;
const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ScheduleContainer = styled.div``;

const Scheduleheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`;
const Notification = styled.div`
  background-color: ${({ theme }) => theme.danger};
  color: ${({ theme }) => theme.white};
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: -8px;
`;
const ScheduleContent = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
`;

const HeadingItem = styled.div`
  padding: 0.6rem 0rem;
  display: flex;
  align-items: center;
`;
const Imgcontainer = styled.div`
  margin: 0 0.9rem;
  border-radius: 50%;
  /* padding: 9px 9px; */
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.backgroundLight};
`;

const ImgDescription = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
`;

const Title = styled.h3`
  font-family: "Myriad-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;

const Descriptions = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.border};
  margin: 0;
`;

const PastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* border: 1px solid green; */
`;

const Pastheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`;

const PastContent = styled.div`
  border: 1px solid ${({ theme }) => theme.border2};
  background-color: ${({ theme, is_notify }) =>
    is_notify && theme.backgroundDark};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  /* margin-bottom: 0.7rem; */
  position: relative;
  svg {
    margin-right: 0.7rem;
    font-size: 1.2rem;
  }
  cursor: pointer;
`;

const FinalReportContainer = styled.div``;

const Reportheading = styled.h3`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`;

const ReportInfo = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145.23%;
  color: ${({ theme }) => theme.textDark};
  margin: 0;
`;
const BoxWrapper = styled.div`
  width: 20%;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 31px rgba(43, 70, 88, 0.1);
  border-radius: 8px;
  z-index: 1;
  padding: 1rem;
  position: absolute;
  right: 2rem;
  top: 4.5rem;
`;
const CustomRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;
const Text = styled.p`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  text-align: center;
  letter-spacing: -0.32px;

  color: ${({ theme }) => theme.textDark};
`;
