import * as Yup from "yup";

const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const SigninSchemas = Yup.object().shape({
  email: Yup.string().email().required("Incorrect email format."),
  password: Yup.string().required("Password is required"),
  phone: Yup.string()
    .min(11, "Phone number must have 11 digits")
    .required("Phone number required"),
});

export const SignupSchemas = Yup.object().shape({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  phone: Yup.string().min(11).required("Phone number required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Incorrect email format."),
  password: Yup.string()
    .min(7, "Password must be atleast 6 digit")
    .required("Password is required"),
  repeat_password: Yup.string()
    .required("Password is required")
    .oneOf([Yup.ref("password"), null], "Password do not match"),
});

export const LoginSchemas = Yup.object().shape({
  // username: Yup.string(),
  email: Yup.string().email("Invalid email address").required("Invalid Email."),
  password: Yup.string().required("Password is required"),
});
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});
export const OtpSchemas = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const ChangePasswordSchemas = Yup.object().shape({
  current_password: Yup.string().required("Current password is required"),
  new_password1: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must be at least 8 characters with one letter, one number, and one special character"
    ),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
    .required("Confirm password is required"),
});
// export const NewPasswordSchemas = Yup.object().shape({
//   new_password1: Yup.string().required("Password is required"),
//   new_password2: Yup.string()
//     .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
//     .required("Confirm Password is required"),
// });
export const NewPasswordSchemas = Yup.object().shape({
  new_password1: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password must be at least 8 characters with one letter, one number, and one special character"
    ),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const clientProfileSchema = Yup.object().shape({
  client_name: Yup.string().required("Client Name is required "),
  industry: Yup.string().required("Industry Name is required "),
  // email: Yup.string()
  //   .email("Invalid email address")
  //   .required("Email is required"),
  state: Yup.string(),
  city: Yup.string(),
  zip_code: Yup.string(),
  contact_name: Yup.string()
    .required("Contact Name is required ")
    .typeError("Invalid Contact Name"),
  contact_number: Yup.string()
    .required("Contact Number is required")
    .typeError("Invalid Contact Number"),
  // company_employees: Yup.number(),
  profile_picture: Yup.string().required("Company logo is required "),
});
export const CoacheeProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required")
    .typeError("Invalid Contact Name"),
  last_name: Yup.string().required("Last Name is required"),
  title: Yup.string().required("Title is required"),
  // department: Yup.string(),
  // city: Yup.string(),
  // zip_code: Yup.number().typeError("Invalid Contact Number"),
  // company_employees: Yup.number(),
  profile_picture: Yup.string().required("Picture is required"),
});

export const coachProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  years_of_experience: Yup.number()
    .required("Years of Coaching Experience is required")
    .integer("Must be an integer"),
  bio: Yup.string().required("Bio is required"),
  city: Yup.string().required("City is required"),
  // zip_code: Yup.string().typeError("Invalid Zip Code"),
  // // intro_video: Yup.string().re,
  certificates: Yup.array(),
  profile_picture: Yup.string().required("Picture is required"),
});

export const addEmployeesSchemas = Yup.object().shape({
  first_name: Yup.string().required("First name required"),
  last_name: Yup.string().required(" Last name required"),
  contact_number: Yup.string().required("Phone number required"),
  email: Yup.string().email().required("Incorrect email format."),
  title: Yup.string().required("Title is required"),
});

export const FeedbackSchemas = Yup.object().shape({
  feedback_text: Yup.string().required("Feedback is required"),
});

export const RatingSchemas = Yup.object().shape({
  rating: Yup.string().required("Rating is required"),
});
export const CoacheeReportSchema = Yup.object().shape({
  rating: Yup.number().required("Rating is Required"),
  ratingOne: Yup.number().required("Rating is Required"),
  ratingTwo: Yup.number().required("Rating is Required"),
  ratingThree: Yup.number().required("Rating is Required"),
});
