import { Modal } from "antd"
import Button from "Elements/Button/Button"
import React, { useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import { ReactComponent as EmailSvg } from "./email.svg"
const SessionModal = ({
  isSessionmodal,
  setisSessionmodal,
  getCoachData,
  filterSession
}) => {
  const navigate = useNavigate()

  const { id } = useParams()

  const Data = getCoachData?.find(item => item?.coach_id == id)

  const handleclose = () => {
    setisSessionmodal(false)
  }

  const handleBack = () => {
    setisSessionmodal(false)
    navigate(-1)
  }

  return (
    <Modal
      footer={false}
      className="Session_Modal"
      centered
      // open
      open={isSessionmodal}
      onCancel={handleclose}
    >
      <Content>
        <ModelContent>
          <EmailSvg style={{ marginTop: "2.7rem" }} />
          <Description>Request was successfully sent!</Description>
          <ScheduleName>
            You are scheduled with {Data?.first_name} {Data?.last_name}. A
            calendar invitation has been sent to your email address.
          </ScheduleName>

          <>
            <CalenderDate>
              {moment(filterSession?.date).format("dddd MMMM D, YYYY")} <br />
              {filterSession?.start_time?.slice(0, 5)}{" "}
              {filterSession?.start_time?.slice(5, 7)} -{" "}
              {filterSession?.end_time?.slice(0, 5)}{" "}
              {filterSession?.end_time?.slice(5, 7)}
            </CalenderDate>
          </>

          <CalenderaStandard>Eastern Standard Time</CalenderaStandard>

          <ButtonContent>
            <Button isBorder onClick={handleBack}>
              Got it
            </Button>
          </ButtonContent>
        </ModelContent>
      </Content>
    </Modal>
  )
}

export default SessionModal

// const Wrapper = styled.div`
//   width: 100%;
//   height: 90vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const Content = styled.div`
  /* width: 475px;
  height: 579px; */
  /* border: 2px solid red; */
`

const ModelContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Description = styled.p`
  font-style: normal;
  /* width: 250px; */
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 1rem;
`

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 295px;
  gap: 1rem;
`

const ScheduleName = styled.p`
  color: #2b2b2b;
  text-align: center;
  font-family: "Myriad Regular";
  width: 70%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`

const CalenderDate = styled.h3`
  margin: 0;
  margin-top: 10px;
  color: #2b2b2b;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.32px;
  max-width: 70%;
  width: 72%;
`

const CalenderaStandard = styled.h5`
  margin-bottom: 2rem;
  font-size: 16px;
  color: #2b2b2b;
  text-align: center;
  font-family: "Myriad Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`
