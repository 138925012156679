import { BaseService } from "API/base"
import Loading from "Components/Loader/Loading"
import SelectCoachModal from "Components/SelectCoachModal/SelectCoachModal"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Error } from "Responses/Response"
import styled from "styled-components"
import { ReactComponent as NoCoach } from "./coach.svg"

const CoacheeList = () => {
  const navigate = useNavigate()
  const [isSelectCoach, setisSelectCoach] = useState(false)
  const [allcoaches, setAllCoaches] = useState([])
  const [coach, setCoach] = useState({})
  const [iscoachesloading, setIsCoachesLoading] = useState(false)

  const getCoach = async () => {
    try {
      setIsCoachesLoading(true)
      const response = await BaseService.get(`api/v1/coachees/coaches/`)
      if (response?.status === 200) {
        setAllCoaches(response?.data)
      }
      setIsCoachesLoading(false)
    } catch (error) {
      Error(error?.detail)
    }
  }

  const selectCoach = async () => {
    try {
      setIsCoachesLoading(true)
      const response = await BaseService.get(
        `/api/v1/coachees/select_coach/${coach?.engagement_info_id}/`
      )
      if (response?.status === 200) {
        setisSelectCoach(false)
        getCoach()
      }
      setIsCoachesLoading(false)
    } catch (error) {
      Error(error?.detail)
    }
  }

  useEffect(() => {
    getCoach()
  }, [])

  const handleCoach = data => {
    setCoach(data)
    if (coach) {
      setisSelectCoach(true)
    }
  }

  return (
    <CardContainer>
      {iscoachesloading && <Loading />}
      {allcoaches?.length > 0 ? (
        <>
          {allcoaches?.map(item => (
            <Card key={item?.coach_id}>
              <ProfileContent>
                <ProfileImage>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%"
                    }}
                    src={
                      item?.profile_picture_url
                        ? item?.profile_picture_url
                        : "https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                    }
                    alt=""
                  />
                </ProfileImage>
                <ProfileInfoContainer>
                  <ProfileName>
                    {item?.first_name} {item?.last_name}
                  </ProfileName>
                  <ProfileExp>
                    {item?.years_of_experience} years coaching experience
                  </ProfileExp>
                </ProfileInfoContainer>
              </ProfileContent>
              <Description>{item?.bio}</Description>
              {item?.is_chemistry_call ? (
                item?.is_assigned ? (
                  <ViewButton
                    onClick={() =>
                      navigate(
                        `/app/coachee/coaches/session-detail/${item?.coach_id}`
                      )
                    }
                  >
                    Schedule Coaching Session
                  </ViewButton>
                ) : (
                  <ViewButton onClick={() => handleCoach(item)}>
                    Select Coach
                  </ViewButton>
                )
              ) : (
                <ViewButton
                  onClick={() =>
                    navigate(
                      `/app/coachee/coach-profile-detail/${item?.coach_id}`
                    )
                  }
                >
                  View Profile
                </ViewButton>
              )}
            </Card>
          ))}
        </>
      ) : (
        <Nodata>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {/* <img src="/Assets/noData.png" alt="" /> */}
            <NoCoach />
            <NodataHeading>No coaches are available yet.</NodataHeading>
          </div>
        </Nodata>
      )}

      <SelectCoachModal
        isSelectCoach={isSelectCoach}
        setisSelectCoach={setisSelectCoach}
        Username={`${coach?.first_name} ${coach?.last_name}`}
        img={coach?.profile_picture_url}
        onclick={selectCoach}
        // getCoach={getCoach}
      />
    </CardContainer>
  )
}

export default CoacheeList

const CardContainer = styled.div`
  /* border: 1px solid green; */
  height: 90%;
  padding: 3.5rem 3rem;

  /* height: 40vh; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Card = styled.div`
  width: 280px;
  min-height: 238px;
  max-height: 240px;
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0px 11px 32px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 0rem 1.2rem;
  margin-bottom: 2rem;
`

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  /* border: 1px solid green; */
`
const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
`

const ProfileImage = styled.div`
  width: 72px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
const ProfileName = styled.h3`
  margin: 0;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 105% */
  letter-spacing: -0.32px;
`

const ProfileExp = styled.h4`
  margin: 0;
  color: #5b5b5b;
  font-family: "Myriad-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.24px;
`

const Description = styled.p`
  width: 272px;
  color: #2b2b2b;
  font-family: "Myriad-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px; /* 160.714% */
  letter-spacing: -0.32px;
  height: 45px; /* Adjust this value */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const ViewButton = styled.button`
  padding: 10px;
  border-radius: 100px;
  width: 272px;
  background: #e4bf44;
  border: none;
  outline: none;
  cursor: pointer;
  color: #221f20;
  font-family: "Myriad-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.32px;
`

const Nodata = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid green; */
`

const NodataHeading = styled.h3`
  color: #2b2b2b;
  text-align: center;
  font-family: "Myriad-Regular";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
  letter-spacing: -0.32px;
`
