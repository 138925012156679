import Button from "Elements/Button/Button";
import Input from "Elements/Input/Input";
import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { LoginSchemas } from "Schemas";
import Loading from "Components/Loader/Loading";
import { Error } from "Responses/Response";
import { getCookie, removeAllCookie, setCookie } from "Utils/StorageVariables";
import { Link, useNavigate } from "react-router-dom";
import { BaseService } from "API/base";
import { userTypes } from "Utils/Common";
import { GlobalStateContext } from "GlobalStateContext";

// import Signin from "Components/Signup/Sign in/Signin";
// import Signup from "Components/Signup/Signup";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [getCoachLoading, setGetCoachLoading] = useState(false);
  const [getClientLoading, setGetClientLoading] = useState(false);

  const { globalState, updateGlobalState } = useContext(GlobalStateContext);
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    // username: "",
  });
  let userData = JSON.parse(getCookie("userData"));
  let token = getCookie("accessToken");
  useEffect(() => {
    if (token && userData?.user_type === userTypes?.client) {
      navigate("app/client/profile");
    }
    if (token && userData?.user_type === userTypes?.coach) {
      navigate("app/coach/profile");
    }
    if (token && userData?.user_type === userTypes?.coachee) {
      navigate("app/coachee/profile");
    }
  }, [token]);
  const getCoach = async () => {
    try {
      setGetCoachLoading(true);
      const response = await BaseService.get(
        `api/v1/coaches/${userData?.user_type_id}/`,
        true
      );
      // updateGlobalState("profile_details", response?.data);
      // setCookie("globalState", JSON.stringify(globalState));

      setGetCoachLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        removeAllCookie();
      }
    }
  };
  const getCoachees = async () => {
    try {
      setGetCoachLoading(true);
      const response = await BaseService.get(
        `api/v1/coachees/${userData?.user_type_id}/`,
        true
      );
      // updateGlobalState("profile_details", response?.data);
      // setCookie("globalState", JSON.stringify(globalState));

      setGetCoachLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        removeAllCookie();
      }
    }
  };
  const getClient = async () => {
    try {
      setGetClientLoading(true);
      const response = await BaseService.get(
        `api/v1/clients/${userData?.user_type_id}/`,
        true
      );
      // updateGlobalState("profile_details", response?.data);
      // setCookie("globalState", JSON.stringify(globalState));

      setGetClientLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (userData?.user_type === userTypes?.client) {
      getClient();
    } else if (userData?.user_type === userTypes?.coachee) {
      getCoachees();
    } else if (userData?.user_type === userTypes?.coach) {
      getCoach();
    }
  }, []);

  const handleLogin = async (values) => {
    setIsLoading(true);
    let authorizedRoles = [
      userTypes.client,
      userTypes.coach,
      userTypes.coachee,
    ];
    try {
      const response = await BaseService.post(
        "rest-auth/login/",
        values,
        false
      );
      if (
        response?.status === 200 &&
        authorizedRoles.includes(response?.data?.user?.user_type)
      ) {
        setCookie("accessToken", response?.data?.key);
        setCookie("userData", JSON.stringify(response?.data?.user));
        setInitialValues({
          email: "",
          password: "",
          // username: "",
        });
      } else {
        Error("Sorry! you are not authorized to access this system...");
      }
    } catch (error) {
      console.error({ error });
      error?.response?.data?.non_field_errors?.map((err) => Error(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    removeAllCookie();
    navigate("/");
  };
  // console.log(globalState?.data?.profile_picture_url);
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchemas,
    onSubmit: () => handleLogin(values),
  });

  return (
    <Fragment>
      <Main>
        <MainContainer>
          <Logocontainer>
            <Logo src="/Assets/Group 462.svg" alt="" />
          </Logocontainer>
          {getCookie("accessToken") ? (
            <>
              <div>You are logged in.</div>
              <button onClick={handleLogout}>Logout</button>
            </>
          ) : (
            <>
              {isLoading ? (
                <Loading />
              ) : (
                <Form onSubmit={handleSubmit}>
                  <InputField>
                    <Heading>Login</Heading>
                    <InputWrapper>
                      <Input
                        Error={errors.email && touched.email}
                        label={"Email"}
                        type="email"
                        placeholder={"Email"}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <Span>{errors.email}</Span>
                      ) : null}
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        Error={errors.password && touched.password}
                        label={"Password"}
                        type="password"
                        placeholder={"Password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password ? (
                        <Span>{errors.password}</Span>
                      ) : null}
                    </InputWrapper>
                    <Info to={"/forget-password"}>Forgot password?</Info>
                    <Buttonwrapper>
                      <Button
                        isBorder
                        type={"submit"}
                        disabled={isSubmitting || isLoading}
                      >
                        {isLoading ? "Loading..." : "Login"}
                      </Button>
                    </Buttonwrapper>
                  </InputField>
                </Form>
              )}
            </>
          )}
        </MainContainer>
      </Main>
    </Fragment>
  );
};

export default Login;

const Text = styled.h2`
  color: ${({ theme }) => theme.textDark};
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div``;
const Logocontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;

const Logo = styled.img``;

const Form = styled.form`
  width: 440px;
  min-height: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid green;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 24px;
`;

const InputField = styled.div`
  /* border: 1px solid ${({ theme }) => theme.primary}; */
  width: 80%;
  /* height: 80%; */
  padding: 1.2rem;
`;

const Heading = styled.h3`
  text-align: center;
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`;
const InputWrapper = styled.div`
  margin-bottom: 1.2rem;
`;

const Buttonwrapper = styled.div`
  margin-top: 1.5rem;
`;
const Info = styled(Link)`
  color: ${({ theme }) => theme.textDark};
  font-family: inter-medium;
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.06em;
  padding-top: 0.5rem;
  text-align: right;
`;

const Span = styled.p`
  color: ${({ theme }) => theme.danger};
  margin: 0;
  font-size: 13px;
  font-weight: 700;
`;
