import Button from "Elements/Button/Button"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { LeftOutlined, RightOutlined, PlusOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import "./Calendersetting.scss"
import AddSlotModal from "Components/AddSlotModal/AddSlotModal"
import { TimePicker } from "antd"
import { BaseService } from "API/base"
import { Error } from "Responses/Response"
import Loading from "Components/Loader/Loading"
import { ReactComponent as DeleteIcon } from "./DeleteRed.svg"
import { DateTime } from "luxon"
import { v4 as uuidv4 } from "uuid"

const CalenderSetting = () => {
  const [openslot, setisOpenslot] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState()
  const [showTimeColumn, setShowTimeColumn] = useState(false)
  const [selectedDate, setSelectedDate] = useState()
  const [isData, setIsData] = useState(null)
  const [slotData, setSlotData] = useState(null)
  const [getSlotsLoading, setGetSlotsloading] = useState(false)
  const [coachingSlotsLoading, setCoachingSlotsLoading] = useState(false)
  const [deleteSlotsLoading, setDeleteSlotsLoading] = useState(false)
  const [updateSlotLoading, setUpdateSlotLoading] = useState(false)
  const [currDate, setCurrdate] = useState()
  const [slotDate, isSlotDate] = useState()
  const navigate = useNavigate()
  const [filteredItems, setfilteredItems] = useState([])

  const [currentView, setCurrentView] = useState("month")
  const [Viewdot, setViewdot] = useState("month")
  const [errorMessages, setErrorMessages] = useState({})
  const localizer = momentLocalizer(moment)

  const convertBackendDataToUserTimeZone = backendData => {
    // Convert backend data to the UK/London time zone (UTC)
    const backendDateTime = DateTime.fromFormat(
      `${backendData?.date} ${backendData?.start_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData?.timezone }
    )

    const userTime = backendDateTime.setZone("America/New_York")

    const endDateTime = DateTime.fromFormat(
      `${backendData?.date} ${backendData?.end_time}`,
      "yyyy-MM-dd hh:mma",
      { zone: backendData?.timezone }
    )

    const userEndTime = endDateTime.setZone("America/New_York")

    const formattedUtcOffset = "-05:00"

    // Return the converted data
    return {
      ...backendData,
      date: userTime.toFormat("yyyy-MM-dd"),
      start_time: userTime.toFormat("hh:mma"),
      end_time: userEndTime.toFormat("hh:mma"),
      utc_offset: formattedUtcOffset
    }
  }

  const getSlots = async () => {
    try {
      setGetSlotsloading(true)
      const response = await BaseService.get("api/v1/coaching_slots/")
      let slotsAvailable = response?.data?.filter(slot => !slot?.is_reserved)

      slotsAvailable = slotsAvailable.map(convertBackendDataToUserTimeZone)

      setSlotData(slotsAvailable)

      setGetSlotsloading(false)
    } catch (error) {
      Error(error?.detail)
    }
  }

  const mappedSlotsData = slotData?.map((item, i) => {
    const start = moment(
      `${item.date} ${item.start_time}`,
      "YYYY-MM-DD h:mmA"
    ).toDate()
    const end = moment(
      `${item.date} ${item.end_time}`,
      "YYYY-MM-DD h:mmA"
    ).toDate()

    return {
      title: "Available Slots",
      start,
      end,
      allDay: false
    }
  })

  const addSpaceBeforeTimeIndicator = timeString => {
    const timeIndicators = ["AM", "PM"]
    for (const indicator of timeIndicators) {
      const index = timeString.indexOf(indicator)
      if (index !== -1) {
        const timeWithoutIndicator = timeString.substring(0, index)
        return `${timeWithoutIndicator} ${indicator}`
      }
    }
    return timeString
  }

  useEffect(() => {
    getSlots()
  }, [])

  useEffect(() => {
    const dateObj = new Date()
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setCurrdate(formattedDate)
  }, [slotDate])

  const coachingSlots = async ({ id, error, ...data }) => {
    try {
      setCoachingSlotsLoading(true)
      const response = await BaseService.post("api/v1/coaching_slots/", data)

      if (response?.status === 201) {
        // getSlots()
        setSlotData(prev => [
          ...prev?.filter(slot => slot?.id != id),
          response?.data
        ])
        setCoachingSlotsLoading(false)
        setisOpenslot(false)
      }
    } catch (err) {
      Error(err?.response?.data?.detail)
      setSlotData(prev => [...prev?.filter(slot => slot?.id != id)])
    } finally {
      setCoachingSlotsLoading(false)
    }
  }

  const deleteObjectById = async id => {
    const newfiltereditem = slotData?.find(item => item?.id == id)
    if (newfiltereditem?.isLocal)
      return setSlotData(slotData?.filter(item => item?.id != id))

    try {
      setDeleteSlotsLoading(true)

      const response = await BaseService.remove(`/api/v1/coaching_slots/${id}/`)

      setSlotData(slotData?.filter(item => item?.id != id))
      setErrorMessages({ id: false })
    } catch (error) {
      console.error("Error deleting object:", error)
    } finally {
      setDeleteSlotsLoading(false)
    }
  }

  const handleAddTime = () => {
    if (slotDate < currDate) {
      return
    }
    const existingSlots = slotData.filter(slot => slot?.date === slotDate)
    if (existingSlots.length < 6) {
      const newSlot = {
        date: slotDate,
        isNew: true,
        isLocal: true,
        id: uuidv4(),
        error: false
      }

      setSlotData([...slotData, newSlot])
    }
  }

  const handleChangeTime = (id, field, value) => {
    const formattedTime = moment(value, "h:mm a").format("hh:mmA")
    setSlotData(
      slotData?.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: formattedTime,
            isUpdate: item?.isNew ? false : true
            // error: false
          }
        }
        return item
      })
    )
  }

  const bulkSlots = async data => {
    try {
      const response = await BaseService.post(
        "/api/v1/coaching_slots_bulk_create_or_update/",
        data
      )

      setisOpenslot(false)
      getSlots()
    } catch (err) {
      Error(err?.response?.data?.detail)
    } finally {
    }
  }

  const handleSave = () => {
    if (slotData) {
      let payload = slotData
        .filter(slot => {
          if (slot?.date === slotDate && (slot?.isNew || slot?.isUpdate))
            return slot
        })
        .map(({ date, start_time, end_time, isNew, isUpdate, id }) => {
          if (isNew && !isUpdate) {
            return {
              date,
              start_time,
              end_time,
              utc_offset: "-05:00",
              is_reserved: isNew && !isUpdate && false
            }
          } else if (id) {
            return {
              date,
              start_time,
              end_time,
              utc_offset: "-05:00",
              id,
              is_reserved: isNew && !isUpdate && false
            }
          } else {
            return null
          }
        })

      // Remove any null values from the payload
      payload = payload.filter(slot => slot !== null)

      console.log({ payload })
      if (payload.length > 0) {
        bulkSlots(payload)
      }
    }
  }

  const CustomEvent = ({ event }) => {
    const { start, end, title } = event

    return (
      <>
        <AvailableSlotdiv>
          <EventTitle>{title}</EventTitle>
          <EventDate>
            {moment(start).format("h:mm A")} - {moment(end).format("h:mm A")}
          </EventDate>
        </AvailableSlotdiv>
      </>
    )
  }

  const CustomHeader = ({ date, slotData }) => {
    const handleHeaderClick = () => {
      const dateString = date
      const dateObj = new Date(dateString)
      const year = dateObj.getFullYear()
      const month = String(dateObj.getMonth() + 1).padStart(2, "0")
      const day = String(dateObj.getDate()).padStart(2, "0")
      const formattedDate = `${year}-${month}-${day}`
      isSlotDate(formattedDate)
      if (selectedDate && selectedDate.getTime() === date.getTime()) {
        setSelectedDate(null)
      } else {
        setSelectedDate(date)
      }

      setShowTimeColumn(true)
    }

    const headerStyle = {
      backgroundColor:
        selectedDate && selectedDate.getTime() === date.getTime()
          ? "rgb(255 230 149)"
          : "white"
    }

    const day = moment(date).format("ddd")
    const dateNumber = moment(date).format("DD")

    return (
      <>
        <HeaderContainer onClick={handleHeaderClick}>
          <HeaderContent className="rbc-header-content">
            <HeaderDay className="rbc-header-day">{day}</HeaderDay>
            <HeaderDate className="rbc-header-date" style={headerStyle}>
              {dateNumber}
              <span
                style={{
                  position: "absolute",
                  top: "8px",
                  left: "13px"
                }}
              >
                {slotData?.find(
                  slot => moment(slot?.date).format("DD") == dateNumber
                )
                  ? "."
                  : null}
              </span>
            </HeaderDate>
          </HeaderContent>
        </HeaderContainer>
      </>
    )
  }

  const Toolbar = ({ label, onView, view, onNavigate, localizer }) => (
    <MainToolbarContainer className="my-toolbar" localizer={localizer}>
      <Header>
        <ButtonContent>
          <Button1 onClick={() => onNavigate("PREV")}>
            <LeftOutlined />
          </Button1>
          <Button1 onClick={() => onNavigate("NEXT")}>
            <RightOutlined />
          </Button1>
          <Span>{label}</Span>
        </ButtonContent>

        <Buttoncontent2>
          <Button2
            active={view === "week"}
            onClick={() => {
              onView("week")
              setCurrentView("week")
              setViewdot("week")
            }}
          >
            Week
          </Button2>
          <Button2
            active={view === "month"}
            onClick={() => {
              onView("month")
              setCurrentView("month")
              setViewdot("month")
            }}
          >
            Month
          </Button2>
        </Buttoncontent2>
      </Header>
    </MainToolbarContainer>
  )

  function CustomTimeColumn(props) {
    return (
      <>
        {currDate || selectedDate ? (
          <Addslot className="custom-time-column">
            <AddslotContainer>
              <h3>{props.label}</h3>
              <ModalItem>
                <AvailableHeading>Coaching Slots</AvailableHeading>
                <Description>You can add 6 session slots</Description>
                <Mainitem>
                  {slotData
                    ?.filter(slot => slot?.date === slotDate)
                    ?.map(item => (
                      <>
                        <ItemContainer>
                          <InputWrapper key={item.id}>
                            <TimePicker
                              className="time_picker"
                              size="large"
                              placeholder={
                                item?.start_time &&
                                addSpaceBeforeTimeIndicator(item?.start_time)
                              }
                              style={{
                                borderColor: errorMessages[item?.id] && "red"
                              }}
                              showNow={false}
                              use12Hours
                              format="h:mm a"
                              onChange={(time, timeString) =>
                                handleChangeTime(
                                  item.id,
                                  "start_time",
                                  timeString
                                )
                              }
                              onSelect={value => {
                                if (value.$d instanceof Date) {
                                  const timeString = moment(value.$d).format(
                                    "h:mm a"
                                  )
                                  handleChangeTime(
                                    item.id,
                                    "start_time",
                                    timeString?.toLocaleUpperCase()
                                  )
                                  return timeString
                                } else {
                                  return ""
                                }
                              }}
                            />
                            <Line />
                            <TimePicker
                              className="time_picker"
                              showNow={false}
                              size="large"
                              placeholder={
                                item?.end_time &&
                                addSpaceBeforeTimeIndicator(item?.end_time)
                              }
                              style={{
                                borderColor: errorMessages[item?.id] && "red"
                              }}
                              use12Hours
                              format="h:mm a"
                              onChange={(time, timeString) =>
                                handleChangeTime(
                                  item.id,
                                  "end_time",
                                  timeString?.toLocaleUpperCase()
                                )
                              }
                              onSelect={value => {
                                if (value.$d instanceof Date) {
                                  const timeString = moment(value.$d).format(
                                    "h:mm a"
                                  )
                                  // console.log(timeString)
                                  handleChangeTime(
                                    item.id,
                                    "end_time",
                                    timeString?.toLocaleUpperCase()
                                  )
                                  return timeString
                                } else {
                                  return ""
                                }
                              }}
                            />
                          </InputWrapper>
                          <ButtonWrapper>
                            <DeleteButton
                              onClick={() => deleteObjectById(item.id)}
                            >
                              <DeleteIcon />
                            </DeleteButton>
                          </ButtonWrapper>
                        </ItemContainer>
                      </>
                    ))}
                </Mainitem>
                <AdditemButton onClick={handleAddTime}>
                  <PlusOutlined /> Add &nbsp;Time
                </AdditemButton>
                <ApplyButtonContainer>
                  <Button isBorder onClick={handleSave}>
                    Save
                  </Button>
                </ApplyButtonContainer>
              </ModalItem>
            </AddslotContainer>
          </Addslot>
        ) : null}
      </>
    )
  }

  const handleSelectSlot = (slotInfo, e) => {
    const dateString = slotInfo.start
    const dateObj = new Date(dateString)
    const year = dateObj.getFullYear()
    const month = String(dateObj.getMonth() + 1).padStart(2, "0")
    const day = String(dateObj.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    isSlotDate(formattedDate)

    // isSlotDate(formattedDate);
    setSelectedSlot()
    setisOpenslot(true)
  }
  useEffect(() => {
    isSlotDate(currDate)
  }, [currDate])

  const [dotsVisible, setDotsVisible] = useState(false)

  useEffect(() => {
    const updateDotsVisibility = () => {
      if (Viewdot === "month") {
        setDotsVisible(true)
      } else {
        setDotsVisible(false)
      }
    }

    updateDotsVisibility()
  }, [Viewdot])

  ///////////////

  useEffect(() => {
    if (dotsVisible) {
      const dateCells = document.querySelectorAll(".rbc-date-cell")

      dateCells.forEach(dateCell => {
        const button = dateCell.querySelector(".rbc-button-link")
        const dateText = button.textContent.trim()

        const SessionArray = slotData?.find(
          slot => moment(slot?.date)?.format("DD") === dateText
        )

        if (SessionArray) {
          console.log("Adding dot for date:", dateText)

          const dotElement = document.createElement("span")
          dotElement.innerHTML = "."
          dotElement.classList.add("dot")
          // Adjust CSS styles as needed
          dotElement.style.position = "absolute"
          dotElement.style.top = "11px"
          dotElement.style.left = "13px"
          dotElement.style.zIndex = "10000"
          dotElement.style.color = "black" // Adjust color
          dotElement.style.fontSize = "15px" // Adjust font size
          button.appendChild(dotElement)
        }
      })
    } else {
      // Remove dots if not in "month" view
      const dateCellDots = document.querySelectorAll(".rbc-button-link .dot")
      dateCellDots.forEach(dot => dot.remove())
    }
  }, [dotsVisible, slotData])
  // ////////////////////////

  useEffect(() => {
    const dateCells = document.querySelectorAll(".rbc-date-cell")

    dateCells.forEach(dateCell => {
      const button = dateCell.querySelector(".rbc-button-link")

      button.addEventListener("click", e => {
        e.preventDefault()
        const buttonText = button.textContent
        const trimmedText = buttonText.slice(0, 2)

        if (slotDate) {
          var dateString = slotDate
          var words = dateString.split("-")
          words[words.length - 1] = trimmedText
          var modifiedString = words.join("-")
          isSlotDate(modifiedString)
        }
      })
    })
  }, [slotDate])

  return (
    <Container>
      {(getSlotsLoading && <Loading />) ||
        (coachingSlotsLoading && <Loading />) ||
        (updateSlotLoading && <Loading />) ||
        (deleteSlotsLoading && <Loading />)}
      <Content>
        <HeadingContent>
          {/* <LeftOutlined onClick={() => navigate(-1)} /> */}
          <Heading>Settings</Heading>
        </HeadingContent>
        <BtnContent>
          <Button
            isBorder
            onClick={() => {
              setisOpenslot(true)
              // isSlotDate(slotDate);
            }}
          >
            Add Slots
          </Button>
        </BtnContent>
      </Content>

      <Calendar
        views={["month", "week"]}
        localizer={localizer}
        selectable={true}
        events={mappedSlotsData}
        onSelectSlot={handleSelectSlot}
        startAccessor="start"
        endAccessor="end"
        className="my-calendar-setting"
        components={{
          toolbar: Toolbar,
          event: CustomEvent,
          timeGutterWrapper: CustomTimeColumn,
          week: {
            header: props => <CustomHeader slotData={slotData} {...props} />
          }
        }}
        min={new Date(0, 0, 0, 0, 0, 0)}
        max={new Date(0, 0, 0, 23, 1, 0)}
      />
      {currentView === "month" && openslot && (
        <AddSlotModal
          filteredItems={filteredItems}
          isData={isData}
          slotData={slotData?.filter(slot => slot?.date === slotDate)}
          slotDate={slotDate}
          openslot={openslot}
          handleSave={handleSave}
          setisOpenslot={setisOpenslot}
          coachingSlots={coachingSlots}
          deleteObjectById={deleteObjectById}
          handleAddTime={handleAddTime}
          handleChangeTime={handleChangeTime}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleSelectSlot={handleSelectSlot}
          setfilteredItems={setfilteredItems}
          setSlotData={setSlotData}
          selectedSlot={selectedSlot}
          isSlotDate={isSlotDate}
          getSlots={getSlots}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
        />
      )}
      <FootarButtoncontainer>
        <FootarButton>
          <Button isBorder onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </FootarButton>
      </FootarButtoncontainer>
    </Container>
  )
}

export default CalenderSetting

const Container = styled.div`
  height: 100vh;
  overflow: auto;
  /* border: 1px solid red; */
  background-color: #fff;
`

const HeadingContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Heading = styled.h3`
  font-family: "Myriad-Regular";
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
  margin-left: 0.1rem;
`

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  height: 10vh;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.5rem;
`

const BtnContent = styled.div`
  width: 123px;
  margin-left: 1.2rem;
`

const MainToolbarContainer = styled.div`
  background-color: #fff;
  height: 60px;
`
const Header = styled.div`
  /* border: 1px solid red; */
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`
const Span = styled.span`
  margin: 0;
  font-family: "Myriad-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`
const Button1 = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 9px;
`

const Buttoncontent2 = styled.div`
  padding: 2px;
  width: 343px;
  height: 32px;
  display: flex;
  background: ${({ theme }) => theme.border2};
  border-radius: 8px;
`

const Button2 = styled.button`
  width: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.primary : theme.border2};
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.08px;
  color: ${({ theme }) => theme.textDark};
`

const HeaderContainer = styled.div``

const HeaderContent = styled.div`
  min-height: 65px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
`
const HeaderDay = styled.div`
  font-family: "Myriad-Light";
  font-weight: 600;
  font-size: 14px;
  line-height: 124.5%;
  text-align: center;
  color: #45485b;
`
const HeaderDate = styled.div`
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 124.5%;
  color: #45485b;
  margin-top: 3px;
  padding: 2px 4px;
  border-radius: 50%;
  position: relative;
`

const Addslot = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
`

const AddslotContainer = styled.div`
  width: 49%;
  /* border: 1px solid green; */
  margin-left: 1.5rem;
`

const ModalItem = styled.div`
  /* border: 1px solid red; */
  max-width: 33rem;
  /* height: 100%; */
  /* max-width: 30rem; */
  padding: 2px 12px;
`
const AvailableHeading = styled.h3`
  margin-bottom: 2px;
  margin-top: 0;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: ${({ theme }) => theme.textDark};
`

const Description = styled.p`
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: ${({ theme }) => theme.textDark};
`
const Mainitem = styled.div`
  /* border: 1px solid purple; */
  min-height: 420px;
  max-height: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
`
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;

  .ant-picker-large {
    .ant-picker-input {
      input {
        text-transform: uppercase !important;
      }
    }
  }
`

const Line = styled.hr`
  height: 2px;
  outline: none;
  border: none;
  background-color: #45485b;
  width: 8px;
  /* margin: 1rem 1rem; */
`
const ButtonWrapper = styled.div``
const DeleteButton = styled.button`
  outline: none;
  border: none;
  background-color: #ffcdcf;
  border-radius: 100px;
  padding: 9px 10px;
  cursor: pointer;
`

const AdditemButton = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  font-family: "Myriad-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #221f20;
  cursor: pointer;
`
const ApplyButtonContainer = styled.div`
  width: 100%;
  margin: 1.2rem 0rem;
`

const FootarButtoncontainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  box-shadow: 5px 11px 27px rgba(0, 0, 0, 0.15);
  padding: 0.7rem 1.5rem 0.7rem 0rem;
`
const FootarButton = styled.div`
  width: 10%;
`
// const CustomWrapper = styled.div`
//   /* background-color: red; */
//   display: flex;
//   width: 100%;
//   gap: 0.3rem;
//   flex-wrap: wrap;
// `;
const EventTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2b2b;
`
const AvailableSlotdiv = styled.div`
  background-color: #39ff14;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 6px;
  border-radius: 5px;
  padding: 2px 0;
`

const EventDate = styled.p`
  margin: 0;
  color: #2b2b2b;
`
